import Http from './Http';

const getSubscriptionUrl = (tariffId: string, period: number) =>
  Http.makeInstance().post('/subscriptions', {
    tariffId,
    period,
  });

const getSubscriptionUrlForActiveTariff = () =>
  Http.makeInstance().get('/subscriptions/payNow');

const cancelSubscription = (tariffId: string) =>
  Http.makeInstance().delete(`/tariffs/cancel/${tariffId}`);

export default {
  getSubscriptionUrl,
  cancelSubscription,
  getSubscriptionUrlForActiveTariff
};
