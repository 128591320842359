export const SHOW_URKSIB_BANK_INTEGRATION = 'showUkrsibBankIntegration';

export const SHOW_BANK_MILLENNIUM_INTEGRATION = 'showBankMillenniumIntegration';
export const SHOW_BANK_EBURY_INTEGRATION = 'showBankEburyIntegration';
export const SHOW_BANK_ING_INTEGRATION = 'showBankINGIntegration';
export const SHOW_BANK_INTELIGO_INTEGRATION = 'showBankInteligoIntegration';
export const SHOW_NEST_BANK_CORPORATE_INTEGRATION =
  'showNestBankCorporateIntegration';
export const SHOW_NEST_BANK_PRIVATE_INTEGRATION =
  'showNestBankPrivateIntegration';
export const SHOW_SANTANDER_BANK_INTEGRATION = 'showSantanderBankIntegration';
export const SHOW_SANTANDER_BANK_CORPORATE_INTEGRATION =
  'showSantanderBankCorporateIntegration';
export const SHOW_STRIPE_BANK_INTEGRATION = 'showStripeBankIntegration';
export const SHOW_ING_BANK_FOR_POLAND = 'showINGbankForPoland';
export const SHOW_ING_SALTEDGE = 'showINGsaltedge';
export const SHOW_TEST_SALTEDGE_BANKS = 'showTestSaltedgeBanks';
export const SHOW_FACTUROWNIA_INTEGRATION = 'showFacturowniaIntegration';
export const USE_RECONNECT_INTEGRATION = 'useReconnectIntegration';
export const USE_ONLY_NORDIGEN_SERVICE = 'useOnlyNordigenService';
export const SHOW_IDOKLAD_INTEGRATION = 'showiDokladIntegration';
export const SHOW_ALERT_INTEGRATION_PAYMENT = 'showAlertIntegrationPayment';
export const SHOW_DEMO_NORDIGEN_BANK_ON_STAGE = 'showDemoNordigenBankOnStage';
