import { AxiosResponse } from 'axios';
import {
  BelvoAccount,
  BelvoCredentials,
  BelvoCredentialsPayload,
  BelvoIntegrationId,
} from '../../store/integrations/types';
import Http from '../Http';

const getAllBanks = (countryCode: string) =>
  Http.makeInstance().get(
    `integrations/belvoApiCredentials/banks/${countryCode}`,
  );

const getIntegrationAccounts = (
  integrationId: string,
): Promise<AxiosResponse<BelvoAccount[]>> =>
  Http.makeInstance().get(
    `integrations/belvoApiCredentials/accounts/${integrationId}`,
  );

const getCredentials = (
  data: BelvoCredentials,
): Promise<AxiosResponse<BelvoIntegrationId>> =>
  Http.makeInstance().post('integrations/belvoApiCredentials', { ...data });

const updateCredentials = (data: BelvoCredentialsPayload) =>
  Http.makeInstance().patch('integrations/belvoApiCredentials', { ...data });

export default {
  getAllBanks,
  getCredentials,
  updateCredentials,
  getIntegrationAccounts,
};
