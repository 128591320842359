import { isNotEmpty } from 'class-validator';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class AsiaAllianceBankParser extends BaseImportParserV3 {
  private prepareNewOperation(currentOp, body) {
    currentOp[0] = moment(currentOp[0], 'DD.MM.YYYY hh:mm:ss').toDate(),
    currentOp[1] = moment(currentOp[1].replaceAll(' ', ''), 'DD.MM.YYYY').toDate();
    currentOp[2] = currentOp[2].replaceAll(' ', '');
    currentOp[4] = currentOp[4].replaceAll(',', '').replaceAll(' ', '');
    currentOp[4] = currentOp[4] ? Number(currentOp[4]) : currentOp[4];
    currentOp[5] = currentOp[5].replaceAll(',', '').replaceAll(' ', '');
    currentOp[5] = currentOp[5] ? Number(currentOp[5]) : currentOp[5];
    body.push(currentOp);
  }

  private addToOperation(currentOp, arr) {
    currentOp[0] += currentOp[0] ? ' ' + arr[0] : arr[0];
    currentOp[1] += currentOp[1] ? ' ' + arr[1] : arr[1];
    currentOp[2] += currentOp[2] ? ' ' + arr[2] : arr[2];
    currentOp[3] += currentOp[3] ? ' ' + arr[3] : arr[3];
    currentOp[4] += currentOp[4] ? ' ' + arr[4] : arr[4];
    currentOp[5] += currentOp[5] ? ' ' + arr[5] : arr[5];
  }

  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value, i) => {
              const sentence = rawDocument[i].str +
                rawDocument[i+1]?.str +
                rawDocument[i+2]?.str +
                rawDocument[i+3]?.str +
                rawDocument[i+4]?.str +
                rawDocument[i+5]?.str +
                rawDocument[i+6]?.str;
              return sentence === 'Выпискалицевогосчета(подокументам)';
            }),
          );
        },
        proceedCase: (rawImportDocument) => {
          const importDocument = rawImportDocument.map(arr => {
            return [arr[0], arr[1], arr[2], arr[3] + arr[4], arr[5], arr[6]];
          })
          this.setDocumentHeader(importDocument[0]);
          const body = [];
          let currentOp = [...importDocument[1]];
          let isNextByDate = false;
          importDocument.slice(2).forEach(arr => {
            if((arr[3].startsWith('МФО') || moment(arr[1], 'DD.MM.YYYY', true).isValid()) && !!currentOp[1]) {
              if(!isNextByDate) {
                this.prepareNewOperation(currentOp, body);
                currentOp = [...arr];
              } else {
                this.addToOperation(currentOp, arr);
              }
              if(!arr[3].startsWith('МФО') && moment(arr[1], 'DD.MM.YYYY', true).isValid()) {
                isNextByDate = true;
              } else {
                isNextByDate = false;
              }
            } else {
              this.addToOperation(currentOp, arr);
            }
          })
          if(currentOp.length) {
            this.prepareNewOperation(currentOp, body);
          }
          this.setDocumentBody(body);
          return (): ImportResultItemMask => {
            return {
              date: this.getFirstValidCellByColumn(['Дата\nпроводки', 0]),
              time: this.getFirstValidCellByColumn(['Дата\nпроводки', 0]),
              comment: this.getFirstValidCellByColumn(['Корреспондент: Банк/Счет/Инн Наименование Назначение\nплатежа', 3]),
              debit: this.getFirstValidCellByColumn(['Дебbт', 4]),
              credit: this.getFirstValidCellByColumn(['Кредит', 5]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UZS',
          preParserConfigs: {
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 10,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => (word === 'Дата' && etc?.nextWord === 'Дата'),
                (word, etc) => (word === 'платежа' && etc?.nextWord === 'Дня'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) => (word === 'Дня' && etc?.prevWord === 'платежа'),
                1,
              );
              self.deleteFromTo(
                (word, etc) => (word === 'Сумма' && etc?.nextWord === 'оборотов'),
                (word, etc) => (word === 'платежа' && etc?.nextWord === 'Дня'),
              );
              self.deleteFromTo(
                (word, etc) => (word === 'Сумма' && etc?.nextWord === 'оборотов'),
                (word, etc) => (moment(word, 'DD.MM.YYYY', true).isValid() && etc?.prevWord === 'ANK'),
              );
              self.deleteFromTo(
                // url validate regexp
                (word, etc) => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(word),
                (word, etc) => (moment(word, 'DD.MM.YYYY', true).isValid()),
              );
              self.deleteFromTo(
                (word, etc) => (word === 'Выписка' && etc?.nextWord === 'лицевого'),
                (word, etc) => (/Page \d+ of \d+/.test(word) && etc?.prevWord === ')'),
              );
              self.deleteFromTo(
                (word, etc) => (word === 'Сумма' && etc?.nextWord === 'оборотов'),
                (word, etc) => (/\d+\.\d+/.test(word.split(',').length ? word.split(',').slice(-1)[0] : '') && moment(etc?.prevWord, 'DD.MM.YYYY', true).isValid()),
              );
              self.defineOperation([
                (value) => true,
              ]);
            },
          },
        },
      },
    ],
  };
}
