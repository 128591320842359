import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import Loader from '../../../components/Loader/LottieLoader';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from '../../../services/accounts';
import belvoApi from '../../../services/bankIntegrations/belvo';
import { selectCurrentCompany } from '../../../store/company/selectors';
import belvoActions from '../../../store/integrations/belvoActions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import {
  BelvoAccount,
  IntegrationTypeId,
} from '../../../store/integrations/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import { getBalance } from '../../../utils/getBalance';
import ErrorDialog from '../../Integrations/ErrorDialog';
import AccountSelector from '../../Operations/Components/AccountSelector';
import { useStyles } from './styles';

function BelvoAccountsDialog({
  onCloseAccountsListDialog,
  onSuccess,
  belvoIntegration,
}: {
  onCloseAccountsListDialog(): void;
  onSuccess(): void;
  belvoIntegration: {
    integrationId: string;
    accountName: string;
    startDate: number;
    bankName: string;
  };
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const facebookContext = useContext(FacebookPixelContext);

  const [accounts, setAccounts] = useState<BelvoAccount[] | null>(null);
  const [currentAccount, setCurrentAccount] = useState<BelvoAccount | null>(
    null,
  );
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(false);

  const company = useSelector(selectCurrentCompany);

  const handleCloseErrorDialog = useCallback(() => {
    setAccounts(null);
    setShowErrorDialog(false);
    onCloseAccountsListDialog();
  }, [onCloseAccountsListDialog]);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  const handleChangeAccount = useCallback((val: any) => {
    setCurrentAccount(val);
  }, []);

  const handleIntegrationStart = useCallback(async () => {
    setIntegrationLoading(true);

    if (belvoIntegration && currentAccount) {
      const { integrationId, accountName, startDate, bankName } =
        belvoIntegration;

      const {
        data: { newAccount },
      } = await accountsApi.createAccount(
        currentAccount.currency,
        accountName,
        currentAccount.balance,
        IntegrationTypeId.Belvo,
      );

      const accountId = newAccount._id;

      dispatch(belvoActions.setBelvoIntegrationId(integrationId));

      const payload: CreateIntegrationPayload = {
        typeId: IntegrationTypeId.Belvo,
        accountId,
        startDate: new Date(startDate).getTime() + getTimeOffset(),
        bankName,
        facebookContext,
      };

      await belvoApi.updateCredentials({
        integrationId,
        accountId: currentAccount.id,
      });

      dispatch(belvoActions.createBelvoIntegration(payload));

      setIntegrationLoading(false);
      handleCloseDialog();

      onSuccess();
    }
  }, [
    belvoIntegration,
    dispatch,
    currentAccount,
    handleCloseDialog,
    facebookContext,
    onSuccess,
  ]);

  useEffect(() => {
    const getBelvoAccounts = async () => {
      try {
        setLoading(true);

        const { data: belvoAccounts } = await belvoApi.getIntegrationAccounts(
          belvoIntegration.integrationId,
        );

        setLoading(false);

        const formatAccounts = belvoAccounts.map((el) => ({
          ...el,
          name: `${el.name || ''} ${getBalance(el.balance)} ${el.currency}`,
        }));

        setAccounts(formatAccounts);
        setShowDialog(true);
      } catch (e) {
        setLoading(false);
        setShowErrorDialog(true);
      }
    };

    if (!showErrorDialog) {
      getBelvoAccounts();
    }
  }, [company, showErrorDialog, belvoIntegration]);

  if (showErrorDialog) {
    return <ErrorDialog onClose={handleCloseErrorDialog} />;
  }

  return (
    <Dialog
      isOpened={loading || showDialog}
      onClose={handleCloseDialog}
      title={t('operationDialogs.account.placeholder')}
    >
      {loading ? (
        <div className={classes.overlay}>
          <Loader size="medium" />
        </div>
      ) : (
        <></>
      )}
      <AccountSelector
        // @ts-ignore
        value={currentAccount}
        // @ts-ignore
        accounts={accounts}
        onChange={handleChangeAccount}
        disablePortal={false}
      />
      <CustomButton
        fullWidth
        action={handleIntegrationStart}
        loading={integrationLoading}
        title={t('bank.connectAccount')}
      />
    </Dialog>
  );
}

export default React.memo(BelvoAccountsDialog);
