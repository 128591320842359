import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseCSVPreParser,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class RaiffeisenImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        async isCurCase(file: File, preParser: BaseCSVPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
            { CSVDelimiter: ';' },
          );
          return rawDocument[0][0].startsWith('Дата операції');
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта операції', 7]),
            dateAndTime: moment(
              this.getFirstValidCellByColumn(['Дата операції', 0]),
              'DD.MM.YYYY, HH:mm',
            ).toISOString(),
            sum: this.getFirstValidCellByColumn(['Сума у валюті рахунку', 5]),
            comment: this.getFirstValidCellByColumn(['Деталі операції', 4]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: { CSVDelimiter: ';' },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта', 3]),
            dateAndTime: this.getFirstValidCellByColumn(['Дата операції', 4]),
            counterparty: this.getFirstValidCellByColumn(['Кореспондент', 10]),
            debit: this.getFirstValidCellByColumn(['Дебет', 13]),
            credit: this.getFirstValidCellByColumn(['Кредит', 14]),
            comment: this.getFirstValidCellByColumn([
              'Призначення платежу',
              15,
            ]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          encoding: 'win1251',
          preParserConfigs: { CSVDelimiter: ';' },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return (
            rawDocument?.length &&
            rawDocument[0].str.includes('АТ «Райффайзен Банк»')
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn([
              'Дата операції\n/Дата обробки\nоперації',
              0,
            ])?.replace(/\/[^_]+/m, ''),
            sum: this.getFirstValidCellByColumn(['Сума у валюті\nрахунку', 5]),
            comment: this.getFirstValidCellByColumn(['Деталі операції', 3]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word?.includes('Дата операції'),
                (word) => word?.includes('рахунку)'),
              );
              self.deleteFromTo(
                undefined,
                (_, etc) => etc?.nextWord?.includes('Дата операції'),
                1,
              );
              self.defineOperation([
                (value) => /^-?([,.\d\s]+,\d{2})$/.test(value), // '-1 000,00'
                (value) => /^[A-Z]{3}$/.test(value), // 'UAH'
              ]);
            },
          },
        },
      },
      // case 7
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return (
            rawDocument?.length &&
            rawDocument[0].str.includes('АТ "РАЙФФАЙЗЕН БАНК"')
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата опер.',
              0,
            ])?.replace(/\s/, ' '),
            counterparty: this.getFirstValidCellByColumn([
              'Реквізити кореспондента',
              4,
            ]),
            debit: this.getFirstValidCellByColumn(['Дебет', 2]),
            credit: this.getFirstValidCellByColumn(['Кредит', 3]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word?.includes('Номер'),
                (word) => word?.includes('док.'),
              );
              self.deleteFromTo(undefined, (word) => word?.includes('док.'), 1);
              self.deleteFromTo(
                (word) => word?.includes('ВСЬОГО:'),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => /^([\d\s]+\.\d{2})$/.test(value), // '1 000.00'
              ]);
            },
          },
        },
      },
      // case 3 4 6 8
      {
        proceedCase: (importDocument) => {
          const body = [];
          importDocument
            .slice(1)
            .filter(
              (arr) =>
                !arr[0]?.includes('Оборот за ') &&
                !arr[0]?.includes('Вихідний залишок на '),
            )
            .forEach((el, i) => {
              const [dateRaw] = el[1].split('\n');
              const [date] = dateRaw.split(' ');
              if (!moment(date, 'DD.MM.YYYY', true).isValid()) {
                const lastIndex = body.length - 1;
                if (el[0]) body[lastIndex][0] += `\n${el[0]}`;
                if (el[1]) body[lastIndex][1] += `\n${el[1]}`;
                if (el[2]) body[lastIndex][2] += `\n${el[2]}`;
                if (el[3]) body[lastIndex][3] += `\n${el[3]}`;
                if (el[4]) body[lastIndex][4] += `\n${el[4]}`;
                if (el[5]) body[lastIndex][5] += `\n${el[5]}`;
                if (el[6]) body[lastIndex][6] += `\n${el[6]}`;
              } else {
                body.push(el);
              }
            });
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => {
            const [date, time] = this.getFirstValidCellByColumn([
              'Дата\nоперації',
              'Дата операції',
              1,
            ])?.split('\n');
            let dateAndTime = date;
            if (time) {
              dateAndTime += ` ${time}`;
            }
            return {
              dateAndTime,
              comment: this.getFirstValidCellByColumn([
                'Призначення платежу',
                3,
              ]),
              debit: this.getFirstValidCellByColumn(['Витрати\n(дебет)']),
              credit: this.getFirstValidCellByColumn([
                'Витрати\n(кредит)',
                'Надходження\n(кредит)',
              ]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            maxInterlineSpacingPx: 10,
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word?.includes('Реквізити кореспондента'),
                (word) => word?.includes('Найменування') || word === 'Назва',
              );
              self.deleteFromTo(
                undefined,
                (_, etc) =>
                  etc?.prevWord?.includes('Найменування') ||
                  etc?.prevWord === 'Назва',
                1,
              );
              self.deleteFromTo(
                (_, etc) => etc?.nextWord?.includes('Обороти за період'),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => /^([\s\d]+,\d+)$/.test(value), // 75 000,00
              ]);
            },
          },
        },
      },
    ],
  };
}
