import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import { isNotEmpty } from 'class-validator';

export class UkrsibBankImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) =>
              value.str.includes('Виписка за картковим рахунком клієнта'),
            ),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const sum = this.getFirstValidCellByColumn(['Сума в валюті', 4]);
            const [currency, ...sumArr] = (
              this.getFirstValidCellByColumn(['Операція', 3]) || ''
            )
              .trim()
              .split(/\s/);
            return {
              date: this.getFirstValidCellByColumn([
                'Дата\nрозрахунку',
                1,
              ])?.split(/\s/)[0],
              sum: sum || sumArr.join(' '),
              comment: this.getFirstValidCellByColumn([
                'Опис операції',
                2,
              ])?.replace(/^[0-9]{6}/, ''),
              currency,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            pageSeparatorsLengthPx: 60,
            separatorsDistanceModifier: 0.2,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') && etc.nextWord?.includes('Дата'),
                (word, etc) =>
                  word?.includes('рахунку') && etc.prevWord?.includes('Сума'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('Операції') && etc.nextWord?.includes('Дата'),
                1,
              );
              self.deleteFromTo(
                (word) => word?.includes('Поточні блокування по рахунку'),
                undefined,
                1,
              );
              self.deleteFromTo(
                (word) => word?.includes('Разом списання коштів'),
                (_, etc) => etc.prevWord?.includes('Разом поповнень'),
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        // case 6
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          const res1 = isNotEmpty(
            rawDocument.find(
              (value, i) =>
                rawDocument[i].str +
                  rawDocument[i + 1]?.str +
                  rawDocument[i + 2]?.str +
                  rawDocument[i + 3]?.str +
                  rawDocument[i + 4]?.str ===
                'Виписказакартковимрахункомклієнта',
            ),
          );
          const res2 = isNotEmpty(
            rawDocument.find(
              (value, i) =>
                rawDocument[i].str +
                  rawDocument[i + 1]?.str +
                  rawDocument[i + 2]?.str +
                  rawDocument[i + 3]?.str +
                  rawDocument[i + 4]?.str +
                  rawDocument[i + 5]?.str +
                  rawDocument[i + 6]?.str ===
                  'Інформаціяпропоточнузаборгованістьпорахункуна' &&
                moment(rawDocument[i + 7]?.str, 'DD.MM.YYYY', true).isValid(),
            ),
          );
          return res1 && res2;
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          const body = [];
          let finded = false;
          importDocument.slice(1).forEach((arr) => {
            if (finded) {
              body.push(arr);
              return;
            }
            if (arr[0] === '' && arr[1] === '') {
              finded = true;
              body.push(arr);
            }
          });
          const result = [];
          let comments = [];
          let currentOp = [];
          const sumWithCurrencyAndComment = (str) => {
            if (!str) return { comment: '', sumWithCurrency: '' };
            let comment = '';
            let sumWithCurrency = '';
            let finish = false;
            const parts = str.split(' ');
            parts.forEach((part, i) => {
              if (finish) return;
              if (part.length === 3) {
                const rest = parts.slice(i + 1);
                const sum = rest.join('');
                const isNum = !isNaN(Number(sum));
                if (isNum) {
                  finish = true;
                  sumWithCurrency = `${part} ${sum}`;
                  return;
                }
              }
              comment += part + ' ';
            });
            return { comment, sumWithCurrency };
          };
          body.forEach((arr) => {
            if (moment(arr[0], 'DD.MM.YYYY', true).isValid()) {
              let lastComment = comments[comments.length - 1];
              if (currentOp.length) {
                currentOp[2] = comments.slice(0, -1).join('');
                result.push([...currentOp]);
                currentOp = [];
              }
              comments = [lastComment];
              currentOp[0] = arr[0];
              const sumWithCurrency = sumWithCurrencyAndComment(
                arr[3],
              ).sumWithCurrency;
              if (arr[1]) currentOp[1] = arr[1];
              if (sumWithCurrency) currentOp[3] = sumWithCurrency;
              if (arr[4]) currentOp[4] = arr[4].replaceAll(' ', '');
            } else {
              const sumWithCurrency = sumWithCurrencyAndComment(
                arr[3],
              ).sumWithCurrency;
              if (arr[1])
                currentOp[1] = currentOp[1] ? currentOp[1] + arr[1] : arr[1];
              if (sumWithCurrency) currentOp[3] = sumWithCurrency;
              if (arr[4])
                currentOp[4] = currentOp[4] ? currentOp[4] + arr[4] : arr[4];
            }
            let localComment = '';
            if (arr[2]) {
              localComment += arr[2] + ' ';
            }
            const comment = sumWithCurrencyAndComment(arr[3]).comment;
            if (comment) {
              localComment += comment + ' ';
            }
            if (localComment) {
              comments.push(localComment);
            }
          });
          currentOp[2] = comments.join('');
          result.push([...currentOp]);

          this.setDocumentBody(result);
          return (): ImportResultItemMask => {
            const sum = this.getFirstValidCellByColumn(['Сума в валюті', 4]);
            const [currency, ...sumArr] = (
              this.getFirstValidCellByColumn(['Операція', 3]) || ''
            )
              .trim()
              .split(/\s/);
            return {
              date: this.getFirstValidCellByColumn([
                'Дата\nоперації',
                0,
              ])?.split(/\s/)[0],
              dateOfPayment: this.getFirstValidCellByColumn([
                'Дата\nрозрахунку',
                1,
              ])?.split(/\s/)[0],
              sum: sum || sumArr.join(' '),
              comment: this.getFirstValidCellByColumn([
                'Опис операції',
                2,
              ])?.replace(/^[0-9]{6}/, ''),
              currency,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            pageSeparatorsLengthPx: 60,
            separatorsDistanceModifier: 0.2,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') && etc.nextWord?.includes('Дата'),
                (word, etc) =>
                  word?.includes('рахунку') && etc.prevWord?.includes('Сума'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('рахунком') && etc.nextWord?.includes('Дата'),
                1,
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Разом') &&
                  etc?.nextWord?.includes('списання'),
                (word, etc) =>
                  !isNaN(Number(word?.replaceAll(' ', ''))) &&
                  etc?.prevWord?.includes('поповнень'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Поточні') &&
                  etc?.nextWord?.includes('блокування'),
                (word, etc) =>
                  word?.includes('рахунку') && etc?.prevWord?.includes('по'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Операції') && etc?.nextWord?.includes('за'),
                (word, etc) =>
                  word?.includes('рахунком') &&
                  etc?.prevWord?.includes('картковим'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Операції') && etc?.nextWord?.includes('за'),
                (word, etc) =>
                  word?.includes('картками') &&
                  etc?.prevWord?.includes('платіжними'),
              );
              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Дата') && etc.nextWord?.includes('Дата'),
                (word, etc) =>
                  word?.includes('рахунку') && etc.prevWord?.includes('Сума'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Разом') &&
                  etc?.nextWord?.includes('списання'),
                (word, etc) =>
                  !isNaN(Number(word?.replaceAll(' ', ''))) &&
                  etc?.prevWord?.includes('картками'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Разом') &&
                  etc?.nextWord?.includes('поповнень'),
                (word, etc) =>
                  !isNaN(Number(word?.replaceAll(' ', ''))) &&
                  etc?.prevWord?.includes('картками'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Вклади') &&
                  etc?.nextWord?.includes('гарантуються'),
                (word, etc) =>
                  word?.includes('.') && etc.prevWord?.includes('ФОП'),
              );

              self.defineOperation([(value) => true]);
            },
          },
        },
      },
      {
        // case 7
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          const res1 = isNotEmpty(
            rawDocument.find(
              (value, i) =>
                rawDocument[i].str +
                  rawDocument[i + 1]?.str +
                  rawDocument[i + 2]?.str +
                  rawDocument[i + 3]?.str +
                  rawDocument[i + 4]?.str ===
                'Виписказакартковимрахункомклієнта',
            ),
          );
          const res2 = isNotEmpty(
            rawDocument.find(
              (value, i) =>
                rawDocument[i].str +
                  rawDocument[i + 1]?.str +
                  rawDocument[i + 2]?.str +
                  rawDocument[i + 3]?.str +
                  rawDocument[i + 4]?.str +
                  rawDocument[i + 5]?.str +
                  rawDocument[i + 6]?.str ===
                  'Інформаціяпропоточнузаборгованістьпорахункуна' &&
                moment(rawDocument[i + 7]?.str, 'DD.MM.YYYY', true).isValid(),
            ),
          );
          return res1 && !res2;
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const sum = this.getFirstValidCellByColumn([
              'Сума у\nвалюті рахунку',
              6,
            ]);
            const currency = this.getFirstValidCellByColumn([
              'Валюта\nтранзакції валюті рахунку',
              5,
            ]);
            const sumArr = (
              this.getFirstValidCellByColumn([
                'Сума у валюті\nтранзакції',
                4,
              ]) || ''
            )
              .trim()
              .split(/\s/);
            return {
              date: this.getFirstValidCellByColumn([
                'Дата\nоперації',
                0,
              ])?.split(/\s/)[0],
              dateOfPayment: this.getFirstValidCellByColumn([
                'Дата\nрозрахунку',
                1,
              ])?.split(/\s/)[0],
              sum: sum || sumArr.join(' '),
              comment: this.getFirstValidCellByColumn([
                'Опис операції',
                3,
              ])?.replace(/^[0-9]{6}/, ''),
              currency,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            pageSeparatorsLengthPx: 4,
            separatorsDistanceModifier: 0.2,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') && etc.nextWord?.includes('Дата'),
                (word, etc) =>
                  word?.includes('рахунку') && etc.prevWord?.includes('валюті'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('рахунком') && etc.nextWord?.includes('Дата'),
                1,
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Разом') &&
                  etc?.nextWord?.includes('списання'),
                (word, etc) =>
                  !isNaN(Number(word?.replaceAll(' ', ''))) &&
                  etc?.prevWord?.includes('поповнень'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Поточні') &&
                  etc?.nextWord?.includes('блокування'),
                (word, etc) =>
                  word?.includes('рахунку') && etc?.prevWord?.includes('по'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Операції') && etc?.nextWord?.includes('за'),
                (word, etc) =>
                  word?.includes('рахунком') &&
                  etc?.prevWord?.includes('картковим'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Операції') && etc?.nextWord?.includes('за'),
                (word, etc) =>
                  word?.includes('картками') &&
                  etc?.prevWord?.includes('платіжними'),
              );
              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Дата') && etc.nextWord?.includes('Дата'),
                (word, etc) =>
                  word?.includes('рахунку') && etc.prevWord?.includes('валюті'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Разом') &&
                  etc?.nextWord?.includes('списання'),
                (word, etc) =>
                  !isNaN(Number(word?.replaceAll(' ', ''))) &&
                  etc?.prevWord?.includes('картками'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Разом') &&
                  etc?.nextWord?.includes('поповнень'),
                (word, etc) =>
                  !isNaN(Number(word?.replaceAll(' ', ''))) &&
                  etc?.prevWord?.includes('картками'),
              );

              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Вклади') &&
                  etc?.nextWord?.includes('гарантуються'),
                (word, etc) =>
                  word?.includes('.') && etc.prevWord?.includes('ФОП'),
              );

              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      // case 8
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find(
              (value, i) =>
                moment(rawDocument[i]?.str, ': DD.MM.YYYY', true).isValid() &&
                rawDocument[i + 1]?.str === 'No' &&
                rawDocument[i + 2]?.str === 'Дата' &&
                rawDocument[i + 3]?.str === 'опер' &&
                rawDocument[i + 4]?.str === '.' &&
                rawDocument[i + 5]?.str === 'Дебет' &&
                rawDocument[i + 6]?.str === 'Кредит' &&
                rawDocument[i + 7]?.str === 'Реквізити',
            ),
          );
        },
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = [];
          importDocument.slice(1).forEach((el, i) => {
            if (moment(el[0], 'HH:mm', true).isValid()) {
              const lastIndex = body.length - 1;
              body[lastIndex][0] += `\n${el[0]}`;
              if (el[1]) body[lastIndex][1] += `\n${el[1]}`;
              if (el[2]) body[lastIndex][2] += `\n${el[2]}`;
              if (el[3]) body[lastIndex][3] += `\n${el[3]}`;
              if (el[4]) body[lastIndex][4] += `\n${el[4]}`;
              if (el[5]) body[lastIndex][5] += `\n${el[5]}`;
            } else {
              body.push(el);
            }
          });
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата опер.',
              0,
            ])?.replace('\n', ' '),
            debit: this.getFirstValidCellByColumn(['Дебет', 2]),
            credit: this.getFirstValidCellByColumn(['Кредит', 3]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            separatorsDistanceModifier: 0.2,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => word === 'No' && etc?.nextWord === 'Дата',
                (word, etc) =>
                  word === 'документа' && etc?.prevWord === 'платежу',
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('платежу') &&
                  etc.prevWord?.includes('Призначення'),
                1,
              );
              self.deleteFromTo(
                (word) => word?.includes('Обороти:'),
                undefined,
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      // case 4, case 9
      {
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = [];
          importDocument.slice(1).forEach((el, i) => {
            if (moment(el[0], 'HH:mm', true).isValid()) {
              const lastIndex = body.length - 1;
              body[lastIndex][0] += `\n${el[0]}`;
              if (el[1]) body[lastIndex][1] += `\n${el[1]}`;
              if (el[2]) body[lastIndex][2] += `\n${el[2]}`;
              if (el[3]) body[lastIndex][3] += `\n${el[3]}`;
              if (el[4]) body[lastIndex][4] += `\n${el[4]}`;
              if (el[5]) body[lastIndex][5] += `\n${el[5]}`;
            } else {
              body.push(el);
            }
          });
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата опер.',
              0,
            ])?.replace('\n', ' '),
            debit: this.getFirstValidCellByColumn(['Дебет', 2]),
            credit: this.getFirstValidCellByColumn(['Кредит', 3]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            separatorsDistanceModifier: 0.7,
            rotate: true,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => word?.includes('Дата опер.'), //|| (word === 'No' && etc?.nextWord === 'Дата'),
                (word, etc) => word?.includes('Призначення платежу'), //|| (word === 'документа' && etc?.prevWord === 'платежу'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('платежу') &&
                  etc.prevWord?.includes('Призначення'),
                1,
              );
              self.deleteFromTo(
                (word) => word?.includes('Обороти:'),
                undefined,
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(), ///^([\s\d]+,\d+)$/.test(value), // 75 000,00
              ]);
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(
            importDocument
              .slice(1)
              .filter((raw) => !raw.every((el) => el === '')),
          );
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта', 3]),
            dateAndTime: this.getFirstValidCellByColumn(['Дата операции', 4]),
            counterparty: this.getFirstValidCellByColumn(['Корреспондент', 10]),
            debit: this.getFirstValidCellByColumn(['Дебет', 13]),
            credit: this.getFirstValidCellByColumn(['Кредит', 14]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 15]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
  };
}
