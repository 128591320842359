import './styles.scss';
import { Typography } from '@material-ui/core';
import { Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import cn from 'classnames';
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SystemIcon from '../../../../../assets/images/svg/bank.svg';
import DragAndDropIcon from '../../../../../assets/images/svg/drug_drop_small.svg';
import EditIcon from '../../../../../assets/images/svg/edit.svg';
import UnVisibleIcon from '../../../../../assets/images/svg/eye-hide.svg';
import CustomButton from '../../../../../components/Button';
import Dialog from '../../../../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../../../../components/PreviewRegisterDialog';
import Tooltip from '../../../../../components/Tooltip';
import { ALLOW_CREATE_COMPANY_FROM_PREVIEW } from '../../../../../constants/featureToggles/featureToggle';
import useUnleash from '../../../../../hooks/useUnleash';
import categoryActions from '../../../../../store/categories/actions';
import {
  selectConsumptionSettingsSortableTreeByType,
  selectIncomeSettingsSortableTreeByType,
} from '../../../../../store/categories/selectors';
import {
  Category,
  SubCategory,
  TreeDataItemProps,
} from '../../../../../store/categories/types';
import commonActions from '../../../../../store/common/actions';
import { OperationType } from '../../../../../store/operations/types';
import { isPreviewPage } from '../../../../../utils/isLocalhost';
import ExportItemsToExcel from '../ExportItemsToExcel';
import CreateRow from './CreateRow';
import EditRow from './EditRow';
import { useStyles } from './styles';
import { Props } from './types';

function CategoriesDialog(props: Props) {
  const {
    type,
    onClose,
    isEditable,
    customEditHandler,
    showInDialog = true,
    showCreateButton = true,
  } = props;

  const [gData, setGData] = useState<TreeDataItemProps[]>([]);
  const [showEditRow, setShowEditRow] = useState(false);
  const [currentCategory, setCurrentCategory] =
    useState<TreeDataItemProps | null>(null);
  const [showCreateRow, setShowCreateRow] = useState(false);
  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);
  const [showAllCategories, setShowAllCategories] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState<(string | number)[]>([]);

  const incomeCategories = useSelector(selectIncomeSettingsSortableTreeByType);
  const consumptionCategories = useSelector(
    selectConsumptionSettingsSortableTreeByType,
  );

  const categories =
    type === OperationType.income ? incomeCategories : consumptionCategories;

  const hasSubCategories = useMemo(
    () => Boolean(categories.find((el) => el?.subcategories.length)),
    [categories],
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const featureAllowCompanyCreateEnable = useUnleash(
    ALLOW_CREATE_COMPANY_FROM_PREVIEW,
  );

  const oldTooltipsCategories: string[] = useMemo(() => ['salary', 'tax'], []);

  const handleShowRestrictedDialog = useCallback(() => {
    dispatch(commonActions.setShowRestrictedDialog(true));
  }, [dispatch]);

  const handleSetExpandedKeys = useCallback(() => {
    if (!showAllCategories) {
      return;
    }

    const ids = categories.reduce((acc, el) => {
      if (el?.subcategories.length) {
        acc.push(el._id);
      }

      return acc;
    }, [] as string[]);

    setExpandedKeys(ids);
  }, [categories, showAllCategories]);

  const handleToggleShowAllCategories = useCallback(() => {
    setShowAllCategories(!showAllCategories);

    if (showAllCategories) {
      setExpandedKeys([]);
    } else {
      handleSetExpandedKeys();
    }
  }, [showAllCategories, handleSetExpandedKeys]);

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const handleOpenEditRow = useCallback(
    (cat?: Category | SubCategory) => {
      if (!isEditable) {
        handleShowRestrictedDialog();
      } else if (customEditHandler && cat) {
        // @ts-ignore
        customEditHandler(cat);
      } else {
        setShowEditRow(true);
      }
    },
    [isEditable, customEditHandler, handleShowRestrictedDialog],
  );

  const handleCloseEditRow = useCallback(() => {
    setShowEditRow(false);
  }, []);

  const handleOpenCreateDialog = useCallback(() => {
    handleClosePreviewRegisterDialog();

    if (!isEditable) {
      handleShowRestrictedDialog();
    } else {
      setShowCreateRow(true);
    }
  }, [
    handleClosePreviewRegisterDialog,
    handleShowRestrictedDialog,
    isEditable,
  ]);

  const handleCloseCreateDialog = useCallback(() => {
    setShowCreateRow(false);
  }, []);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleExpand = useCallback((ids: (string | number)[]) => {
    setExpandedKeys(ids);
  }, []);

  const handleUpdate = useCallback(
    (label: string, visible: boolean) => {
      if (currentCategory) {
        const category = {
          label,
          visible,
          _id: currentCategory._id,
          orderIndex: currentCategory.orderIndex,
        };

        // @ts-ignore
        dispatch(categoryActions.updateSettingsCategories([category], type));
      }

      handleCloseEditRow();
      setCurrentCategory(null);
    },
    [dispatch, currentCategory, handleCloseEditRow, type],
  );

  const handleDelete = useCallback(
    (id: string) => {
      dispatch(categoryActions.deleteCategory(id, type));
      handleCloseEditRow();
    },
    [dispatch, handleCloseEditRow, type],
  );

  const handleCreate = useCallback(
    (name: string) => {
      dispatch(categoryActions.createSettingsCategory(name, type));
    },
    [dispatch, type],
  );

  const onDrop = useCallback(
    (info: any) => {
      if (!isEditable) {
        handleShowRestrictedDialog();

        return;
      }

      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      const dropPos = info.node.pos.split('-');
      const dropPosition =
        info.dropPosition - Number(dropPos[dropPos.length - 1]);

      if (
        (info.node.parentId && !info.dropToGap) ||
        (info.node.systemValue && !info.dropToGap)
      ) {
        return;
      }

      // @ts-ignore
      const loop = (data, key, callback) => {
        for (let i = 0; i < data.length; ++i) {
          if (data[i].key === key) {
            return callback(data[i], i, data);
          }
          if (data[i].children) {
            loop(data[i].children, key, callback);
          }
        }
      };
      const data = [...gData];

      // Find dragObject
      // @ts-ignore
      let dragObj;
      // @ts-ignore
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });

      if (!info.dropToGap) {
        // Drop on the content
        // @ts-ignore
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert 示例添加到头部，可以是随意位置
          // @ts-ignore
          item.children.unshift(dragObj);
        });
      } else if (
        (info.node.props.children || []).length > 0 && // Has children
        info.node.props.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        // @ts-ignore
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert 示例添加到头部，可以是随意位置
          // @ts-ignore
          item.children.unshift(dragObj);
          // in previous version, we use item.children.push(dragObj) to insert the
          // item to the tail of the children
        });
      } else {
        let ar;
        let i;
        // @ts-ignore
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          // @ts-ignore
          ar.splice(i, 0, dragObj);
        } else {
          // @ts-ignore
          ar.splice(i + 1, 0, dragObj);
        }
      }

      const sortedList = data.map((el, index) => {
        const { children, _id } = el;

        let subcategories: any[] = [];

        if (children?.length) {
          subcategories = children.map((ch, inx) => ({
            ...ch,
            orderIndex: inx,
            parentId: _id,
          }));
        }

        return {
          ...el,
          parentId: null,
          subcategories,
          children: subcategories,
          orderIndex: index,
        };
      });

      const wrongList = sortedList.some((el) => {
        const { subcategories } = el;

        return subcategories?.some((subCat) =>
          Boolean(subCat?.subcategories?.length),
        );
      });

      if (wrongList) {
        return;
      }

      const formatToUpdate = sortedList.reduce((acc, el) => {
        const { subcategories } = el;

        if (subcategories?.length) {
          subcategories.forEach((subEl) =>
            acc.push({
              _id: subEl._id,
              label: subEl.label,
              visible: subEl.visible,
              orderIndex: subEl.orderIndex,
              // @ts-ignore
              parentId: subEl.parentId,
            }),
          );
        }

        acc.push({
          _id: el._id,
          label: el.label,
          visible: el.visible,
          orderIndex: el.orderIndex,
          // @ts-ignore
          parentId: null,
        });

        return acc;
      }, [] as (Category | SubCategory)[]);

      dispatch(categoryActions.updateSettingsCategories(formatToUpdate, type));
      setGData(sortedList);
    },
    [gData, dispatch, type, isEditable, handleShowRestrictedDialog],
  );

  const handleEditClick = useCallback(
    (event: SyntheticEvent) => {
      if (!isEditable) {
        return;
      }

      if (featureAllowCompanyCreateEnable && isPreviewPage()) {
        handleOpenPreviewRegisterDialog();

        return;
      }

      const { id } = event.currentTarget;

      const index = categories.findIndex((el) => el._id === id);

      if (index !== -1) {
        if (categories[index].systemValue) {
          return;
        }

        if (categories[index].visible) {
          if (customEditHandler) {
            customEditHandler(categories[index]);
          } else {
            // @ts-ignore
            setCurrentCategory(categories[index]);

            handleOpenEditRow();
          }

          return;
        }

        const payload = {
          label: categories[index].label,
          _id: categories[index]._id,
          visible: true,
        };

        // @ts-ignore
        dispatch(categoryActions.updateSettingsCategories([payload], type));

        return;
      }

      categories.forEach((el) => {
        const { subcategories } = el;

        const subCat = subcategories?.find((subEl) => subEl._id === id);

        if (subCat) {
          if (subCat.visible) {
            // @ts-ignore
            setCurrentCategory(subCat);
            handleOpenEditRow(subCat);
          } else {
            subCat.visible = true;
            dispatch(categoryActions.updateSettingsCategories([subCat], type));
          }
        }
      });
    },
    [
      type,
      dispatch,
      categories,
      isEditable,
      customEditHandler,
      handleOpenEditRow,
      featureAllowCompanyCreateEnable,
      handleOpenPreviewRegisterDialog,
    ],
  );

  const renderRow = useCallback(
    (nodeData: DataNode & Category & { parentId: string; title: string }) => (
      <div
        className={cn(
          classes.row,
          !nodeData.visible && classes.opacity,
          !isEditable && classes.disabled,
        )}
        id={nodeData.key.toString()}
        onClick={handleEditClick}
      >
        <div
          className={cn(
            classes.leftBlock,
            nodeData.parentId && classes.marginLeft35,
            nodeData.parentId && classes.subShortNames,
            nodeData.parentId &&
              nodeData.title.length > 33 &&
              classes.subShortLength,
          )}
        >
          {isEditable && (
            <img
              alt=""
              src={DragAndDropIcon}
              className={cn(classes.opacity, classes.margin10)}
            />
          )}
          {!isEditable && <div className={classes.margin10} />}
          <Typography
            className={cn(
              !nodeData.parentId && classes.categoryText,
              classes.shortNames,
            )}
          >
            {nodeData.title}
          </Typography>
        </div>
        {nodeData.systemValue && (
          <Tooltip
            title={
              oldTooltipsCategories.includes(nodeData.normalizedLabel)
                ? t('settingsPage.systemCategoryTooltip')
                : t('settingsPage.systemCategoryNewTooltip')
            }
          >
            <img alt="system" src={SystemIcon} />
          </Tooltip>
        )}
        {!nodeData.systemValue && isEditable && (
          <div className={classes.rightBlock}>
            {nodeData.visible ? (
              <img alt="" src={EditIcon} />
            ) : (
              <img alt="" src={UnVisibleIcon} />
            )}
          </div>
        )}
      </div>
    ),
    [t, classes, handleEditClick, oldTooltipsCategories, isEditable],
  );

  const Component = useCallback(
    () => (
      <>
        <div>
          {showCreateRow && (
            <CreateRow
              type={type}
              onCreateCategory={handleCreate}
              onClose={handleCloseCreateDialog}
            />
          )}
          {showEditRow && showCreateButton && (
            <EditRow
              type={type}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              onClose={handleCloseEditRow}
              item={currentCategory}
            />
          )}
          {!showEditRow && !showCreateRow && showCreateButton && (
            <div className={classes.buttonContainer}>
              <CustomButton
                action={handleOpenPreviewRegisterDialog}
                className={classes.button}
                title={t('category.add')}
              />
              <ExportItemsToExcel />
            </div>
          )}
          {hasSubCategories && (
            <Typography
              className={cn(classes.openCategoriesText)}
              onClick={handleToggleShowAllCategories}
            >
              {showAllCategories
                ? t('settingsPage.closeCategories')
                : t('settingsPage.openCategories')}
            </Typography>
          )}
        </div>
        {/* @ts-ignore */}
        <Tree
          defaultExpandAll
          className={cn('draggable-tree')}
          expandedKeys={expandedKeys}
          draggable
          onDrop={onDrop}
          blockNode
          selectable={false}
          treeData={gData}
          // @ts-ignore
          titleRender={renderRow}
          onExpand={handleExpand}
          disabled={!isEditable}
        />
      </>
    ),
    [
      t,
      type,
      gData,
      onDrop,
      classes,
      renderRow,
      isEditable,
      showEditRow,
      expandedKeys,
      handleCreate,
      handleDelete,
      handleExpand,
      handleUpdate,
      showCreateRow,
      currentCategory,
      showCreateButton,
      hasSubCategories,
      showAllCategories,
      handleCloseEditRow,
      handleCloseCreateDialog,
      handleToggleShowAllCategories,
      handleOpenPreviewRegisterDialog,
    ],
  );

  useEffect(() => {
    setGData(categories);

    handleSetExpandedKeys();
  }, [categories, handleSetExpandedKeys]);

  return (
    <>
      {showInDialog && (
        <Dialog
          title={
            type === 'income'
              ? t('settingsPage.incomeCategories.title')
              : t('settingsPage.consumptionCategories.title')
          }
          isOpened
          onClose={handleClose}
          className={classes.dialog}
          titleClassName={classes.title}
        >
          <Component />
        </Dialog>
      )}
      {!showInDialog && <Component />}

      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={handleOpenCreateDialog}
        />
      )}
    </>
  );
}

export default React.memo(CategoriesDialog);
