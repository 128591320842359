import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import {
  BelvoBankType,
  BelvoCredentials,
} from '../../../store/integrations/types';
import belvoApi from '../../../services/bankIntegrations/belvo';
import BelvoField from './BelvoField';

type Props = {
  onClose(): void;
  bank: BelvoBankType;
  isOpened: boolean;
  onSuccess(integrationId: string): void;
};

function BelvoCredsDialog(props: Props) {
  const { onClose, bank, onSuccess, isOpened } = props;
  const [fieldErrors, setFieldErrors] = useState<boolean[]>(
    bank.form_fields.map(
      (el) =>
        !(
          el.optional ||
          el.type === 'hidden' ||
          (el.type === 'select' && el.values?.length)
        ),
    ),
  );
  const [fieldValues, setFieldValues] = useState<(string | null)[]>(
    bank.form_fields.map((el) => el.value || null),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [respError, setRespError] = useState<string | undefined>(undefined);

  const { t } = useTranslation();

  const handleSetFieldError = useCallback(
    (hasError: boolean, index: number) => {
      const newErrorsArr = [...fieldErrors];
      newErrorsArr[index] = hasError;
      setFieldErrors(newErrorsArr);
    },
    [fieldErrors],
  );

  const handleSetFieldValue = useCallback(
    (value: string | null, index: number) => {
      const newValuesArr = [...fieldValues];
      newValuesArr[index] = value;
      setFieldValues(newValuesArr);
    },
    [fieldValues],
  );

  const handleCreateConnection = useCallback(async () => {
    const hasError = fieldErrors.some((el) => el);
    if (hasError) return;

    const usernameFieldIndex = bank.form_fields.findIndex(
      (el) => el.name === 'username',
    );
    const payload: BelvoCredentials = {
      fetch_resources: bank.resources,
      institution: bank.name_code,
      username: fieldValues[usernameFieldIndex] as string,
    };
    bank.form_fields.forEach((el, i) => {
      if (i === usernameFieldIndex) return;

      if (fieldValues[i] !== null) {
        payload[el.name] = fieldValues[i];
      }
    });

    try {
      setLoading(true);

      const { data: belvoCredentials } = await belvoApi.getCredentials(payload);

      onSuccess(belvoCredentials.integrationId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setRespError(
        // @ts-ignore
        error?.response?.data?.message || t('bank.loadAccountsError.title'),
      );
    }
  }, [fieldErrors, bank, fieldValues, onSuccess, t]);

  return (
    <>
      <Dialog
        isOpened={isOpened}
        onClose={onClose}
        title={t('accounts.yourLoginAndPassword')}
      >
        <>
          {bank.form_fields.map((el, i) => (
            <BelvoField
              key={el.name}
              index={i}
              field={el}
              setError={handleSetFieldError}
              setValue={handleSetFieldValue}
            />
          ))}
        </>
        <CustomButton
          disabled={fieldErrors.some((el) => el)}
          title={t('bank.set')}
          action={handleCreateConnection}
          fullWidth
          loading={loading}
          errorText={respError}
        />
      </Dialog>
    </>
  );
}

export default React.memo(BelvoCredsDialog);
