import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class IpalYuliBankParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        proceedCase: (importDocument) => {
          const header = [];
          const headerRows = [];
          let rowNum = -1;
          const body = [];
          for(let i = 0; i < importDocument.length; i++) {
            const row = importDocument[i];
            if(row.includes('№ пп')) {
              row.forEach((e, i) => {
                if(!e && rowNum === -1) return;
                if(e) {
                  rowNum++;
                  headerRows[rowNum] = [i];
                  header.push(e);
                } else {
                  headerRows[rowNum].push(i);
                }
              })
            } else if(header.length) {
              const arr = [];
              row.forEach((e,i) => {
                if(e) {
                  let headerCount;
                  headerRows.forEach((el, ix) => {
                    if(el.includes(i)) {
                      headerCount = ix
                    }
                  })
                  if(headerCount !== undefined) {
                    if(arr[headerCount]) {
                      arr[headerCount] += e;
                    } else {
                      arr[headerCount] = e;
                    }

                  }
                }
              })
              if(arr.length && arr[4]) body.push(arr);
            }
          }
          
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата документа', 0]),
            debit: this.getFirstValidCellByColumn(['Обороты по дебету', 6]),
            credit: this.getFirstValidCellByColumn(['Обороты по кредиту', 7]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 8]),
          });
        },
        caseOptions: { defaultCurrency: 'UZS' },
      },
    ],
  };
}
