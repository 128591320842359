import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class ForteBankImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      // case 4
      {
        isCurCase: async (file: File, preParser: BasePDFPreParser) => {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return rawDocument[0]?.str?.includes('Выписка по счету');
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const [sum, currency] = this.getFirstValidCellByColumn([
              'Сумма\nоперации в\nвалюте\nсчета\n(+/-)',
              4
            ]).split(' ');
            return {
              dateAndTime: this.getFirstValidCellByColumn([
                'Дата операции',
                0
              ]).replaceAll('\n', ' '),
              sum,
              currency,
              comment: this.getFirstValidCellByColumn(['Описание операции', 2]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          isDESCOpOrder: true,
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 3,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата операции') && etc?.nextWord.includes('Дата'),
                (word, etc) =>
                  etc?.nextWord?.includes('******') && word?.includes('(+/-)'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) => word?.includes('******') && etc?.prevWord?.includes('(+/-)'),
                1,
              );
              self.deleteFromTo(
                (word, etc) => word?.includes('Заблокированные/ожидающие списания суммы:') && etc?.nextWord.includes('Дата операции'),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => moment(value, 'YYY-MM-DD', true).isValid(),
              ]);
            },
          },
        },
      },
      // case 1 2 3
      {
        proceedCase: (importDocument) => {
          // need parsing currency from importDocument
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            let dateAndTime = '';
            const dateAndTimeArr = this.getFirstValidCellByColumn([
              'Дата',
              1,
            ]).split('\n');
            for (let i = 0; i < dateAndTimeArr.length; i++) {
              if (moment(dateAndTime, 'DD.MM.YYYY', true).isValid())
                dateAndTime += ' ';
              dateAndTime += dateAndTimeArr[i];
            }

            return {
              dateAndTime,
              counterpartyIncome: this.getFirstValidCellByColumn([
                'Отправитель (Наименование,',
                3,
              // @ts-ignore
              ])?.replace(/БИН(.){0,}/s, ''),
              counterpartyConsumption: this.getFirstValidCellByColumn([
                'Получатель (Наименование,',
                4,
              // @ts-ignore
              ])?.replace(/БИН(.){0,}/s, ''),
              debit: this.getFirstValidCellByColumn(['Дебет /', 5]), // multifield header
              credit: this.getFirstValidCellByColumn(['Кредит /', 6]),
              comment: this.getFirstValidCellByColumn(['Төлемнің', 7]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          isDESCOpOrder: true,
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 3,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  (word?.startsWith('№') || word?.startsWith('No')) &&
                  (etc?.nextWord.includes('Дата') || etc?.nextWord.includes('Күні')),
                (word, etc) =>
                  ((etc?.prevWord?.includes('Төлемнің') && word?.includes('Курс')) || (etc?.prevWord?.includes('ИИК, БИН/ИИН)') && word?.includes('ИИК, БИН/ИИН)'))),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  ((word?.includes('Purpose of payment') ||
                    word?.includes('Курс')) &&
                  /^(\s*\d+\s*)$/.test(etc?.nextWord) || (etc?.prevWord?.includes('ИИК, БИН/ИИН)') && word?.includes('ИИК, БИН/ИИН)'))),
                1,
              );
              self.deleteFromTo(
                (word) => word?.startsWith('Айналым'),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => /[\d ]+,[\d]+/.test(value),
                (value) => /[\d ]+,[\d]+/.test(value),
                (value) => /[\d ]+,[\d]+/.test(value),
              ]);
            },
          },
        },
      },
    ],
  };
}
