import { SelectedProps } from '../../../../store/types';

export type ResourceLabelToId = {
  [key in
    | JournalItems
    | CategoryItems
    | SettingsName
    | FuturePaymentsName
    | ReportName]: string;
};

export type ResourceIdToLabel = {
  [key in string]: string;
};

export type Props = {
  role: {
    id: string;
    name: string;
  } | null;
  isOwner: boolean;

  onClose(): void;
  onUpdate(id: string, name: string): void;
};

export enum AccessType {
  read = 'read',
  edit = 'edit',
}

export enum LogAccessType {
  read = 'read',
  edit = 'edit',
  delete = 'delete',
}

export enum CategoryItems {
  'categories/income' = 'categories/income',
  'categories/consumption' = 'categories/consumption',
}

export type CategoryState = {
  'categories/income': {
    [LogAccessType.read]: SelectedProps;
    [LogAccessType.edit]: SelectedProps;
    [LogAccessType.delete]: SelectedProps;
  };
  'categories/consumption': {
    [LogAccessType.read]: SelectedProps;
    [LogAccessType.edit]: SelectedProps;
    [LogAccessType.delete]: SelectedProps;
  };
};

export enum FuturePaymentsName {
  'operations/income/loan' = 'operations/income/loan',
  'operations/income/loanRepayment' = 'operations/income/loanRepayment',
  'operations/income/investment' = 'operations/income/investment',
  'operations/consumption/loan' = 'operations/consumption/loan',
  'operations/consumption/loanRepayment' = 'operations/consumption/loanRepayment',
  'operations/consumption/tax' = 'operations/consumption/tax',
  'operations/consumption/salary' = 'operations/consumption/salary',
  // 'operations/consumption/other' = 'operations/consumption/other',
  'operations/consumption/dividend' = 'operations/consumption/dividend',
  'operations/consumption/supplier' = 'operations/consumption/supplier',
  'operations/income/sale' = 'operations/income/sale',
}

export enum SettingsName {
  accounts = 'accounts',
  projects = 'projects',
  tags = 'tags',
  'categories/income' = 'categories/income',
  'categories/consumption' = 'categories/consumption',
  clients = 'clients',
  investors = 'investors',
  borrowers = 'borrowers',
  creditors = 'creditors',
  staffMembers = 'staffMembers',
  suppliers = 'suppliers',
  owners = 'owners',
  counterparties = 'counterparties',
  taxOrganisations = 'taxOrganisations',
}

export type SettingsState = {
  [AccessType.read]: {
    [key in SettingsName]: boolean;
  };
  [AccessType.edit]: {
    [key in SettingsName]: boolean;
  };
};

export enum ReportName {
  'analytics/cash' = 'analytics/cash',
  'analytics/profitAndLoss' = 'analytics/profitAndLoss',
  'analytics/debit' = 'analytics/debit',
  'analytics/credit' = 'analytics/credit',
  'analytics/projects' = 'analytics/projects',
  'analytics/accounts/statement' = 'analytics/accounts/statement',
  'operations/history' = 'operations/history',
}

export type ReportsState = {
  [key in ReportName]: boolean;
};

export type FuturePaymentsState = {
  [key in FuturePaymentsName]: boolean;
};

export enum JournalItems {
  accounts = 'accounts',
  projects = 'projects',
  tags = 'tags',
  clients = 'clients',
  investors = 'investors',
  borrowers = 'borrowers',
  creditors = 'creditors',
  staffMembers = 'staffMembers',
  suppliers = 'suppliers',
  owners = 'owners',
  counterparties = 'counterparties',
  taxOrganisations = 'taxOrganisations',
}

export type JournalState = {
  [key in JournalItems | CategoryItems]: {
    [k in LogAccessType]: string[];
  };
};

export type WrappedPermissionProps = {
  readPermissionIds: string[];
  editPermissionIds: string[];
  deletePermissionIds: string[];
  resourceIdToLabel: ResourceIdToLabel;
};

export type UpdatedStateProps = {
  accountsVisibilityEnable: boolean;
  analyticEnable: boolean;
  futurePaymentsEnable: boolean;
  calendarEnable: boolean;
  logEnable: boolean;
  operationsEnable: boolean;
  settingsEnable: boolean;
  employeesEnable: boolean;
  journalStatePermissions: JournalState;
  categoryStatePermissions: CategoryState;
  logReadPermissions: JournalState;
  analyticReadPermissions: ReportsState;
  futurePaymentsReadPermissions: FuturePaymentsState;
  operationEditPermissions: JournalState;
  operationDeletePermissions: JournalState;
  employeesReadPermissions: SettingsState[AccessType.read];
  settingsReadPermissions: SettingsState[AccessType.read];
  settingsEditPermissions: SettingsState[AccessType.edit];
};
