import { OPERATION_TYPES } from '@finmap/core-constants';
import { parseSum } from '@finmap/core-utils';
import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class UkrGazBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['DATA_D', 6]),
            currency: this.getFirstValidCellByColumn(['CUR_TAG', 12]),
            type:
              this.getFirstValidCellByColumn(['DK', 7])?.toString() === '1'
                ? OPERATION_TYPES.INC
                : OPERATION_TYPES.CON,
            sum: parseSum(this.getFirstValidCellByColumn(['SUM_PD_E', 18])),
            comment: this.getFirstValidCellByColumn(['PURPOSE', 19]),
            counterparty: this.getFirstValidCellByColumn(['NAME_KOR', 11]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['DATA_D', 6]),
            currency: this.getFirstValidCellByColumn(['CUR_TAG', 12]),
            type:
              this.getFirstValidCellByColumn(['DK', 7])?.toString() === '1'
                ? OPERATION_TYPES.INC
                : OPERATION_TYPES.CON,
            sum: parseSum(this.getFirstValidCellByColumn(['SUM_PD_E', 18])),
            comment: this.getFirstValidCellByColumn(['PURPOSE', 19]),
            counterparty: this.getFirstValidCellByColumn(['NAME_KOR', 11]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['CUR_TAG', 12]),
            date: this.getFirstValidCellByColumn(['DATA_D', 6]),
            type:
              this.getFirstValidCellByColumn(['DK', 7])?.toString() === '1'
                ? OPERATION_TYPES.INC
                : OPERATION_TYPES.CON,
            sum:
              parseSum(this.getFirstValidCellByColumn(['SUM_PD_E', 18])) / 100,
            comment: this.getFirstValidCellByColumn(['PURPOSE', 19]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          encoding: 'win1251',
          preParserConfigs: { CSVDelimiter: ';' },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Номер документу\nДата',
              0,
            ])?.split('\n')[1],
            comment: this.getFirstValidCellByColumn(['ПРИЗНАЧЕННЯ', 5]),
            debit: this.getFirstValidCellByColumn(['СУМА ДЕБЕТ\nСписано', 3]),

            credit: this.getFirstValidCellByColumn([
              'СУМА КРЕДИТ\nЗараховано',
              4,
            ]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            maxInterlineSpacingPx: 10,
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            rotate: true,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Номер документу') &&
                  etc?.nextWord?.includes('КОРЕСПОНДЕНТ'),
                (word, etc) =>
                  word?.includes('Код банку') &&
                  etc?.prevWord?.includes('Назва банку'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('Номер документу') &&
                  etc?.nextWord?.includes('КОРЕСПОНДЕНТ'),
                1,
              );
              self.deleteFromTo(
                (word) => word?.startsWith('Підсумок оборотів'),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => /^([\s\d]+,\d+)$/.test(value), // 75 000,00
              ]);
            },
          },
        },
      },
    ],
  };
}
