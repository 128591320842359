import { parseSum } from '@finmap/core-utils';
import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

function chunkStringFromCharCode(array, chunkSize) {
  let result = '';
  for (let i = 0; i < array.length; i += chunkSize) {
      let chunk = array.slice(i, i + chunkSize);
      result += String.fromCharCode.apply(null, chunk);
  }
  return result;
}

export class PKOBankImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        async isCurCase(file: File) {
          const buffer = await file.arrayBuffer();
          const uint8arr = new Uint8Array(buffer)
          const data = chunkStringFromCharCode(uint8arr, 10000);
          const result = data.startsWith('"Data operacji",')

          return result
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Waluta', 4]),
            date: this.getFirstValidCellByColumn(['Data operacji', 0]),
            sum:
              parseSum(this.getFirstValidCellByColumn(['Kwota', 3])),
            comment:
              this.getFirstValidCellByColumn(['Opis transakcji', 6]) !== undefined
                ? this.getFirstValidCellByColumn(['Opis transakcji', 6])
                : this.getFirstValidCellByColumn([7]),
          });
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: { CSVDelimiter: ',' },
        }
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Waluta', 4]),
            date: this.getFirstValidCellByColumn(['Data księgowania', 0]),
            counterparty:
              this.getFirstValidCellByColumn(['Nadawca / Odbiorca', 2]),
            comment: this.getFirstValidCellByColumn(['Tytułem', 6]),
            sum:
              parseSum(this.getFirstValidCellByColumn(['Kwota operacji', 7])),
          });
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: { CSVDelimiter: ';' },
        }
      },
    ],
  };
}
