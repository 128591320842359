import { isNotEmpty } from 'class-validator';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class HalykBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) => value.str.includes('Филиал:')),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['проведения', 0]),
            credit: this.getFirstValidCellByColumn(['Приход', 5]),
            debit: this.getFirstValidCellByColumn(['Расход', 6]),
            counterparty: this.getFirstValidCellByColumn(['карточки', 8]),
            comment: this.getFirstValidCellByColumn(['Описание', 2]),
            currency: this.getFirstValidCellByColumn(['Валюта', 5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          isDESCOpOrder: true,
          preParserConfigs: {
            maxInterlineSpacingPx: 22,
            interlineSpacingAccuracy: 1,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => word === 'Дата' && etc?.nextWord === 'Дата',
                (word, etc) =>
                  word === 'операции' && etc?.prevWord === 'операции',
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word === 'операции' && etc?.prevWord === 'операции',
                1,
              );
              self.deleteFromTo(
                (word) => word?.startsWith('Местопечати'),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => /^([\s\d]+,\d+)$/.test(value), // '1 500,00'
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата', 0]),
            debit: this.getFirstValidCellByColumn(['Дебет', 2]),
            credit: this.getFirstValidCellByColumn(['Кредит', 3]),
            counterparty: this.getFirstValidCellByColumn(['Контрагент', 4]),
            comment: this.getFirstValidCellByColumn(['Детали платежа', 5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            interlineSpacingAccuracy: 8,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') &&
                  etc?.nextWord?.includes('Номер документа'),
                (word, etc) =>
                  word?.includes('Детали платежа') &&
                  etc?.prevWord?.includes('Контрагент'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('Детали платежа') &&
                  etc?.prevWord?.includes('Контрагент'),
              );
              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Кредит') &&
                  etc?.nextWord?.includes('Обороты:'),
                undefined,
              );
              self.defineOperation([
                (value) =>
                  /^([\s\d]+,\d+)$/.test(value) ||
                  /^([,\d]+\.\d+)$/.test(value), // '1 500,00' || '34,725.00'
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          const firstHeaderLine = this.findString(/^Дата валютирования/)?.raw;
          const lastBodyLine = this.findString(/^Исходящий остаток на:/)?.raw;

          this.setDocumentHeader(importDocument[firstHeaderLine]);
          this.setDocumentBody(
            importDocument.slice(firstHeaderLine + 1, lastBodyLine - 1),
          );
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата валютирования', 0]),
            debit: this.getFirstValidCellByColumn(['Дебет', 7]),
            credit: this.getFirstValidCellByColumn(['Кредит', 8]),
            counterparty: this.getFirstValidCellByColumn(['Контрагент', 4]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 9]),
          });
        },
        caseOptions: { defaultCurrency: 'KZT', isDESCOpOrder: true },
      },
    ],
  };
}
