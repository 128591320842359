import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class BankPekaoImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const [sum, currency] =
              this.getFirstValidCellByColumn(['Currency\nDocuments', 5])?.split(
                /\s/,
              ) || [];
            const datesArr =
              this.getFirstValidCellByColumn(['Currency date', 0])?.split(
                /\s/,
              ) || [];
            return {
              sum,
              currency,
              date: datesArr[1] || datesArr[0],
              comment: this.getFirstValidCellByColumn(['Operation title', 4]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: {
            verticalAlign: 'middle',
            pageSeparatorsLengthPx: 3,
            interlineSpacingAccuracy: 4,
            separatorsDistanceModifier: 1.5,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word?.includes('Owner name'),
                (word) => word?.includes('Documents'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('Documents'),
                1,
              );
              self.deleteFromTo(
                (word) => word?.startsWith('In trans-border settlement'),
                undefined,
                1,
              );
              self.deleteFromTo(
                (word) =>
                  word?.startsWith(
                    'https://www.pekaobiznes24.pl/webcorpo/do/operationHistory',
                  ),
                (_, etc) => etc.prevWord?.includes('Bank Pekao SA'),
              );
              self.defineOperation([
                (value) =>
                  /^(-?[\d,]+\.\d{2}|-?[\d,]+\.\d{2}\s[A-z]{3})$/.test(value?.trim()), // '-30.34 PLN' || '110,000.00\nUSD'
              ]);
            },
          },
        },
      },
    ],
  };
}
