export enum Routes {
  LOG = '/log',
  ON_BOARDING = '/onboarding',
  EMPLOYEE = '/employee',
  CALENDAR = '/calendar',
  INVOICING = '/invoicing',
  LOGIN = '/auth/login',
  EMAIL_SENT = '/auth/emailsent',
  CREATE_COMPANY = '/auth/create-company',
  REGISTER = '/auth/register',
  FORGOT = '/auth/forgot',
  RESTORE_PASSWORD = '/auth/restore_password',
  FAQ = '/faq',
  CREATE_PHONE = '/auth/create-phone',
  ACCESS_DENIED = '/access_denied',
  SUCCESS_PAYMENT = '/success-payment',
  AUTO_RULES = '/autorules',
}

export enum AnalyticRoutes {
  ANALYTIC = '/analytic',
  CASH_FLOW = '/analytic/cashflow',
  PROFIT_AND_LOSS = '/analytic/profitAndLoss',
  DEBIT = '/analytic/debit',
  CREDIT = '/analytic/credit',
  STATEMENT = '/analytic/statement',
  PROJECTS = '/analytic/projects',
  HISTORY = '/analytic/history',
}

export enum SettingsRoutes {
  SETTINGS = '/settings',
  AUTO_RULES = '/settings/auto-rules',
  PROFILE = '/settings/profile',
  COMPANIES = '/settings/companies',
  IMPLEMENT = '/settings/implement',
  SUBSCRIPTION = '/settings/subscription',
  CLIENTS = '/settings/clients',
  ACCOUNTS = '/settings/accounts',
  CATEGORIES = '/settings/categories',
  PROJECTS = '/settings/projects',
  TAGS = '/settings/tags',
  EMPLOYEES = '/settings/employees',
  CURRENCiES = '/settings/currencies',
  INVOICING_MY_DETAILS = '/settings/invoicing-my-details',
  INVOICING_CLIENTS_DETAILS = '/settings/invoicing-clients-details',
  GOODS = '/settings/goods',
  API = '/settings/api',
  AI = '/settings/ai',
  WEBHOOKS = '/settings/webhooks',
  CUSTOMIZATION = '/settings/customization',
  CUSTOMIZATION_LOG = '/settings/customization-log',
}
