import { isNotEmpty } from 'class-validator';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  BaseXLSXPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class AbankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) =>
              value.str.includes('Підсумкова виписка за період'),
            ),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата та\nчас операції',
              0,
            ])?.replace('\n', ' '),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 2]),
            sum: this.getFirstValidCellByColumn(['Сума', 3]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => word?.includes('Дата та'),
                (word, etc) =>
                  word?.includes('час операції') &&
                  etc?.prevWord?.includes('Сума'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) => etc?.nextWord.includes('час операції'),
                1,
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) => value.str.includes('Номер картки')),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата і час\nоперації',
              0,
            ])?.replace('\n', ' '),
            comment: this.getFirstValidCellByColumn(['Деталі операції', 2]),
            sum: this.getFirstValidCellByColumn([
              'Сума у валюті\nкарти (UAH)',
              4,
            ]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            maxInterlineSpacingPx: 10,
            verticalAlign: 'middle',
            pageSeparatorsLengthPx: 0.5,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  (word?.includes('Сума') && etc?.nextWord.includes('Сума')) ||
                  (word?.includes('Дата і час') && etc?.nextWord.includes('Сума у валюті')),
                (word, etc) =>
                  (word?.includes('(UAH)') && etc?.prevWord?.includes('(UAH)')) ||
                  (word?.includes('(UAH)') && etc?.prevWord?.includes('(USD)')),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) => etc?.nextWord.includes('Дата і час'),
                1,
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата і час\nоперації',
              0,
            ])?.replace('\n', ' '),
            comment: this.getFirstValidCellByColumn(['Деталі операції', 2]),
            sum: this.getFirstValidCellByColumn([
              'Сума у валюті\nкарти (UAH)',
              3,
            ]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            maxInterlineSpacingPx: 40,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Сума у') && etc?.nextWord.includes('Сума у'),
                (word, etc) =>
                  word?.includes('(UAH)') &&
                  moment(etc?.nextWord, 'DD.MM.YYYY', true).isValid(),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) => etc?.nextWord.includes('Дата і час'),
                1,
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        async isCurCase(file: File, preParser: BaseXLSXPreParser) {
          let rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          rawDocument = rawDocument
            .map((el) => (el.length ? el.filter(Boolean) : []))
            .filter((el) => Boolean(el.length));
          return (
            rawDocument?.length &&
            rawDocument[0]?.includes('Підсумкова виписка')
          );
        },
        proceedCase: (importDocument) => {
          const firstHeaderLine = this.findString(/Дата/)?.raw;
          const lastBodyLine = this.findString(/ПРОВОДОК/)?.raw;
          importDocument = importDocument
            .slice(firstHeaderLine + 1, lastBodyLine)
            .map((el) => el.filter(Boolean))
            .filter((el) => !isEmpty(el));

          const targetValues = [
            ['Дата', 'ПРИЗНАЧЕННЯ', 'КОРРЕСПОНДЕНТ', 'Дебет', 'Кредит'],
          ];
          for (let line = 0; line < importDocument.length; line += 4) {
            const date = importDocument[line][0];
            const debit = importDocument[line][
              importDocument[line].length - 2
            ].replace(/([\r\n-])/g, '');
            const credit = importDocument[line][
              importDocument[line].length - 1
            ]?.replace(/([\r\n-])/g, '');
            const comment = importDocument[line + 1][1];
            const counterparty = importDocument[line + 3][1];
            targetValues.push([date, comment, counterparty, debit, credit]);
          }
          this.setDocumentHeader(targetValues[0]);
          this.setDocumentBody(targetValues.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата', 0]),
            debit: this.getFirstValidCellByColumn(['Дебет', 3]),
            credit: this.getFirstValidCellByColumn(['Кредит', 4]),
            counterparty: this.getFirstValidCellByColumn(['КОРРЕСПОНДЕНТ', 2]),
            comment: this.getFirstValidCellByColumn(['ПРИЗНАЧЕННЯ', 1]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH', isDESCOpOrder: true },
      },
      //abank-import-parser-case6
      {
        async isCurCase(file: File, preParser: BaseXLSXPreParser) {
          let rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          rawDocument = rawDocument
            .map((el) => (el.length ? el.filter(Boolean) : []))
            .filter((el) => Boolean(el.length));
          return (
            rawDocument?.length &&
            rawDocument[0][0] &&
            (rawDocument[0][0] as string)?.includes('Клієнт: ')
          );
        },
        proceedCase: (importDocument) => {
          const rawData = importDocument.filter(arr => arr.filter(Boolean).length !== 0);
          const data = [];
          let header = [];
          for(const arr of rawData) {
            if(
              !header.length &&
              arr[0] === 'Дата i час операції' &&
              arr[1] === 'Номер картки' &&
              arr[2] === 'Деталі операції' &&
              arr[3] === 'MCC' &&
              arr[4] === 'Сума в валюті картки (USD)' &&
              arr[5] === 'Сума в валюті операції' &&
              arr[6] === 'Валюта' &&
              arr[7] === 'Курс' &&
              arr[8] === 'Сума комісій (USD)' &&
              arr[9] === 'Сума кешбеку (UAH)' &&
              arr[10] === 'Залишок після операції'
            ) {
              header = arr;
            }
            else if(header.length) {
              data.push(arr);
            }
          }

          this.setDocumentHeader(header);
          this.setDocumentBody(data);
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn(['Дата i час операції', 0]),
            sum: this.getFirstValidCellByColumn(['Сума в валюті картки (USD)', 4]),
            comment: this.getFirstValidCellByColumn(['Деталі операції', 2]),
          });
        },
        caseOptions: { defaultCurrency: 'USD', isDESCOpOrder: true },
      },
      {
        proceedCase: (importDocument) => {
          const firstHeaderLine = this.findString(/Дата/)?.raw;

          this.setDocumentHeader(importDocument[firstHeaderLine]);
          this.setDocumentBody(
            importDocument
              .slice(firstHeaderLine + 1)
              .filter(([n]) => !isNaN(parseInt(n, 10))),
          );
          return (): ImportResultItemMask => ({
            dateAndTime: [
              this.getFirstValidCellByColumn(['Дата', 0]).split(' ')[0],
              this.getFirstValidCellByColumn(['Час операції', 1]),
            ]
              .filter(Boolean)
              .join(' '),
            counterparty: this.getFirstValidCellByColumn(['Контрагент', 3]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 7]),
            sum: this.getFirstValidCellByColumn(['Сума, грн', 8]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH', isDESCOpOrder: true },
      },
    ],
  };
}
