import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class PaypalImportParser extends BaseImportParserV3 {
  private DATE_FORMAT: string;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        async isCurCase(file: File) {
          const buffer = String.fromCharCode.apply(
            null,
            new Uint8Array(await file.arrayBuffer()),
          );
          return /[\w]+;[\w]+/.test(buffer);
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: moment(
              this.getFirstValidCellByColumn(['Date', 0]),
              'MM/DD/YYYY',
            ).toISOString(),
            time: moment(
              this.getFirstValidCellByColumn(['Time', 1]),
              'HH:mm:ss',
            ).toISOString(),
            sum: this.getFirstValidCellByColumn(['Net', 9]),
            comment:
              [
                this.getFirstValidCellByColumn(['Name', 3]),
                this.getFirstValidCellByColumn(['Transaction ID', 12]),
                this.getFirstValidCellByColumn(['Item Title', 13]),
              ]
                .filter(Boolean)
                .join('; ') || undefined,
            currency: this.getFirstValidCellByColumn(['Currency', 6]),
          });
        },
        caseOptions: {
          defaultCurrency: 'USD',
          preParserConfigs: { simpleParse: true, CSVDelimiter: ';' },
        },
      },
      {
        proceedCase: (importDocument) => {
          if (importDocument[0].some((s) => s.includes('Date')))
            this.DATE_FORMAT = 'DD/MM/YYYY';
          else this.DATE_FORMAT = 'MM/DD/YYYY';
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: moment(
              this.getFirstValidCellByColumn(['Date', 'Дата', 0]),
              this.DATE_FORMAT,
            ).toISOString(),
            time: moment(
              this.getFirstValidCellByColumn(['Time', 'Время', 1]),
              'HH:mm:ss',
            ).toISOString(),
            sum: this.getFirstValidCellByColumn(['Net', 'Чистая сумма', 7]),
            comment:
              [
                this.getFirstValidCellByColumn(['Description', 'Описание', 3]),
                this.getFirstValidCellByColumn([
                  'Transaction ID',
                  'Код операции',
                  9,
                ]),
                this.getFirstValidCellByColumn(['Name', 'Название', 11]),
              ]
                .filter(Boolean)
                .join('; ') || undefined,
            currency: this.getFirstValidCellByColumn(['Currency', 'Валюта', 4]),
          });
        },
        caseOptions: {
          defaultCurrency: 'USD',
          preParserConfigs: { simpleParse: true },
        },
      },
    ],
  };
}
