import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '../../components/Dialog/Dialog';
import useImports from '../../hooks/useImports';
import useOnboardingV2 from '../../hooks/useOnboardingV2';
import useOnScreen from '../../hooks/useOnScreen';
import TooltipWrapper from '../../scenes/InfoBlock/OnBoardingV2/Components/TooltipWrapper';
import { useTooltipStyles } from '../../scenes/InfoBlock/OnBoardingV2/Components/TooltipWrapper/styles';
import VideoLinkComponent from '../../scenes/InfoBlock/OnBoardingV2/Components/VideoLinkComponent';
import { ImportFrom } from '../../store/import/selectors';
import { ImportType } from '../../store/import/types';
import ImportFileDialog from './importFile';
import RenderWriteUsButton from './RenderWriteUsButton';
import { useStyles } from './styles';
import { Props } from './types';

function ImportDialog(props: Props) {
  const { onClose } = props;
  const [currentImportType, setCurrentImportType] = useState<ImportType | null>(
    null,
  );

  const { t } = useTranslation();
  const importsList = useImports();
  const {
    onCreateDemoData,
    onDeleteIntegrationStep,
    isCreateImportActiveStep,
    onboardingV2VideoInProgress,
  } = useOnboardingV2();

  const servicesRef = useRef<HTMLDivElement | null>(null);
  const countriesRef = useRef<HTMLDivElement | null>(null);

  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();

  const { isIntersecting: servicesOnVisible } = useOnScreen(servicesRef);
  const { isIntersecting: countriesOnVisible } = useOnScreen(countriesRef);

  const handleClose = useCallback(() => {
    if (isCreateImportActiveStep) {
      onDeleteIntegrationStep();
    }

    onClose();
  }, [isCreateImportActiveStep, onClose, onDeleteIntegrationStep]);

  const handleCloseImportFileDialog = useCallback(() => {
    setCurrentImportType(null);
  }, []);

  const handleSelect = useCallback((event: any) => {
    const { id } = event.currentTarget;

    setCurrentImportType(id);
  }, []);

  const services = useMemo(
    () => importsList.filter((el) => el.type === ImportFrom.services),
    [importsList],
  );

  const ukraineImports = useMemo(
    () => importsList.filter((el) => el.type === ImportFrom.ukraineImports),
    [importsList],
  );

  const polandImports = useMemo(
    () => importsList.filter((el) => el.type === ImportFrom.polandImports),
    [importsList],
  );

  const uzbekistanImports = useMemo(
    () => importsList.filter((el) => el.type === ImportFrom.uzbekistanImports),
    [importsList],
  );

  const kazakhstanImports = useMemo(
    () => importsList.filter((el) => el.type === ImportFrom.kazakhstanImports),
    [importsList],
  );

  const otherCountriesImports = useMemo(
    () =>
      importsList.filter((el) => el.type === ImportFrom.otherCountriesImports),
    [importsList],
  );

  const handleCreateDemoData = useCallback(
    (event: any) => {
      event.stopPropagation();

      onCreateDemoData();
    },
    [onCreateDemoData],
  );

  const RenderItem = useCallback(
    ({ item }: { item: any }) => (
      <div className={classes.row} key={item.id}>
        <div
          id={item.id}
          onClick={handleSelect}
          className={classes.block}
          data-cy={item.dataAttribute}
        >
          {item.icon && (
            <img src={item.icon} alt={item.id} className={classes.icon} />
          )}
          <Typography className={classes.buttonText}>{item.title}</Typography>
        </div>
      </div>
    ),
    [classes, handleSelect],
  );

  return (
    <>
      <Dialog isOpened onClose={handleClose} title={t('import.titleFrom')}>
        <div>
          <TooltipWrapper
            open={
              isCreateImportActiveStep &&
              servicesOnVisible &&
              !currentImportType &&
              !onboardingV2VideoInProgress
            }
            placement="left"
            title={
              <div>
                <Typography className={tooltipClasses.onboardingPopupTitle}>
                  {t('onboardingV2.tooltip.neededImport')}
                </Typography>
                <VideoLinkComponent />
              </div>
            }
          >
            <div ref={servicesRef}>
              <Typography className={classes.subTitle}>
                {t('import.services')}
              </Typography>
            </div>
          </TooltipWrapper>
          <div className={classes.blockContainer}>
            {services.map((el) => (
              <RenderItem item={el} key={el.id} />
            ))}
          </div>

          <TooltipWrapper
            open={
              isCreateImportActiveStep &&
              countriesOnVisible &&
              !currentImportType &&
              !onboardingV2VideoInProgress
            }
            placement="left"
            title={
              <Typography className={tooltipClasses.onboardingPopupText}>
                {t('onboardingV2.tooltip.loadDemoData', {
                  postProcess: 'reactPostprocessor',

                  loadDemoData: (
                    <span
                      className={cn(
                        tooltipClasses.onboardingPopupTitle,
                        tooltipClasses.underline,
                        tooltipClasses.clickable,
                      )}
                      onClick={handleCreateDemoData}
                    >
                      {t('onboardingV2.tooltip.handleLoadDemoData')}
                    </span>
                  ),
                })}
              </Typography>
            }
          >
            <div ref={countriesRef}>
              <Typography className={classes.subTitle}>
                <span role="img" aria-label="dog">
                  🇺🇦
                </span>
                {` ${t('countries.Ukraine')}`}
              </Typography>
            </div>
          </TooltipWrapper>
          <div className={classes.blockContainer}>
            {ukraineImports.map((el) => (
              <RenderItem item={el} key={el.id} />
            ))}
          </div>

          <Typography className={classes.subTitle}>
            {`🇵🇱 ${t('countries.Poland')}`}
          </Typography>
          <div className={classes.blockContainer}>
            {polandImports.map((el) => (
              <RenderItem item={el} key={el.id} />
            ))}
          </div>

          <Typography className={classes.subTitle}>
            {`🇰🇿 ${t('countries.Kazakhstan')}`}
          </Typography>
          <div className={classes.blockContainer}>
            {kazakhstanImports.map((el) => (
              <RenderItem item={el} key={el.id} />
            ))}
          </div>

          <Typography className={classes.subTitle}>
            {`🇺🇿 ${t('countries.Uzbekistan')}`}
          </Typography>
          <div className={classes.blockContainer}>
            {uzbekistanImports.map((el) => (
              <RenderItem item={el} key={el.id} />
            ))}
          </div>

          <Typography className={classes.subTitle}>
            {`🌍 ${t('countries.otherCountries')}`}
          </Typography>
          <div className={classes.blockContainer}>
            {otherCountriesImports.map((el) => (
              <RenderItem item={el} key={el.id} />
            ))}
          </div>

          <Typography className={classes.text}>
            {t('import.anotherResource')}
          </Typography>
          <RenderWriteUsButton onClose={onClose} />
        </div>
      </Dialog>
      {currentImportType && (
        <ImportFileDialog
          currentImportType={currentImportType}
          onClose={handleCloseImportFileDialog}
          onCloseRootDialog={onClose}
        />
      )}
    </>
  );
}

export default React.memo(ImportDialog);
