import moment from 'moment';

import { OPERATION_TYPES } from '@finmap/core-constants';
import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BaseXLSXPreParser,
  Config,
  ImportResultItemMask,
  TIME_FORMAT,
} from '@finmap/import-parsers/base-import-parser-v3';

export class AlfaBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        async isCurCase(file: File, preParser: BaseXLSXPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return rawDocument?.length && rawDocument[0].includes('Наш рахунок');
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта', 12]),
            type:
              this.getFirstValidCellByColumn(['Операція', 2]) === 'Кредит'
                ? OPERATION_TYPES.INC
                : OPERATION_TYPES.CON,
            date: this.getFirstValidCellByColumn(['Дата проведення', 9]),
            time: moment(
              this.getFirstValidCellByColumn(['Час проведення', 13]),
              TIME_FORMAT,
            ).toISOString(),
            counterparty: this.getFirstValidCellByColumn([
              'Найменування контрагента',
              6,
            ]),
            sum: this.getFirstValidCellByColumn(['Сума', 11]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 8]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
      {
        proceedCase: (importDocument) => {
          const firstHeaderLineRaw = this.findString(/^Дата операции$/)?.raw;
          this.setDocumentHeader(importDocument[firstHeaderLineRaw]);
          this.setDocumentBody(importDocument.slice(firstHeaderLineRaw + 1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта', 5]),
            date: this.getFirstValidCellByColumn(['Дата операции', 0]),
            counterparty: this.getFirstValidCellByColumn(['Корреспондент', 10]),
            debit: this.getFirstValidCellByColumn(['Дебет', 13]),
            credit: this.getFirstValidCellByColumn(['Кредит', 14]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 15]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта', 5]),
            date: this.getFirstValidCellByColumn(['Дата операции', 0]),
            counterparty: this.getFirstValidCellByColumn(['Корреспондент', 10]),
            debit: this.getFirstValidCellByColumn(['Дебет', 13]),
            credit: this.getFirstValidCellByColumn(['Кредит', 14]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 15]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          encoding: 'win1251',
          preParserConfigs: { CSVDelimiter: ';' },
        },
      },
    ],
  };
}
