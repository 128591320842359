import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/uk';
import 'moment/locale/pl';
import 'moment/locale/cs';
import 'moment/locale/tr';
import 'moment/locale/es';
import 'moment/locale/kk';
import 'moment/locale/uz-latn';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { IntercomProps, IntercomProvider } from 'react-use-intercom';

import IntercomButton from './components/IntercomButton';
import { IMPORT_TOAST_ID, INTERCOM_APP_ID } from './constants';
import { Routes } from './constants/routes';
import ErrorBoundary from './foundation/ErrorBoundary';
import DeepLinks from './GlobalHelpers/DeepLinks';
import GetServerTime from './GlobalHelpers/GetServerTime';
import Layout from './layouts/default';
import RestrictedAccessDialog from './Portals/RestrictedAccessDialog';
import FacebookFixel from './Providers/FacebookFixelProvider';
import UnleashProvider from './Providers/UnleashProvider';
import Storages, { StorageKey } from './services/Storages';
import { User } from './store/auth/types';
import store from './store/store';
import ThemeProvider from './utils/ThemeProvider';

const user = Storages.get(StorageKey.user) as User;
const autoBootProps: IntercomProps = {
  customLauncherSelector: '#intercom-chat',
  hideDefaultLauncher: true,
  alignment: 'right',
  email: user?.email,
  userId: user?._id,
  horizontalPadding: 20,
  verticalPadding: 20,
  languageOverride:
    Storages.get(StorageKey.language) === 'uk'
      ? 'ru'
      : Storages.get(StorageKey.language),
};

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Router>
          <ThemeProvider>
            <UnleashProvider>
              <FacebookFixel>
                <GetServerTime />
                <IntercomProvider
                  appId={INTERCOM_APP_ID}
                  autoBoot
                  autoBootProps={autoBootProps}
                >
                  <Layout />
                  <DeepLinks />
                  <IntercomButton />
                </IntercomProvider>
                <ToastContainer />
                <RestrictedAccessDialog />
              </FacebookFixel>
            </UnleashProvider>
          </ThemeProvider>
        </Router>
      </Provider>
    </ErrorBoundary>
  );
}

export default React.memo(App);

if (window.Cypress) {
  window.store = store;
  window.__chr__store__ = store;

  window.IMPORT_TOAST_ID = IMPORT_TOAST_ID;
  window.TAB_VALUES = Routes;
}
