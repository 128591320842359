import i18next from 'i18next';
import moment from 'moment';
import { createSelector } from 'reselect';

import { EMPTY, PROJECTS_AMOUNT_CHARTS } from '../../constants';
import { ReportTypeFilterBy } from '../../scenes/InfoBlock/Analytic/CashFlowAndPL/types';
import { getColorHex } from '../../scenes/InfoBlock/Analytic/CashFlowAndPL/utils';
import { DebitCreditByDate } from '../../scenes/InfoBlock/Analytic/Components/DebitCreditPeriodFilter/types';
import { ChartType } from '../../scenes/InfoBlock/Analytic/Projects/Charts/types';
import { getLanguage } from '../../selectors/main';
import { black, persianGreen, vividTangerine } from '../../theme/colors';
import { ucFirst } from '../../utils/stringUtils';
import { selectCustomFilters } from '../filters/selectors';
import { FiltersState } from '../filters/types';
import { Status } from '../journal/types';
import { OperationType } from '../operations/types';
import { AppState } from '../reducers';
import { StackedItemData } from './types';

function getxAxisDataFromString(
  items: number[],
  lng: string,
  months: string[],
  format = 'MMM YY',
) {
  const xAxisData: string[] = [];

  items.forEach((_el, index) => {
    const [year, month] = months[index].split('-');
    const date = moment()
      .locale(lng)
      .set('year', Number(year))
      .set('month', Number(month) - 1);

    xAxisData.push(ucFirst(date.format(format).replace('.', '')));
  });

  return xAxisData;
}

function getxAxisDataFromNumber(
  value: number | string,
  lng: string,
  format = 'MMM YY',
) {
  const date = moment(value).locale(lng);

  return ucFirst(date.format(format).replace('.', ''));
}

export const getAnalyticLoading = (state: AppState) => state.analytic.loading;

export const getPieChartDataAmount = (state: AppState) =>
  state.analytic.pieChartDataAmount;

export const getCircleCharsLoading = (state: AppState) =>
  state.analytic.loadingCircles;

export const getStackedCharsLoading = (state: AppState) =>
  state.analytic.loadingStacked;

export const getIncomeStackedCharsLoading = (state: AppState) =>
  state.analytic.loadingIncomeStacked;

export const getConsumptionStackedCharsLoading = (state: AppState) =>
  state.analytic.loadingConsumptionStacked;

export const getTableDataLoading = (state: AppState) =>
  state.analytic.loadingTable;

export const getHistory = (state: AppState) => state.analytic.actionsHistory;

export const getAccountStatement = (state: AppState) =>
  state.analytic.accountStatement;

export const getDebitData = (state: AppState) => state.analytic.debit;

export const getCreditData = (state: AppState) => state.analytic.credit;

export const getChartsData = (state: AppState) =>
  state.analytic.cashFlowAndPLData;

export const getLoadingErrors = (state: AppState) =>
  state.analytic.loadingErrors;

export const getProjectsData = (state: AppState) => state.analytic.projects;

export const selectPeriodChart = createSelector(
  getChartsData,
  (data) => data.periodChart,
);

export const selectPeriodSeriesMonths = createSelector(
  selectPeriodChart,
  (chartData) => chartData.months,
);

export const monthsToLocalString = createSelector(
  getLanguage,
  selectPeriodSeriesMonths,
  (language, months, format = 'MMM YY') =>
    months.map((el) => {
      const [year, month] = el.split('-');
      const date = moment()
        .locale(language)
        .set('year', Number(year))
        .set('month', Number(month) - 1);

      return ucFirst(date.format(format).replace('.', ''));
    }),
);

export const selectDataDynamicProjects = () =>
  createSelector(
    selectStackedData,
    selectPeriodSeriesMonths,
    monthsToLocalString,
    (stackedData, months, monthsToLocale) => {
      const { projects } = stackedData;
      const { income, consumption } = projects;

      const incomeSeries: any[] = [];
      const consumptionSeries: any[] = [];

      income.forEach((el) => {
        const data: number[] = [];

        const { approvedData } = el;

        months.forEach((month) => {
          const index = approvedData.findIndex((appData) => {
            const dateToString = moment.utc(appData[0]).format('YYYY-MM');

            return dateToString === month;
          });

          if (index !== -1) {
            data.push(approvedData[index][1]);
          } else {
            data.push(0);
          }
        });

        incomeSeries.push({
          name: el.name,
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series',
          },
          data,
        });
      });

      consumption.forEach((el) => {
        const data: number[] = [];

        const { approvedData } = el;

        months.forEach((month) => {
          const index = approvedData.findIndex((appData) => {
            const dateToString = moment.utc(appData[0]).format('YYYY-MM');

            return dateToString === month;
          });

          if (index !== -1) {
            data.push(approvedData[index][1]);
          } else {
            data.push(0);
          }
        });

        consumptionSeries.push({
          name: el.name,
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series',
          },
          data,
        });
      });

      return {
        months: monthsToLocale,
        series: {
          [OperationType.income]: incomeSeries,
          [OperationType.consumption]: consumptionSeries,
        },
      };
    },
  );

export const selectTopProjectsChartData = (type: ChartType) =>
  createSelector(getProjectsData, (projects) => {
    const xAxis: string[] = [];
    const data: number[] = [];

    [...projects]
      .sort((a, b) => {
        if (type === 'topIncome') {
          return a.income < b.income ? 1 : -1;
        }

        return a.margin < b.margin ? 1 : -1;
      })
      .splice(0, PROJECTS_AMOUNT_CHARTS)
      .forEach((el) => {
        xAxis.push(el.label);
        data.push(type === 'topIncome' ? el.income : el.margin);
      });

    return { xAxis, data };
  });

export const selectProjectsDataWithTranslateEmptyLabel = createSelector(
  getProjectsData,
  getLanguage,
  (projects, language) => {
    const i18 = i18next.getFixedT(language);

    return projects.map((project) => ({
      ...project,
      label: project.id === EMPTY ? i18('projects.without') : project.label,
    }));
  },
);

export const selectHistoryPopupData = createSelector(
  getHistory,
  (history) => history.popupData,
);

export const selectHistoryJournal = createSelector(
  getHistory,
  (history) => history.historyJournal,
);

export const selectIsEmptyHistoryJournal = createSelector(
  getHistory,
  (history) => history.isEmptyJournal,
);

export const selectLoadingHistory = createSelector(
  getHistory,
  (history) => history.loading,
);

export const selectPeriodFilter = createSelector(
  getHistory,
  (history) => history.filters,
);

export const selectHistoryCustomFilters = createSelector(
  getHistory,
  (history) => history.customFilters,
);

export const isCustomFiltersActive = createSelector(getHistory, (history) => {
  const { customFilters } = history;
  const { operations, types, users, accounts } = customFilters;

  return (
    Boolean(operations) || Boolean(types) || Boolean(users) || Boolean(accounts)
  );
});

export const selectPieData = createSelector(
  getChartsData,
  (chartData) => chartData.circlesCharts,
);

export const selectIncomePieSeries = createSelector(
  selectPieData,
  getLanguage,
  (chartsData, language) => {
    const i18 = i18next.getFixedT(language);
    const { counterparties, ...rest } = chartsData;

    const itemsApprovedIncomeTranslated =
      counterparties.income.itemsApproved?.map((el) => ({
        ...el,
        name: i18(`analytic.counterparties.income.${el.name}`),
      }));

    const itemsNotApprovedIncomeTranslated =
      counterparties.income.itemsNotApproved?.map((el) => ({
        ...el,
        name: i18(`analytic.counterparties.income.${el.name}`),
      }));

    const { categories, projects } = rest;

    const incomeItemsApproved = categories.income.itemsApproved?.map((el) => {
      const { name } = el;

      if (name === 'incomeDefault') {
        return {
          ...el,
          name: i18('category.withoutIncome'),
        };
      }

      if (name === 'incomeGeneric') {
        return {
          ...el,
          name: i18('category.incomeGeneric'),
        };
      }

      return el;
    });
    const incomeItemsNotApproved = categories.income.itemsNotApproved?.map(
      (el) => {
        const { name } = el;

        if (name === 'incomeDefault') {
          return {
            ...el,
            name: i18('category.withoutIncome'),
          };
        }

        return el;
      },
    );

    return {
      categories: {
        itemsApproved: incomeItemsApproved ?? [],
        itemsNotApproved: incomeItemsNotApproved ?? [],
      },
      projects: {
        itemsApproved: projects.income?.itemsApproved ?? [],
        itemsNotApproved: projects.income?.itemsNotApproved ?? [],
      },
      counterparties: {
        ...counterparties,
        itemsApproved: itemsApprovedIncomeTranslated ?? [],
        itemsNotApproved: itemsNotApprovedIncomeTranslated ?? [],
      },
    };
  },
);

export const selectConsumptionPieSeries = createSelector(
  selectPieData,
  getLanguage,
  (chartsData, language) => {
    const i18 = i18next.getFixedT(language);
    const { counterparties, ...rest } = chartsData;

    const itemsApprovedConsumptionTranslated =
      counterparties.consumption.itemsApproved?.map((el) => ({
        ...el,
        name: i18(`analytic.counterparties.consumption.${el.name}`),
      }));

    const itemsNotApprovedConsumptionTranslated =
      counterparties.consumption.itemsNotApproved?.map((el) => ({
        ...el,
        name: i18(`analytic.counterparties.consumption.${el.name}`),
      }));

    const { categories, projects } = rest;

    const consumptionItemsApproved = categories.consumption.itemsApproved?.map(
      (el) => {
        const { name } = el;

        if (name === 'consumptionDefault') {
          return {
            ...el,
            name: i18('category.withoutConsumption'),
          };
        }

        if (name === 'consumptionGeneric') {
          return {
            ...el,
            name: i18('category.consumptionGeneric'),
          };
        }

        return el;
      },
    );
    const consumptionItemsNotApproved =
      categories.consumption.itemsNotApproved?.map((el) => {
        const { name } = el;

        if (name === 'consumptionDefault') {
          return {
            ...el,
            name: i18('category.withoutConsumption'),
          };
        }

        if (name === 'consumptionGeneric') {
          return {
            ...el,
            name: i18('category.consumptionGeneric'),
          };
        }

        return el;
      });

    return {
      categories: {
        itemsApproved: consumptionItemsApproved ?? [],
        itemsNotApproved: consumptionItemsNotApproved ?? [],
      },
      projects: {
        itemsApproved: projects.consumption?.itemsApproved ?? [],
        itemsNotApproved: projects.consumption?.itemsNotApproved ?? [],
      },
      counterparties: {
        ...counterparties,
        itemsApproved: itemsApprovedConsumptionTranslated ?? [],
        itemsNotApproved: itemsNotApprovedConsumptionTranslated ?? [],
      },
    };
  },
);

export const selectCashFlowPeriodESeries = (
  report: keyof FiltersState,
  onlyApporoved: boolean,
) =>
  createSelector(selectPeriodChart, getLanguage, (periodChart, language) => {
    const i18 = i18next.getFixedT(language);

    const { months, ...data } = periodChart;

    const legend: { name: string; itemStyle?: any; lineStyle?: any }[] = [];

    const labels = {
      income:
        report === 'cashFlow'
          ? i18('accounts.income.title')
          : i18('table.income'),
      incomeFuture:
        report === 'cashFlow'
          ? i18('accounts.income.futureTitle')
          : i18('table.futureIncome'),
      consumption:
        report === 'cashFlow'
          ? i18('accounts.consumption.title')
          : i18('table.consumption'),
      consumptionFuture:
        report === 'cashFlow'
          ? i18('accounts.consumption.futureTitle')
          : i18('table.futureConsumption'),
      balance:
        report === 'cashFlow'
          ? i18('analytic.cashFlow.saldo')
          : i18('analytic.pl.profit'),
      balanceFuture:
        report === 'cashFlow'
          ? i18('analytic.cashFlow.saldoFuture')
          : i18('analytic.pl.profitFuture'),
    };

    if (data.notApprovedIncomes.some((el) => Math.abs(el) > 0)) {
      legend.push({ name: labels.incomeFuture });
    }

    if (data.approvedIncomes.some((el) => Math.abs(el) > 0)) {
      legend.push({ name: labels.income });
    }

    if (data.approvedConsumptions.some((el) => Math.abs(el) > 0)) {
      legend.push({ name: labels.consumption });
    }

    if (data.notApprovedConsumptions.some((el) => Math.abs(el) > 0)) {
      legend.push({ name: labels.consumptionFuture });
    }

    if (data.approvedDifferences.some((el) => Math.abs(el) > 0)) {
      legend.push({
        name: labels.balance,
        lineStyle: {
          opacity: 0,
        },
        itemStyle: {
          color: vividTangerine,
          borderRadius: 10,
          decal: {
            symbol: 'circle',
            symbolSize: 4,
            color: vividTangerine,
          },
        },
      });
    }

    if (data.differences.some((el) => Math.abs(el) > 0)) {
      legend.push({
        name: labels.balanceFuture,
        itemStyle: {
          color: vividTangerine,
          borderType: 'dashed',
          borderRadius: 10,
        },
        lineStyle: {
          opacity: 0,
        },
      });
    }

    let lng = language === 'cz' ? 'cs' : language;

    if (language === 'uz') {
      lng = 'uz-latn';
    }

    const series = [
      {
        type: 'bar',
        stack: 'one',
        name: labels.income,
        data: data.approvedIncomes,
        color: persianGreen,
        label: {
          type: 'income',
        },
      },
      {
        type: 'bar',
        stack: 'one',
        data: data.notApprovedIncomes,
        name: labels.incomeFuture,
        color: persianGreen,
        itemStyle: {
          decal: {
            symbol: 'rect',
            backgroundColor: 'white',
            color: persianGreen,
            rotation: 120,
            dashArrayX: [5, 0],
          },
        },
      },
      {
        type: 'bar',
        stack: 'second',
        data: data.approvedConsumptions,
        name: labels.consumption,
        color: black,
      },
      {
        type: 'bar',
        stack: 'second',
        data: data.notApprovedConsumptions,
        name: labels.consumptionFuture,
        color: black,
        itemStyle: {
          decal: {
            symbol: 'rect',
            backgroundColor: 'white',
            color: black,
            rotation: 120,
            dashArrayX: [5, 0],
          },
        },
      },
      {
        type: 'line',
        name: labels.balance,
        color: vividTangerine,
        data: data.approvedDifferences,
        smooth: true,
      },
    ];

    if (!onlyApporoved) {
      series.push({
        type: 'line',
        name: labels.balanceFuture,
        color: vividTangerine,
        // @ts-ignore
        lineStyle: {
          color: vividTangerine,
          width: 2,
          type: 'dashed',
        },
        opacity: 0.5,
        data: data.differences,
        smooth: true,
      });
    }
    const xAxisData = getxAxisDataFromString(data.approvedIncomes, lng, months);

    return { xAxisData, data: series, legend };
  });

export const selectDebitAmount = createSelector(getDebitData, (items) => {
  const { investorsDebit, clientsDebit, borrowersDebit } = items;

  let amount =
    investorsDebit?.reduce((sum, item) => sum + item.companyCurrencySum, 0) ||
    0;

  if (clientsDebit?.length) {
    amount = clientsDebit.reduce(
      (sum, item) => sum + item.companyCurrencySum,
      amount,
    );
  }

  if (borrowersDebit?.length) {
    amount = borrowersDebit.reduce(
      (sum, item) => sum + item.companyCurrencySum,
      amount,
    );
  }

  return amount;
});

export const selectCreditAmount = createSelector(getCreditData, (items) => {
  const {
    taxOrganisationsCredit,
    ownersCredit,
    creditorsCredit,
    suppliersCredit,
    staffMembersCredit,
    counterpartiesCredit,
  } = items;

  let amount =
    ownersCredit?.reduce((sum, item) => sum + item.companyCurrencySum, 0) || 0;

  if (creditorsCredit?.length) {
    amount = creditorsCredit.reduce(
      (sum, item) => sum + item.companyCurrencySum,
      amount,
    );
  }

  if (taxOrganisationsCredit?.length) {
    amount = taxOrganisationsCredit.reduce(
      (sum, item) => sum + item.companyCurrencySum,
      amount,
    );
  }

  if (suppliersCredit?.length) {
    amount = suppliersCredit.reduce(
      (sum, item) => sum + item.companyCurrencySum,
      amount,
    );
  }

  if (staffMembersCredit?.length) {
    amount = staffMembersCredit.reduce(
      (sum, item) => sum + item.companyCurrencySum,
      amount,
    );
  }

  if (counterpartiesCredit?.length) {
    amount = counterpartiesCredit.reduce(
      (sum, item) => sum + item.companyCurrencySum,
      amount,
    );
  }

  return amount;
});

export const selectStatusesList = createSelector(getLanguage, (language) => {
  const i18 = i18next.getFixedT(language);

  return [
    {
      id: Status.future,
      name: i18('analytic.status.future'),
    },
    {
      id: Status.payed,
      name: i18('analytic.status.payed'),
    },
  ];
});

export const selectFilterBy = createSelector(getLanguage, (language) => {
  const i18 = i18next.getFixedT(language);

  return [
    {
      id: ReportTypeFilterBy.categories,
      name: i18('analyticFilters.filterBy.category'),
    },
    {
      id: ReportTypeFilterBy.counterparties,
      name: i18('analyticFilters.filterBy.counterparties'),
    },
    {
      id: ReportTypeFilterBy.projects,
      name: i18('analyticFilters.filterBy.projects'),
    },
  ];
});

export const selectTypeFilter = createSelector(
  getChartsData,
  selectFilterBy,
  (cashFlowAndPLData, filterBy) => cashFlowAndPLData.typeFilter ?? filterBy[0],
);

export const selectDebitCreditFilterByDate = createSelector(
  getLanguage,
  (language) => {
    const i18 = i18next.getFixedT(language);

    return [
      {
        id: DebitCreditByDate.NOWADAYS,
        name: i18('analyticFilters.debitCreditByDate.nowadays'),
      },
      {
        id: DebitCreditByDate.YESTERDAY,
        name: i18('analyticFilters.debitCreditByDate.yesterday'),
      },
      {
        id: DebitCreditByDate.END_LAST_MONTH,
        name: i18('analyticFilters.debitCreditByDate.endLastMonth'),
      },
      {
        id: DebitCreditByDate.CHOOSE_DATE,
        name: i18('analyticFilters.debitCreditByDate.chooseDate'),
      },
    ];
  },
);

export const selectTableData = createSelector(getChartsData, (data) => {
  const {
    tableData,
    periodChart: { months },
  } = data;

  return tableData.filter((el) => months.includes(el.month));
});

export const selectStackedData = createSelector(
  getChartsData,
  getLanguage,
  (data, language) => {
    const i18 = i18next.getFixedT(language);

    const { chartByMonth } = data;
    const { categories, counterparties, projects } = chartByMonth;
    const { income, consumption, months } = categories;

    const incomeCategories = income.map((el) => {
      const { name } = el;

      if (name === 'incomeDefault') {
        return {
          ...el,
          name: i18('category.withoutIncome'),
        };
      }

      if (name === 'incomeGeneric') {
        return {
          ...el,
          name: i18('category.incomeGeneric'),
        };
      }

      return el;
    });

    const consumptionCategories = consumption.map((el) => {
      const { name } = el;

      if (name === 'consumptionDefault') {
        return {
          ...el,
          name: i18('category.withoutConsumption'),
        };
      }

      if (name === 'consumptionGeneric') {
        return {
          ...el,
          name: i18('category.consumptionGeneric'),
        };
      }

      return el;
    });

    const formatCounterpartiesIncome = counterparties.income.map((el) => {
      if (Array.isArray(el.id)) {
        return {
          ...el,
          id: EMPTY,
        };
      }

      return el;
    });

    const formatCounterpartiesConsumption = counterparties.consumption.map(
      (el) => {
        if (Array.isArray(el.id)) {
          return {
            ...el,
            id: EMPTY,
          };
        }

        return el;
      },
    );

    const formatProjectsIncome = projects.income.map((el) => {
      if (Array.isArray(el.id)) {
        return {
          ...el,
          id: EMPTY,
        };
      }

      return el;
    });

    const formatProjectsConsumption = projects.consumption.map((el) => {
      if (Array.isArray(el.id)) {
        return {
          ...el,
          id: EMPTY,
        };
      }

      return el;
    });

    return {
      projects: {
        ...projects,
        income: formatProjectsIncome,
        consumption: formatProjectsConsumption,
      },
      counterparties: {
        ...counterparties,
        income: formatCounterpartiesIncome,
        consumption: formatCounterpartiesConsumption,
      },
      categories: {
        months,
        income: incomeCategories,
        consumption: consumptionCategories,
      },
    };
  },
);

const getStackedDataByTypeFilter = (
  type: OperationType,
  nestedData?: StackedItemData[],
) =>
  createSelector(
    selectStackedData,
    selectTypeFilter,
    (stackedData, typeFilter) => {
      if (nestedData?.length) {
        return nestedData;
      }

      let stackData = stackedData.categories;

      if (typeFilter.id === ReportTypeFilterBy.counterparties) {
        stackData = stackedData.counterparties;
      }

      if (typeFilter.id === ReportTypeFilterBy.projects) {
        stackData = stackedData.projects;
      }

      return type === OperationType.income
        ? stackData.income
        : stackData.consumption;
    },
  );

export const selectCashFlowAndPLStackedChart = (
  type: OperationType,
  selector: keyof FiltersState,
  nestedData?: StackedItemData[],
) =>
  createSelector(
    getLanguage,
    selectPeriodSeriesMonths,
    getStackedDataByTypeFilter(type, nestedData),
    selectCustomFilters(selector),
    (language, months, data, { status }) => {
      const i18 = i18next.getFixedT(language);

      const formatData = data.reduce((acc, el, index) => {
        const colorHEX = getColorHex(index, type);

        let customName = el.name;

        if (type === OperationType.income && el.name === 'incomeDefault') {
          customName = i18('category.withoutIncome');
        }

        if (
          type === OperationType.consumption &&
          el.name === 'consumptionDefault'
        ) {
          customName = i18('category.withoutConsumption');
        }

        if (el.approvedData?.length) {
          acc.push({
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series',
            },
            name: customName,
            // barMinHeight: 30,
            data: el.approvedData.map((appEl) => [
              getxAxisDataFromNumber(appEl[0], language, 'YYYY-MM'),
              appEl[1],
              {
                startDate: appEl[0],
                nestedItems: el.nestedItems,
                id: el.id,
              },
            ]),
            color: colorHEX,
            subCats: el.nestedItems,
          });
        }

        if (el.notApprovedData?.length && status.id !== Status.payed) {
          acc.push({
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series',
            },
            // barMinHeight: 40,
            name: `${customName} (${i18('filters.future')})`,
            data: el.notApprovedData.map((appEl) => [
              getxAxisDataFromNumber(appEl[0], language, 'YYYY-MM'),
              appEl[1],
              {
                startDate: appEl[0],
                nestedItems: el.nestedItems,
                id: el.id,
              },
            ]),
            subCats: el.nestedItems,
            color: colorHEX,
            itemStyle: {
              decal: {
                symbol: 'rect',
                backgroundColor: 'white',
                color: colorHEX,
                rotation: 120,
                dashArrayX: [5, 0],
              },
            },
          });
        }

        return acc;
      }, [] as any);

      return formatData.map((el: any) => {
        const updatedData: any[] = [];

        months.forEach((month) => {
          const currentData = el.data.find(
            (dataEl: any) => dataEl[0] === month,
          );

          if (currentData) {
            currentData[0] = getxAxisDataFromNumber(currentData[0], language);

            updatedData.push(currentData);
          } else {
            updatedData.push([
              getxAxisDataFromNumber(month, language),
              null,
              null,
              [],
            ]);
          }
        });

        return { ...el, data: updatedData };
      });
    },
  );
