import { AxiosResponse } from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import AlertDialog from '../../components/AlertDialog';
import {
  SHOW_URKSIB_BANK_INTEGRATION,
  USE_ONLY_NORDIGEN_SERVICE,
} from '../../constants/featureToggles/integrationsFeatureToggles';
import { Routes } from '../../constants/routes';
import AccountDialog from '../../foundation/AccountsDialog';
import OperationsDialog from '../../foundation/Header/OperationsDialog';
import Settings from '../../foundation/Header/Settings';
import ImportDialog from '../../foundation/ImportDialog';
import FondyDialog from '../../foundation/Integrations/FondyAuth2/FondyAuth2SecondDialog';
import PayoneerDialog from '../../foundation/Integrations/Payoneer/PayoneerSecondDialog';
import UkrsibSecondDialog from '../../foundation/Integrations/UkrsibBank/SecondStepDialog';
import SubscriptionDialog from '../../foundation/Subscriptions/SubscriptionChooseDialog';
import TelegramBotDialog from '../../foundation/TelegramBot';
import useUnleash from '../../hooks/useUnleash';
import FacebookPixelContext from '../../Providers/FacebookFixelProvider/FacebookPixelContext';
import iDokladApi from '../../services/bankIntegrations/idoklad';
import { GetLinkResponse } from '../../services/bankIntegrations/iDoklad.types';
import Storages, { StorageKey } from '../../services/Storages';
import actions from '../../store/auth/action';
import { getUser } from '../../store/auth/selectors';
import commonApi from '../../store/common/api';
import companyActions from '../../store/company/actions';
import { selectDemoCompany } from '../../store/company/selectors';
import integrationActions from '../../store/integrations/actions';
import { CreateIntegrationPayload } from '../../store/integrations/sagas.types';
import { IntegrationTypeId } from '../../store/integrations/types';
import journalApi from '../../store/journal/api';
import { OperationType } from '../../store/operations/types';
import { useStyles } from './styles';

function addStorageSettingsItem(item: string) {
  const settingsStorage = Storages.get(StorageKey.settingsDeepLinks);

  if (settingsStorage) {
    Storages.put(StorageKey.settingsDeepLinks, {
      ...settingsStorage,
      [item]: true,
    });
  } else {
    Storages.put(StorageKey.settingsDeepLinks, { [item]: true });
  }
}

function DeepLinks() {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const facebookContext = useContext(FacebookPixelContext);

  const showUkrsibBankIntegration = useUnleash(SHOW_URKSIB_BANK_INTEGRATION);
  const useOnlyNordigenService = useUnleash(USE_ONLY_NORDIGEN_SERVICE);

  const user = useSelector(getUser);
  const demoCompany = useSelector(selectDemoCompany);

  const [iDokladAuthorisationError, setShowiDokladAuthorisationError] =
    useState(false);

  const params = new URLSearchParams(search);
  const openIncomeOperation = params.get('income-link');
  const openConsumptionOperation = params.get('consumption-link');
  const openTagsLink = params.get('add-tags-link');
  const openCreateCompanyLink = params.get('create-company-link');
  const openProjectsLink = params.get('add-projects-link');
  const openIncomeCategoriesLink = params.get('edit-income-category-link');
  const openConsumptionCategoriesLink = params.get(
    'edit-consumption-category-link',
  );
  const openChangePWLink = params.get('open-change-pw-link');
  const openImportsLink = params.get('open-imports-link');
  const openTariffsLink = params.get('open-tariffs-link');
  const openReferralLink = params.get('open-referral-link');
  const openDemoLink = params.get('open-demo-link');
  const openBot = params.get('bot-link');
  const importLink = params.get('import-link');
  const addBankLink = params.get('add-bank-link');
  const loginLink = params.get('login-link');
  const subscriptionLink = params.get('subscription-link');
  const token = params.get('token');
  const googleAuth = params.get('google-auth');
  const saltedgeConnectionId = params.get('connection_id');
  const ukrsibState = params.get('state');
  const code = params.get('code'); // iDokladCode, ukrsibBankCode, fondyCode, payoneer, Poster
  const grsf = params.get('grsf');
  const account = params.get('account'); // Poster

  if (grsf) {
    Storages.put(StorageKey.grsf, 'true');
  }

  if (openDemoLink) {
    Storages.put(StorageKey.openDemoLink, 'true');
  }

  const isDeepLink =
    openBot || importLink || addBankLink || loginLink || subscriptionLink;

  if (isDeepLink) {
    commonApi.sendDeepLinkStatus(token || '', Boolean(user));
  }

  const loginAction = useCallback(async (): Promise<void> => {
    if (!token) {
      return;
    }

    if (googleAuth) {
      dispatch(
        actions.updateUserProfile(
          {},
          {
            Authorization: `Bearer ${token}`,
          },
          'googleAuth/google/login ',
        ),
      );
    } else {
      dispatch(actions.updateUserProfile({ token }));
    }

    const { data: logCount } = await journalApi.getOperationsCount();

    if (!user?.phone) {
      history.replace(`${Routes.CREATE_PHONE}/${user?.currentLangIso}`);
    } else if (logCount) {
      history.replace(Routes.LOG);
    } else {
      history.replace(Routes.ON_BOARDING);
    }
  }, [dispatch, history, token, googleAuth, user]);

  if (loginLink && token) {
    loginAction();
  }

  const nordigenIntegration = Storages.get(StorageKey.nordigen);
  const saltedgeIntegration = Storages.get(StorageKey.saltedge);
  const ukrsibIntegration = Storages.get(StorageKey.ukrsib);
  const fondyIntegration = Storages.get(StorageKey.fondy);
  const payoneerIntegration = Storages.get(StorageKey.payoneer);
  const posterIntegration = Storages.get(StorageKey.poster);

  if (code && payoneerIntegration) {
    Storages.put(StorageKey.payoneer, {
      ...payoneerIntegration,
      code,
    });
  }

  if (
    posterIntegration &&
    code &&
    account &&
    window.location.pathname === '/redirectPoster'
  ) {
    Storages.put(StorageKey.poster, {
      ...posterIntegration,
      code,
      account,
    });
  }

  if (fondyIntegration && code && window.location.pathname === '/fondyCode') {
    Storages.put(StorageKey.fondy, {
      ...fondyIntegration,
      code,
    });
  }

  if (ukrsibIntegration && code && ukrsibState) {
    Storages.put(StorageKey.ukrsibSecondStep, {
      code,
      ukrsibState,
    });
  }

  if (saltedgeIntegration && saltedgeConnectionId) {
    if (!useOnlyNordigenService) {
      saltedgeIntegration.connectionId = saltedgeConnectionId;

      Storages.put(StorageKey.saltedge, saltedgeIntegration);
    } else {
      Storages.remove(StorageKey.saltedge);
    }
  }

  const [showSubscrioptionDialog, setShowSubscrioptionDialog] =
    useState(subscriptionLink);
  const [showIncomeDialog, setShowIncomeDialog] = useState(openIncomeOperation);
  const [showConsumptionDialog, setShowConsumptionDialog] = useState(
    openConsumptionOperation,
  );
  const [showBotDialog, setShowBotDialog] = useState(openBot);
  const [showImportDialog, setShowImportDialog] = useState(importLink);
  const [showCreateAccountDialog, setShowCreateAccountDialog] =
    useState(addBankLink);
  const [showSaltedgeDialog, setShowSaltedgeDialog] = useState(
    Boolean(saltedgeIntegration?.integrationId) && Boolean(user),
  );
  const [showNordigenDialog, setShowNordigenDialog] = useState(
    Boolean(nordigenIntegration?.integrationId) && Boolean(user),
  );

  const [showUkrsibDialog, setShowUkrsibDialog] = useState(false);

  const [showFondyDialog, setShowFondyDialog] = useState(false);
  const [showPosterDialog, setShowPosterDialog] = useState(
    Boolean(posterIntegration?.startDate) && Boolean(user),
  );

  const [showPayoneerDialog, setShowPayoneerDialog] = useState(false);

  const handleCloseiDokladErrorDialog = useCallback(() => {
    Storages.remove('iDoklad');

    dispatch(integrationActions.setiDokladIntegrationId(null));

    setShowiDokladAuthorisationError(false);
  }, [dispatch]);

  const handleCloseIncomeDialog = useCallback(() => {
    setShowIncomeDialog(null);
  }, []);

  const handleCloseConsumptionDialog = useCallback(() => {
    setShowConsumptionDialog(null);
  }, []);

  const handleCloseBot = useCallback(() => {
    setShowBotDialog(null);
  }, []);

  const handleCloseImport = useCallback(() => {
    setShowImportDialog(null);
  }, []);

  const handleCloseSubscriptionDialog = useCallback(() => {
    setShowSubscrioptionDialog(null);
  }, []);

  const handleCloseCreate = useCallback(() => {
    setShowCreateAccountDialog(null);
  }, []);

  const handleCloseNordigenDialog = useCallback(() => {
    setShowNordigenDialog(false);
  }, []);

  const handleCloseSaltedgeDialog = useCallback(() => {
    setShowSaltedgeDialog(false);
  }, []);

  const handleCloseUkrsibDialog = useCallback(() => {
    Storages.remove(StorageKey.ukrsib);
    Storages.remove(StorageKey.ukrsibSecondStep);

    setShowUkrsibDialog(false);
  }, []);

  const handleCloseFondyDialog = useCallback(() => {
    Storages.remove(StorageKey.fondy);

    setShowFondyDialog(false);
  }, []);

  const handleClosePosterDialog = useCallback(() => {
    Storages.remove(StorageKey.poster);
    setShowPosterDialog(false);
  }, []);

  const handleClosePayoneerDialog = useCallback(() => {
    Storages.remove(StorageKey.payoneer);

    setShowPayoneerDialog(false);
  }, []);

  const handleRepeateiDokladAuthorisation = useCallback(async () => {
    setShowiDokladAuthorisationError(false);

    const { data }: AxiosResponse<GetLinkResponse> = await iDokladApi.getLink(
      window.location.origin,
    );
    const iDokladIntegration = Storages.get('iDoklad');
    const { accountId, startDate } = iDokladIntegration;

    Storages.put('iDoklad', {
      integrationCredentialId: data.integrationCredentialId,
      startDate,
      accountId,
    });

    window.location.href = data.redirectUrl;
  }, []);

  useEffect(() => {
    if (demoCompany && Storages.get(StorageKey.openDemoLink)) {
      Storages.remove(StorageKey.openDemoLink);

      dispatch(
        companyActions.changeCompany({
          id: demoCompany._id,
        }),
      );
    }
  }, [dispatch, demoCompany]);

  useEffect(() => {
    const checkUserAccess = async (
      iDokladCode: string,
      id: string,
      accountId: string,
      startDate: number,
    ) => {
      try {
        await iDokladApi.setTokens(iDokladCode, id);

        dispatch(integrationActions.setiDokladIntegrationId(id));

        const integrationPayload: CreateIntegrationPayload = {
          facebookContext,
          accountId,
          startDate,
          typeId: IntegrationTypeId.iDoklad,
        };

        dispatch(integrationActions.createIntegration(integrationPayload));

        Storages.remove('iDoklad');
      } catch (e: any) {
        dispatch(integrationActions.setiDokladIntegrationId(null));

        const status = e.response?.status;

        if (status === 401) {
          setShowiDokladAuthorisationError(true);
        }
      }
    };

    const iDokladIntegration = Storages.get('iDoklad');

    if (iDokladIntegration && code) {
      const { accountId, startDate, integrationCredentialId } =
        iDokladIntegration;

      checkUserAccess(code, integrationCredentialId, accountId, startDate);
    }
  }, [dispatch, facebookContext, code]);

  useEffect(() => {
    const ukrsibSecondStep = Storages.get(StorageKey.ukrsibSecondStep);

    if (ukrsibSecondStep) {
      if (ukrsibSecondStep.code) {
        dispatch(integrationActions.setUkrsibClientCode(ukrsibSecondStep.code));
      }

      if (ukrsibIntegration?.integrationId) {
        dispatch(
          integrationActions.setUkrsibIntegrationId(
            ukrsibIntegration.integrationId,
          ),
        );
      }

      setShowUkrsibDialog(Boolean(user) && showUkrsibBankIntegration);
    }
  }, [user, dispatch, ukrsibIntegration, showUkrsibBankIntegration]);

  useEffect(() => {
    if (fondyIntegration && fondyIntegration.code) {
      setShowFondyDialog(!!user);
    }
  }, [user, fondyIntegration]);

  useEffect(() => {
    if (payoneerIntegration) {
      setShowPayoneerDialog(Boolean(user));
    }
  }, [user, payoneerIntegration]);

  useEffect(() => {
    if (posterIntegration) setShowPosterDialog(Boolean(user));
  }, [user, posterIntegration]);

  if (openTagsLink) {
    addStorageSettingsItem('openTagsLink');
  }

  if (openTariffsLink) {
    addStorageSettingsItem('openTariffsLink');
  }

  if (openImportsLink) {
    addStorageSettingsItem('openImportsLink');
  }

  if (openChangePWLink) {
    addStorageSettingsItem('openChangePWLink');
  }

  if (openProjectsLink) {
    addStorageSettingsItem('openProjectsLink');
  }

  if (openCreateCompanyLink) {
    addStorageSettingsItem('openCreateCompanyLink');
  }

  if (openIncomeCategoriesLink) {
    addStorageSettingsItem('openIncomeCategoriesLink');
  }

  if (openConsumptionCategoriesLink) {
    addStorageSettingsItem('openConsumptionCategoriesLink');
  }

  if (openReferralLink) {
    addStorageSettingsItem('openReferralLink');
  }

  return (
    <>
      {!!user && <Settings disableIcon />}
      {showIncomeDialog && Boolean(user) && (
        <OperationsDialog
          type={OperationType.income}
          onClose={handleCloseIncomeDialog}
        />
      )}
      {showConsumptionDialog && Boolean(user) && (
        <OperationsDialog
          type={OperationType.consumption}
          onClose={handleCloseConsumptionDialog}
        />
      )}
      {showBotDialog && Boolean(user) && (
        <TelegramBotDialog onClose={handleCloseBot} />
      )}
      {showImportDialog && Boolean(user) && (
        <ImportDialog onClose={handleCloseImport} />
      )}
      {showCreateAccountDialog && Boolean(user) && (
        <AccountDialog onClose={handleCloseCreate} initialShowCreate />
      )}
      {showSubscrioptionDialog && Boolean(user) && (
        <SubscriptionDialog onClose={handleCloseSubscriptionDialog} />
      )}
      {showNordigenDialog && (
        <AccountDialog onClose={handleCloseNordigenDialog} />
      )}
      {showPosterDialog && <AccountDialog onClose={handleClosePosterDialog} />}
      {showSaltedgeDialog && !useOnlyNordigenService && (
        <AccountDialog onClose={handleCloseSaltedgeDialog} />
      )}
      {iDokladAuthorisationError && (
        <AlertDialog
          title={t('common.error')}
          onClose={handleCloseiDokladErrorDialog}
          onAccept={handleRepeateiDokladAuthorisation}
          acceptText={t('bank.repeatAuth')}
          description={t('bank.iDoklad.error')}
          buttonTextColor={classes.buttonTextColor}
          buttonBackground={classes.buttonBackground}
        />
      )}
      {showUkrsibDialog && (
        <UkrsibSecondDialog onClose={handleCloseUkrsibDialog} />
      )}
      {showFondyDialog && <FondyDialog onClose={handleCloseFondyDialog} />}
      {showPayoneerDialog && (
        <PayoneerDialog onClose={handleClosePayoneerDialog} />
      )}
    </>
  );
}

export default React.memo(DeepLinks);
