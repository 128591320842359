import iconv from 'iconv-lite';
import _, { isArray } from 'lodash';

import { TranslationService } from '@finmap/core-translations';
import { isEmptyImportField } from '@finmap/core-utils';

import { AVAILABLE_IMPORT_TYPES } from './base-import-parser-v3.const';
import {
  AccountsOrAccount,
  CaseConfig,
  CaseOptions,
  Config,
  searchFunc,
} from './base-import-parser-v3.dto';
import { ImportOperation, ImportResultItem } from './import-operation';
import {
  ImportOperationMask,
  ImportResultItemMask,
} from './import-operation-mask';
import { BaseCSVPreParser } from './preparsers/base-csv-preparser';
import { BaseImportPreParser } from './preparsers/base-import-preparser';
import { BasePDFPreParser } from './preparsers/base-pdf-preparser';
import { BaseXLSXPreParser } from './preparsers/base-xlsx-preparser';
import { isNotEmpty } from 'class-validator';
import moment from 'moment';

export const PREPARSERS_MAP: {
  [key in AVAILABLE_IMPORT_TYPES]: BaseImportPreParser;
} = {
  [AVAILABLE_IMPORT_TYPES.CSV]: new BaseCSVPreParser(),
  [AVAILABLE_IMPORT_TYPES.XLS]: new BaseXLSXPreParser(),
  [AVAILABLE_IMPORT_TYPES.XLSX]: new BaseXLSXPreParser(),
  [AVAILABLE_IMPORT_TYPES.PDF]: new BasePDFPreParser(),
};

type ValueOf<T> = T[keyof T];

export class BaseImportParserV3 {
  protected readonly config: Config;
  public readonly debug: boolean = false;

  private line: number;
  private importDocument = [];
  private store = {};
  private documentHeader = [];
  private documentBody = [];
  private readonly translationsService = new TranslationService();
  private errorTranslations;

  public async parse(
    file: File,
    language: string,
    accounts: AccountsOrAccount,
  ): Promise<ImportResultItem[]> {
    try {
      const START_DATE = new Date();

      this.setErrorsTranslations(language);

      const [typeParser, chosenTypeParserCase, OPTIONS] =
        await this.matchWithTypes(file);

      let buffer: ArrayBuffer = await file.arrayBuffer();
      if (OPTIONS.encoding === 'win1251') {
        const decodeCSVString = iconv.decode(Buffer.from(buffer), 'win1251');
        buffer = Buffer.from(decodeCSVString);
      }

      this.importDocument = await typeParser.preParse(buffer, {
        ...(OPTIONS.preParserConfigs as CaseOptions),
        debug: this.debug,
      });
      this.store = typeParser.store;
      typeParser.store = [];

      if (this.debug) console.log({ importDocument: this.importDocument });
      let parseOperation;
      if (!OPTIONS.proceedCaseConfig) {
        parseOperation = chosenTypeParserCase.proceedCase(this.importDocument);
      } else {
        let body = this.importDocument;
        if (OPTIONS.proceedCaseConfig.withoutEmpty) {
          body = body
            .map((el) => (el.length ? el.filter(Boolean) : []))
            .filter((el) => Boolean(el.length));
          if (this.debug) console.log({ bodyWithoutEmpty: body });
        }
        if (OPTIONS.proceedCaseConfig.delete) {
          this.deleteFromTo(body, OPTIONS.proceedCaseConfig.delete);
        }
        const header = body[0];
        body = body.slice(1);

        if (this.debug) console.log({ header, body });

        this.setDocumentHeader(header);
        this.setDocumentBody(body);
        parseOperation = (): ImportResultItemMask => {
          const result = {};
          Object.keys(OPTIONS.proceedCaseConfig.fields).forEach((key) => {
            const field = OPTIONS.proceedCaseConfig.fields[key];
            let value = this.getFirstValidCellByColumn(field.column);
            if (field.if) {
              if (field.if.eq) {
                const firstValue = field.if.eq[0].column
                  ? this.getFirstValidCellByColumn(field.if.eq[0].column)
                  : field.if.eq[0];
                const secondValue = field.if.eq[1].column
                  ? this.getFirstValidCellByColumn(field.if.eq[1].column)
                  : field.if.eq[1];
                if (firstValue !== secondValue) return;
              }
            }
            if (field.add) {
              field.add.forEach((el) => {
                if (el.column) {
                  value += this.getFirstValidCellByColumn(el.column);
                } else {
                  value += el;
                }
              });
            }
            result[key] = value;
          });
          return result;
        };
      }

      const result: ImportResultItem[] = [];

      let i = OPTIONS.isDESCOpOrder ? this.documentBody.length - 1 : 0;
      const check = () =>
        OPTIONS.isDESCOpOrder ? i >= 0 : i < this.documentBody.length;
      const inc = () => (OPTIONS.isDESCOpOrder ? i-- : i++);

      for (; check(); inc()) {
        this.line = i;

        let operationMask: ImportResultItemMask = parseOperation();
        operationMask.index = OPTIONS.isDESCOpOrder
          ? this.documentBody.length - this.line - 1
          : this.line;
        operationMask = new ImportOperationMask(operationMask).prepareOperation(
          accounts,
          OPTIONS,
          START_DATE,
        );
        const operation = new ImportOperation(
          operationMask,
          OPTIONS,
          this.documentBody.length,
        ).toObject(this.errorTranslations);

        result.push(operation);
      }
      if (!result.length)
        this.throwError(this.errorTranslations?.wrongExtension);

      if (this.debug) console.log(result);

      return result;
    } catch (error) {
      if (this.debug) console.log(error);
      if (error?.message) throw error;
      this.throwError(this.errorTranslations?.wrongExtension);
    }
  }

  private setErrorsTranslations(language: string) {
    this.errorTranslations =
      this.translationsService.getResources()[
        language
      ]?.translation?.import?.errors;
  }

  private async matchWithTypes(
    file: File,
  ): Promise<[BaseImportPreParser, CaseConfig, CaseOptions]> {
    const matchType = file.name.match(
      new RegExp(
        Object.values(AVAILABLE_IMPORT_TYPES)
          .map((k) => `\\${k}$`)
          .join('|'),
        'i',
      ),
    );
    if (!matchType?.length)
      this.throwError(this.errorTranslations?.wrongExtension);

    const [fileType] = matchType.map((t) => t?.toLowerCase());
    const typeParser: ValueOf<typeof PREPARSERS_MAP> = PREPARSERS_MAP[fileType];
    if (!typeParser) this.throwError(this.errorTranslations?.wrongExtension);

    const typeParserCases = this.config[fileType];
    if (!typeParserCases?.length)
      this.throwError(this.errorTranslations?.wrongExtension);

    let chosenTypeParserCase: CaseConfig;
    for (const typeParserCase of typeParserCases) {
      if (!typeParserCase.isCurCase && !typeParserCase.caseOptions.isCurCase) {
        chosenTypeParserCase = typeParserCase;
        break;
      } else if (
        !typeParserCase.isCurCase &&
        typeParserCase.caseOptions.isCurCase
      ) {
        const preParser = typeParserCase.preParser
          ? typeParserCase.preParser
          : typeParser;
        let rawDocument = await preParser.getRawData(await file.arrayBuffer());
        if (typeParserCase.caseOptions.withoutEmpty) {
          rawDocument = rawDocument
            .map((el) => (el.length ? el.filter(Boolean) : []))
            .filter((el) => Boolean(el.length));
        }
        const isCurrent = isNotEmpty(
          rawDocument.find((value, i) =>
            this.isCurrentCheck(
              rawDocument,
              i,
              typeParserCase.caseOptions.isCurCase,
            ),
          ),
        );
        if (isCurrent) {
          chosenTypeParserCase = typeParserCase;
          break;
        }
      } else if (typeParserCase.isCurCase) {
        const isCurrent = await typeParserCase.isCurCase(
          file,
          typeParserCase.preParser ? typeParserCase.preParser : typeParser,
        );
        if (isCurrent) {
          chosenTypeParserCase = typeParserCase;
          break;
        }
      }
    }
    if (!chosenTypeParserCase)
      this.throwError(this.errorTranslations?.wrongExtension);

    const OPTIONS: CaseOptions = chosenTypeParserCase.caseOptions;

    return [
      chosenTypeParserCase.preParser
        ? chosenTypeParserCase.preParser
        : typeParser,
      chosenTypeParserCase,
      OPTIONS,
    ];
  }

  private throwError(message: string, line = 1): never {
    throw new Error(
      JSON.stringify({
        message,
        line,
      }),
    );
  }

  protected isImportValueValid(value: unknown): boolean {
    return !isEmptyImportField(value);
  }

  protected setDocumentHeader(documentHeader) {
    this.documentHeader = documentHeader;
  }

  protected setDocumentBody(documentBody) {
    this.documentBody = documentBody;
  }

  protected getFirstValidCellByColumn(
    columnIdentifiers: (string | number)[],
    validationFunc = this.isImportValueValid,
    options: {
      compareStringColumnsAtLower?: boolean;
      compareStringColumnsAsInclude?: boolean;
      revertSearch?: boolean;
    } = {
      compareStringColumnsAtLower: false,
      compareStringColumnsAsInclude: true,
      revertSearch: false,
    },
  ) {
    for (const columnIdentifier of columnIdentifiers) {
      if (typeof columnIdentifier === 'number') {
        if (validationFunc(this.documentBody[this.line][columnIdentifier]))
          return this.documentBody[this.line][columnIdentifier];
      }
      if (typeof columnIdentifier === 'string') {
        const foundColumnIndex = (
          options.revertSearch ? _.findLastIndex : _.findIndex
        )(this.documentHeader, (header: unknown) => {
          if (options.compareStringColumnsAsInclude) {
            return options.compareStringColumnsAtLower
              ? header
                  .toString()
                  .toLowerCase()
                  .includes(columnIdentifier.toLowerCase())
              : header.toString().includes(columnIdentifier);
          }
          return options.compareStringColumnsAtLower
            ? header.toString().toLowerCase() === columnIdentifier.toLowerCase()
            : header.toString() === columnIdentifier;
        });
        if (foundColumnIndex === -1) continue;
        else if (validationFunc(this.documentBody[this.line][foundColumnIndex]))
          return this.documentBody[this.line][foundColumnIndex];
      }
    }
  }

  protected splitFields(columnIdentifiers: number[]) {
    let result = '';
    for (const columnIdentifier of columnIdentifiers) {
      result += this.documentBody[this.line][columnIdentifier] || '';
    }
    return result || undefined;
  }

  protected getFromStore(field: string) {
    return this.store[field];
  }

  protected findString(matchValue: RegExp | searchFunc):
    | {
        data: unknown;
        raw: number;
        column: number;
      }
    | undefined {
    if (typeof matchValue === 'object') {
      for (let i = 0; i < this.importDocument.length; i++) {
        for (let j = 0; j < this.importDocument[i].length; j++) {
          const curWord = this.importDocument[i][j];
          if (matchValue.test(curWord)) {
            return { data: curWord, raw: i, column: j };
          }
        }
      }
    } else {
      for (let i = 0; i < this.importDocument.length; i++) {
        for (let j = 0; j < this.importDocument[i].length; j++) {
          const curWord = this.importDocument[i][j];
          let prevWord = '';
          if (this.importDocument[i][j - 1])
            prevWord = this.importDocument[i][j - 1];
          else if (this.importDocument[i - 1] && this.importDocument[i - 1][j])
            prevWord = this.importDocument[i - 1][j];
          let nextWord = '';
          if (this.importDocument[i][j + 1])
            nextWord = this.importDocument[i][j + 1];
          else if (this.importDocument[i + 1] && this.importDocument[i + 1][j])
            nextWord = this.importDocument[i + 1][j];
          if (matchValue(curWord, { prevWord, nextWord })) {
            return { data: curWord, raw: i, column: j };
          }
        }
      }
    }
  }

  listAvailableImportTypes(): AVAILABLE_IMPORT_TYPES[] {
    return Object.keys(this.config) as AVAILABLE_IMPORT_TYPES[];
  }

  private isCurrentCheck(object, currentIndex, conditions) {
    const useStr = object[0]?.str !== undefined;
    for (const cond of conditions) {
      if (isArray(cond)) {
        let value;
        if (isArray(cond[0])) {
          value = '';
          for (const index of cond[0]) {
            value += useStr
              ? object[currentIndex + index]?.str
              : object[currentIndex][index];
          }
        } else {
          const index = cond[0];
          value = useStr
            ? object[currentIndex + index]?.str
            : object[currentIndex][index];
        }
        const [finalCond] = Object.keys(cond[1]);
        const condValue = cond[1][finalCond];
        if (finalCond === 'eq') {
          if (value !== condValue) return false;
        } else if (finalCond === 'in') {
          if (!value.includes(condValue)) return false;
        } else if (finalCond === 'dateFormat') {
          if (!moment(value, condValue, true).isValid()) return false;
        } else if (finalCond === 'split') {
          const spliter = condValue.spliter;
          const newConds = condValue.arr;
          const values = useStr
            ? value.split(spliter).map((el) => ({ str: el }))
            : value;
          if (!this.isCurrentCheck(values, 0, newConds)) return false;
        }
      } else {
        const keys = Object.keys(cond);
        if (keys.includes('or')) {
          if (
            !cond.or
              .map((el) => this.isCurrentCheck(object, currentIndex, [el]))
              .some((el) => el)
          ) {
            return false;
          }
        } else if (keys.includes('and')) {
          if (
            !cond.and
              .map((el) => this.isCurrentCheck(object, currentIndex, [el]))
              .every((el) => el)
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private deleteFromTo(object, conds) {
    for (const cond of conds) {
      const count = cond.count || Infinity;
      for (let i = 0; i < count; i++) {
        let modified = false;
        let fromIndex, toIndex;
        if (cond.from) {
          fromIndex = object.findIndex((arr, i) =>
            this.isCurrentCheck(object, i, cond.from),
          );
          if (!cond.to && typeof fromIndex === 'number') {
            modified = true;
            object.splice(fromIndex);
          }
        }

        if (cond.to) {
          toIndex = object.findIndex((arr, i) =>
            this.isCurrentCheck(object, i, cond.to),
          );
          if (!cond.from && typeof toIndex === 'number') {
            modified = true;
            object.splice(0, toIndex);
          }
        }

        if (
          cond.from &&
          cond.to &&
          typeof fromIndex === 'number' &&
          typeof toIndex === 'number'
        ) {
          modified = true;
          object.splice(fromIndex, toIndex);
        }
        if (!modified) break;
      }
    }
  }
}
