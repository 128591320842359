import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AndIcon from '../../../../assets/images/svg/and.svg';
import ConnectionSafeIcon from '../../../../assets/images/svg/connectionSafe.svg';
import NordigenLogo from '../../../../assets/images/svg/nordigen.svg';
import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import { useStyles } from './styles';
import { Props } from './types';

function NordigenInfoDialog(props: Props) {
  const { onClose, onChange, bank, loading } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('bank.nordigenInfo.title', {
        postProcess: 'sprintf',
        sprintf: [bank.name],
      })}
    >
      <div className={classes.imagesRootContainer}>
        <div
          className={cn(classes.firstImageContainer, classes.imageContainer)}
        >
          <img src={NordigenLogo} alt="logo" />
          <img src={AndIcon} alt="logo" className={classes.andIcon} />
        </div>
        <div
          className={cn(classes.secondImageContainer, classes.imageContainer)}
        >
          <img
            src={bank.logoLink}
            alt="logo"
            className={cn(classes.icon, classes.bankIconMargin)}
          />
          <Typography className={classes.bankName}>{bank.name}</Typography>
        </div>
      </div>
      <div className={classes.descriptionContainer}>
        <Typography className={cn(classes.text, classes.separatorText)}>
          {t('bank.nordigenInfo.description', {
            postProcess: 'sprintf',
            sprintf: [bank.name],
          })}
        </Typography>
        <a
          href="https://www.nordigen.com"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <Typography className={cn(classes.text, classes.linkText)}>
            www.nordigen.com
          </Typography>
        </a>
        <Typography className={cn(classes.text, classes.separatorText)}>
          {`. ${t('bank.nordigenInfo.warningDescription')}`}
        </Typography>
      </div>
      <Typography className={cn(classes.text, classes.separator)}>
        {t('bank.nordigenInfo.details')}
      </Typography>
      <div className={classes.safeInfoContainer}>
        <img src={ConnectionSafeIcon} alt="safe" className={classes.safeIcon} />
        <Typography>{t('integrations.connectionSafe')}</Typography>
      </div>
      <CustomButton
        fullWidth
        loading={loading}
        title={t('common.ok')}
        action={onChange}
      />
    </Dialog>
  );
}

export default React.memo(NordigenInfoDialog);
