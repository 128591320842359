import i18next from 'i18next';
import { createSelector } from 'reselect';

import C1Icon from '../../assets/images/svg/payments/1c.svg';
import MoneyIcon from '../../assets/images/svg/payments/3smoney.svg';
import AbankIcon from '../../assets/images/svg/payments/abank.svg';
import AlfabankIcon from '../../assets/images/svg/payments/alfabank.svg';
import BankPekaoIcon from '../../assets/images/svg/payments/bank-pekao.jpeg';
import BankPivdennyIcon from '../../assets/images/svg/payments/bank-pivdenny.png';
import BSIBankIcon from '../../assets/images/svg/payments/bsi-bank.png';
import CenterCreditBankIcon from '../../assets/images/svg/payments/centerCredit_logo.png';
import CreditAgricoleIcon from '../../assets/images/svg/payments/creditAgricole.png';
import EcoCenterBankIcon from '../../assets/images/svg/payments/ecoCenterBank.jpeg';
import ExcelIcon from '../../assets/images/svg/payments/excel.svg';
import Finmap1 from '../../assets/images/svg/payments/finmap1.svg';
import FintabloIcon from '../../assets/images/svg/payments/fintablo.svg';
import ForteBankIcon from '../../assets/images/svg/payments/fortebank.png';
import HalykbankIcon from '../../assets/images/svg/payments/halyk-bank.svg';
import IngBankIcon from '../../assets/images/svg/payments/ingbank.svg';
import JusanBankIcon from '../../assets/images/svg/payments/jusanBank.svg';
import KaspiBankIcon from '../../assets/images/svg/payments/kaspiBank.png';
import KaspiBusinessIcon from '../../assets/images/svg/payments/kaspiBusiness.svg';
import KredoBankIcon from '../../assets/images/svg/payments/kredoBank.svg';
import MbankIcon from '../../assets/images/svg/payments/mbank.png';
import MercuryBankIcon from '../../assets/images/svg/payments/mercury_logo.png';
import OschadbankIcon from '../../assets/images/svg/payments/oschadbank.svg';
import OtpBankIcon from '../../assets/images/svg/payments/otp_bank.svg';
import OtpHungaryIcon from '../../assets/images/svg/payments/otpHungary.jpeg';
import PaypalIcon from '../../assets/images/svg/payments/paypal.jpg';
import PayseraIcon from '../../assets/images/svg/payments/paysera.png';
import PKOBankIcon from '../../assets/images/svg/payments/pkoBank.svg';
import PrivatBankIcon from '../../assets/images/svg/payments/privatBank.jpeg';
import ProCreditBankIcon from '../../assets/images/svg/payments/proCredit.svg';
import PUMBPersonalIcon from '../../assets/images/svg/payments/pumbPersonal.png';
import RaiffeisenbankIcon from '../../assets/images/svg/payments/raiffeisenbank.svg';
import BankRBKIcon from '../../assets/images/svg/payments/rbk-kz-bank.png';
import RwSBankIcon from '../../assets/images/svg/payments/rwsBank.jpeg';
import SantanderPolandIcon from '../../assets/images/svg/payments/santanderPoland.svg';
import sebBankLatviaIcon from '../../assets/images/svg/payments/sebBankLatvia.svg';
import SenseBankIcon from '../../assets/images/svg/payments/senseBank.webp';
import SilverbirdBankIcon from '../../assets/images/svg/payments/silverbirdBank.webp';
import SportbankIcon from '../../assets/images/svg/payments/sportbank.png';
import TaskombankIcon from '../../assets/images/svg/payments/taskombank.png';
import UkrGazBankIcon from '../../assets/images/svg/payments/ukrGazBank.svg';
import UkrsibbankIcon from '../../assets/images/svg/payments/ukrsibbank.svg';
import UniversalBankIcon from '../../assets/images/svg/payments/universalBank.svg';
import UmlimintIcon from '../../assets/images/svg/payments/unlimint.webp';
import UpworkIcon from '../../assets/images/svg/payments/upwork.svg';
import VostokBankIcon from '../../assets/images/svg/payments/vostokBank.svg';
import WiseBusinessIcon from '../../assets/images/svg/payments/wiseBusiness.png';
import KapitalbankIcon from '../../assets/images/svg/payments/kapitalbank.png';
import NBUzIcon from '../../assets/images/svg/payments/NBUz.png';
import IpalYuliBankIcon from '../../assets/images/svg/payments/ipalYuliBank.jpeg';
import OrintFinansBank from '../../assets/images/svg/payments/orientFinansBank.png';
import MilleniumBank from '../../assets/images/svg/payments/MilleniumBank.png';
import ChaseBank from '../../assets/images/svg/payments/ChaseBank.png';
import PostFinance from '../../assets/images/svg/payments/PostFinance.png';
import Revolut from '../../assets/images/svg/payments/Revolut.png';
import AltBank from '../../assets/images/svg/payments/AltBank.png';
import IziBank from '../../assets/images/svg/payments/IziBank.png';
import NovaPay from '../../assets/images/svg/payments/NovaPay.jpeg';
import Sqb from '../../assets/images/svg/payments/Sqb.jpeg';
import AsiaAllianceBank from '../../assets/images/svg/payments/AsiaAllianceBank.jpeg';
import { getLanguage } from '../../selectors/main';
import { AppState } from '../reducers';
import { ImportType } from './types';

export enum ImportFrom {
  services = 'services',
  ukraineImports = 'ukraineImports',
  polandImports = 'polandImports',
  kazakhstanImports = 'kazakhstanImports',
  uzbekistanImports = 'uzbekistanImports',
  otherCountriesImports = 'otherCountriesImports',
}
export const getPDFBuffer = (state: AppState) => state.imports.pdfImportBuffer;

export const selectImportsList = createSelector(getLanguage, (language) => {
  const i18 = i18next.getFixedT(language);

  const services = [
    {
      id: ImportType.excel,
      icon: ExcelIcon,
      title: i18('import.type.excel'),
      dataAttribute: 'excel-file-input',
      type: ImportFrom.services,
    },
    {
      id: ImportType.finmap1,
      icon: Finmap1,
      title: i18('import.type.finmap1'),
      dataAttribute: 'finmap1-file-input',
      type: ImportFrom.services,
    },
    {
      id: ImportType.planfact,
      icon: '',
      title: i18('import.type.planfact'),
      dataAttribute: 'planfact-file-input',
      type: ImportFrom.services,
    },
    {
      id: ImportType.finolog,
      icon: '',
      title: i18('import.type.finolog'),
      dataAttribute: 'finolog-file-input',
      type: ImportFrom.services,
    },
    {
      id: ImportType.fintablo,
      icon: FintabloIcon,
      title: i18('import.type.fintablo'),
      dataAttribute: 'fintablo-file-input',
      type: ImportFrom.services,
    },
    {
      id: ImportType['1c'],
      icon: C1Icon,
      title: i18('import.type.1c'),
      dataAttribute: '1c-file-input',
      type: ImportFrom.services,
    },
    {
      id: ImportType.upwork,
      icon: UpworkIcon,
      title: i18('import.type.upwork'),
      dataAttribute: 'upwork-file-input',
      type: ImportFrom.services,
    },
    {
      id: ImportType.paypal,
      icon: PaypalIcon,
      title: i18('import.type.paypal'),
      dataAttribute: 'paypal-file-input',
      type: ImportFrom.services,
    },
  ];

  const ukraineImports = [
    {
      id: ImportType.privatBank,
      icon: PrivatBankIcon,
      title: i18('import.type.privatBank'),
      dataAttribute: 'privatBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.raiffeisenbank,
      icon: RaiffeisenbankIcon,
      title: i18('import.type.raiffeisenbank'),
      dataAttribute: 'raiffeisenbank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.oschadbank,
      icon: OschadbankIcon,
      title: i18('import.type.oschadbank'),
      dataAttribute: 'oschadbank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.ukrsibbank,
      icon: UkrsibbankIcon,
      title: i18('import.type.ukrsibbank'),
      dataAttribute: 'ukrsibbank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.alfabank,
      icon: AlfabankIcon,
      title: i18('import.type.alfabank'),
      dataAttribute: 'alfabank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.pumbPersonal,
      icon: PUMBPersonalIcon,
      title: i18('import.type.pumbPersonal'),
      dataAttribute: 'pumbPersonal-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.otpBank,
      icon: OtpBankIcon,
      title: i18('import.type.otpBank'),
      dataAttribute: 'otpBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.kredoBank,
      icon: KredoBankIcon,
      title: i18('import.type.kredoBank'),
      dataAttribute: 'kredoBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.vostokBank,
      icon: VostokBankIcon,
      title: i18('import.type.vostokBank'),
      dataAttribute: 'vostokBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.proCreditBank,
      icon: ProCreditBankIcon,
      title: i18('import.type.proCreditBank'),
      dataAttribute: 'proCreditBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.ukrGazBank,
      icon: UkrGazBankIcon,
      title: i18('import.type.ukrGazBank'),
      dataAttribute: 'ukrGazBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.universalBank,
      icon: UniversalBankIcon,
      title: i18('import.type.universalBank'),
      dataAttribute: 'universalBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.taskombank,
      icon: TaskombankIcon,
      title: i18('import.type.taskombank'),
      dataAttribute: 'taskombank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.rwsBank,
      icon: RwSBankIcon,
      title: i18('import.type.rwsBank'),
      dataAttribute: 'rwsBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.bankPivdenny,
      icon: BankPivdennyIcon,
      title: i18('import.type.bankPivdenny'),
      dataAttribute: 'bankPivdenny-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.creditAgricole,
      icon: CreditAgricoleIcon,
      title: i18('import.type.creditAgricole'),
      dataAttribute: 'creditAgricole-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.abank,
      icon: AbankIcon,
      title: i18('import.type.abank'),
      dataAttribute: 'abank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.senseBank,
      icon: SenseBankIcon,
      title: i18('import.type.senseBank'),
      dataAttribute: 'senseBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.sportbank,
      icon: SportbankIcon,
      title: i18('import.type.sportbank'),
      dataAttribute: 'sportbank-file-input',
      type: ImportFrom.ukraineImports,
    },
  ];

  const polandImports = [
    {
      id: ImportType.santanderPoland,
      icon: SantanderPolandIcon,
      title: i18('import.type.santanderPoland'),
      dataAttribute: 'santanderPoland-file-input',
      type: ImportFrom.polandImports,
    },
    {
      id: ImportType.ingBank,
      icon: IngBankIcon,
      title: i18('import.type.ingBank'),
      dataAttribute: 'ingBank-file-input',
      type: ImportFrom.polandImports,
    },
    {
      id: ImportType.mBankVATPoland,
      icon: MbankIcon,
      title: i18('import.type.mBankVATPoland'),
      dataAttribute: 'mBankVATPoland-file-input',
      type: ImportFrom.polandImports,
    },
    {
      id: ImportType.bankPekao,
      icon: BankPekaoIcon,
      title: i18('import.type.bankPekao'),
      dataAttribute: 'bankPekao-file-input',
      type: ImportFrom.polandImports,
    },
    {
      id: ImportType.pkoBank,
      icon: PKOBankIcon,
      title: i18('import.type.pkoBank'),
      dataAttribute: 'pkoBank-file-input',
      type: ImportFrom.polandImports,
    },
  ];

  const kazakhstanImports = [
    {
      id: ImportType.kaspiBusiness,
      icon: KaspiBusinessIcon,
      title: i18('import.type.kaspiBusiness'),
      dataAttribute: 'kaspiBusiness-file-input',
      type: ImportFrom.kazakhstanImports,
    },

    {
      id: ImportType.kaspiPrivate,
      icon: KaspiBusinessIcon,
      title: i18('import.type.kaspiPrivate'),
      dataAttribute: 'kaspiPrivate-file-input',
      type: ImportFrom.kazakhstanImports,
    },

    {
      id: ImportType.kaspiBank,
      icon: KaspiBankIcon,
      title: i18('import.type.kaspiBank'),
      dataAttribute: 'kaspiBank-file-input',
      type: ImportFrom.kazakhstanImports,
    },
    {
      id: ImportType.halykbank,
      icon: HalykbankIcon,
      title: i18('import.type.halykbank'),
      dataAttribute: 'halykbank-file-input',
      type: ImportFrom.kazakhstanImports,
    },
    {
      id: ImportType.jusanBank,
      icon: JusanBankIcon,
      title: i18('import.type.jusanBank'),
      dataAttribute: 'jusanBank-file-input',
      type: ImportFrom.kazakhstanImports,
    },
    {
      id: ImportType.forteBank,
      icon: ForteBankIcon,
      title: i18('import.type.forteBank'),
      dataAttribute: 'forteBank-file-input',
      type: ImportFrom.kazakhstanImports,
    },
    {
      id: ImportType.centerCreditBank,
      icon: CenterCreditBankIcon,
      title: i18('import.type.centerCreditBank'),
      dataAttribute: 'centerCreditBank-file-input',
      type: ImportFrom.kazakhstanImports,
    },
    {
      id: ImportType.ecoCenterBank,
      icon: EcoCenterBankIcon,
      title: i18('import.type.ecoCenterBank'),
      dataAttribute: 'ecoCenterBank-file-input',
      type: ImportFrom.kazakhstanImports,
    },
    {
      id: ImportType.bankRBK,
      icon: BankRBKIcon,
      title: i18('import.type.bankRBK'),
      dataAttribute: 'bankRBK-file-input',
      type: ImportFrom.kazakhstanImports,
    },
  ];

  const uzbekistanImports = [
    {
      id: ImportType.kapitalbank,
      icon: KapitalbankIcon,
      title: i18('import.type.kapitalbank'),
      dataAttribute: 'kapitalbank-file-input',
      type: ImportFrom.uzbekistanImports,
    },
    {
      id: ImportType.ipalYuliBank,
      icon: IpalYuliBankIcon,
      title: i18('import.type.ipalYuliBank'),
      dataAttribute: 'ipalYuliBank-file-input',
      type: ImportFrom.uzbekistanImports,
    },
    {
      id: ImportType.NBUz,
      icon: NBUzIcon,
      title: i18('import.type.NBUz'),
      dataAttribute: 'NBUz-file-input',
      type: ImportFrom.uzbekistanImports,
    },
    {
      id: ImportType.orintFinansBank,
      icon: OrintFinansBank,
      title: i18('import.type.orintFinansBank'),
      dataAttribute: 'orintFinansBank-file-input',
      type: ImportFrom.uzbekistanImports,
    },
    {
      id: ImportType.milleniumBank,
      icon: MilleniumBank,
      title: i18('import.type.milleniumBank'),
      dataAttribute: 'milleniumBank-file-input',
      type: ImportFrom.polandImports,
    },
    {
      id: ImportType.chaseBank,
      icon: ChaseBank,
      title: i18('import.type.chaseBank'),
      dataAttribute: 'chaseBank-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.postFinance,
      icon: PostFinance,
      title: i18('import.type.postFinance'),
      dataAttribute: 'postFinance-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.revolut,
      icon: Revolut,
      title: i18('import.type.revolut'),
      dataAttribute: 'revolut-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.altBank,
      icon: AltBank,
      title: i18('import.type.altBank'),
      dataAttribute: 'altBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.iziBank,
      icon: IziBank,
      title: i18('import.type.iziBank'),
      dataAttribute: 'iziBank-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.novaPay,
      icon: NovaPay,
      title: i18('import.type.novaPay'),
      dataAttribute: 'novaPay-file-input',
      type: ImportFrom.ukraineImports,
    },
    {
      id: ImportType.sqb,
      icon: Sqb,
      title: i18('import.type.sqb'),
      dataAttribute: 'sqb-file-input',
      type: ImportFrom.uzbekistanImports,
    },
    {
      id: ImportType.asiaAllianceBank,
      icon: AsiaAllianceBank,
      title: i18('import.type.asiaAllianceBank'),
      dataAttribute: 'asiaAllianceBank-file-input',
      type: ImportFrom.uzbekistanImports,
    },
  ];

  const otherCountriesImports = [
    {
      id: ImportType.wiseBusiness,
      icon: WiseBusinessIcon,
      title: i18('import.type.wiseBusiness'),
      dataAttribute: 'wiseBusiness-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType['3sMoney'],
      icon: MoneyIcon,
      title: i18('import.type.3sMoney'),
      dataAttribute: '3sMoney-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.mercuryBank,
      icon: MercuryBankIcon,
      title: i18('import.type.mercuryBank'),
      dataAttribute: 'mercuryBank-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.sebBankLatvia,
      icon: sebBankLatviaIcon,
      title: i18('import.type.sebBankLatvia'),
      dataAttribute: 'sebBankLatvia-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.unlimint,
      icon: UmlimintIcon,
      title: i18('import.type.unlimint'),
      dataAttribute: 'unlimint-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.paysera,
      icon: PayseraIcon,
      title: i18('import.type.paysera'),
      dataAttribute: 'paysera-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.bsiBank,
      icon: BSIBankIcon,
      title: i18('import.type.bsiBank'),
      dataAttribute: 'bsiBank-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.silverbirdBank,
      icon: SilverbirdBankIcon,
      title: i18('import.type.silverbirdBank'),
      dataAttribute: 'silverbirdBank-file-input',
      type: ImportFrom.otherCountriesImports,
    },
    {
      id: ImportType.otpHungary,
      icon: OtpHungaryIcon,
      title: i18('import.type.otpHungary'),
      dataAttribute: 'otpHungary-file-input',
      type: ImportFrom.otherCountriesImports,
    },
  ];

  return [
    ...services,
    ...ukraineImports,
    ...polandImports,
    ...kazakhstanImports,
    ...uzbekistanImports,
    ...otherCountriesImports,
  ];
});
