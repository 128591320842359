import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class ChaseBankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Posting Date', 1]),
            comment: this.getFirstValidCellByColumn(['Description', 2]),
            sum: this.getFirstValidCellByColumn(['Amount', 3]),
          });
        },
        caseOptions: {
          preParserConfigs: { CSVDelimiter: ',', simpleParse: true },
        },
      },
    ],
  };
}
