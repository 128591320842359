import {
  CreateBankToAccountIntegrationPayload,
  CreateIntegrationPayload,
  CreatePrivate24BusinessPayload,
  CreatePrivate24Payload,
  UpdatePrivate24BusinessCredentialsPayload,
} from './sagas.types';

export const GET_INTEGRATIONS_PENDING = 'GET_INTEGRATIONS_PENDING';
export const GET_INTEGRATIONS_SUCCESS = 'GET_INTEGRATIONS_SUCCESS';

export const GET_INTEGRATIONS_TYPES_PENDING = 'GET_INTEGRATIONS_TYPES_PENDING';
export const GET_INTEGRATIONS_TYPES_SUCCESS = 'GET_INTEGRATIONS_TYPES_SUCCESS';

export const CREATE_INTEGRATIONS_PENDING = 'CREATE_INTEGRATIONS_PENDING';
export const CREATE_INTEGRATIONS_SUCCESS = 'CREATE_INTEGRATIONS_SUCCESS';

export const CREATE_PRIVATE_24_CREDENTIALS_PENDING =
  'CREATE_PRIVATE_24_CREDENTIALS_PENDING';
export const CREATE_PRIVATE_24_CREDENTIALS_SUCCESS =
  'CREATE_PRIVATE_24_CREDENTIALS_SUCCESS';

export const CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_PENDING =
  'CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_PENDING';
export const CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_SUCCESS =
  'CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_SUCCESS';

export const CREATE_PRIVATE24_BUSINESS_INTEGRATION_PENDING =
  'CREATE_PRIVATE24_BUSINESS_INTEGRATION_PENDING';
export const CREATE_PRIVATE24_BUSINESS_INTEGRATION_SUCCESS =
  'CREATE_PRIVATE24_BUSINESS_INTEGRATION_SUCCESS';

export const GET_MONO_INITIAL_CREDENTIALS_PENDING =
  'GET_MONO_INITIAL_CREDENTIALS_PENDING';
export const GET_MONO_INITIAL_CREDENTIALS_SUCCESS =
  'GET_MONO_INITIAL_CREDENTIALS_SUCCESS';

export const GET_MONO_CREDENTIALS_BY_ID_PENDING =
  'GET_MONO_CREDENTIALS_BY_ID_PENDING';
export const GET_MONO_CREDENTIALS_BY_ID_SUCCESS =
  'GET_MONO_CREDENTIALS_BY_ID_SUCCESS';

export const GET_MONO_IS_GRANTED_PENDING = 'GET_MONO_IS_GRANTED_PENDING';
export const GET_MONO_IS_GRANTED_SUCCESS = 'GET_MONO_IS_GRANTED_SUCCESS';

export const GET_MONO_ACCOUNTS_PENDING = 'GET_MONO_ACCOUNTS_PENDING';
export const GET_MONO_ACCOUNTS_SUCCESS = 'GET_MONO_ACCOUNTS_SUCCESS';

export const SET_MONO_CARD_ID_PENDING = 'SET_MONO_CARD_ID_PENDING';
export const SET_MONO_CARD_ID_SUCCESS = 'SET_MONO_CARD_ID_SUCCESS';

export const REMOVE_INTEGRATION_PENDING = 'REMOVE_INTEGRATION_PENDING';
export const REMOVE_INTEGRATION_SUCCESS = 'REMOVE_INTEGRATION_SUCCESS';

export const UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_PENDING =
  'UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_PENDING';
export const UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_SUCCESS =
  'UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_SUCCESS';

export const CREATE_BANK_TO_ACCOUNT_INTEGRATION =
  'CREATE_BANK_TO_ACCOUNT_INTEGRATION';

export const GENERATE_TELEGRAM_API_KEY_PENDING =
  'GENERATE_TELEGRAM_API_KEY_PENDING';
export const GENERATE_TELEGRAM_API_KEY_SUCCESS =
  'GENERATE_TELEGRAM_API_KEY_SUCCESS';

export const GET_TELEGRAM_API_KEYS_PENDING = 'GET_TELEGRAM_API_KEYS_PENDING';
export const GET_TELEGRAM_API_KEYS_SUCCESS = 'GET_TELEGRAM_API_KEYS_SUCCESS';

export const SET_INTEGRATION_ACCOUNT = 'SET_INTEGRATION_ACCOUNT';
export const RESET_INTEGRATION_ACCOUNT = 'RESET_INTEGRATION_ACCOUNT';
export const SET_INTEGRATION_IN_PROGRESS = 'SET_INTEGRATION_IN_PROGRESS';
export const RESET_INTEGRATION_IN_PROGRESS = 'RESET_INTEGRATION_IN_PROGRESS';

export const GET_SERVER_IP_PENDING = 'GET_SERVER_IP_PENDING';
export const GET_SERVER_IP_SUCCESS = 'GET_SERVER_IP_SUCCESS';

export const PRIVATE_24_CREATE_CREDENTIALS_ERROR =
  'PRIVATE_24_CREATE_CREDENTIALS_ERROR';
export const PRIVATE_BUSINESS_CREATE_CREDENTIALS_ERROR =
  'PRIVATE_BUSINESS_CREATE_CREDENTIALS_ERROR';

export const SET_FONDY_INTEGRATION_ID = 'SET_FONDY_INTEGRATION_ID';

export const SET_WESTERN_BID_INTEGRATION_ID = 'SET_WESTERN_BID_INTEGRATION_ID';
export const SET_CHECK_BOX_INTEGRATION_ID = 'SET_CHECK_BOX_INTEGRATION_ID';
export const SET_HUB_STAFF_INTEGRATION_ID = 'SET_HUB_STAFF_INTEGRATION_ID';
export const SET_FAKTUROWNIA_INTEGRATION_ID = 'SET_FAKTUROWNIA_INTEGRATION_ID';
export const SET_IDOKLAD_INTEGRATION_ID = 'SET_IDOKLAD_INTEGRATION_ID';
export const SET_POSTER_INTEGRATION_ID = 'SET_POSTER_INTEGRATION_ID';
export const SET_UKRSIB_INTEGRATION_ID = 'SET_UKRSIB_INTEGRATION_ID';
export const SET_PAYONEER_CREDENTIALS_ID = 'SET_PAYONEER_CREDENTIALS_ID';

export const SET_UKRSIB_CODE = 'SET_UKRSIB_CODE';

const createIntegration = (data: CreateIntegrationPayload) => ({
  type: CREATE_INTEGRATIONS_PENDING,
  payload: data,
});

const setUkrsibClientCode = (code: string) => ({
  type: SET_UKRSIB_CODE,
  payload: { code },
});

const setPayoneerCredentialsId = (id: string) => ({
  type: SET_PAYONEER_CREDENTIALS_ID,
  payload: { id },
});

const setFondyIntegrationId = (id: string) => ({
  type: SET_FONDY_INTEGRATION_ID,
  payload: { id },
});

const setUkrsibIntegrationId = (id: string) => ({
  type: SET_UKRSIB_INTEGRATION_ID,
  payload: { id },
});

const setFacturowniaIntegrationId = (id: string) => ({
  type: SET_FAKTUROWNIA_INTEGRATION_ID,
  payload: { id },
});

const setiDokladIntegrationId = (id: string | null) => ({
  type: SET_IDOKLAD_INTEGRATION_ID,
  payload: { id },
});

const setPosterIntegrationId = (id: string | null) => ({
  type: SET_POSTER_INTEGRATION_ID,
  payload: { id },
});

const setWesternBidIntegrationId = (id: string) => ({
  type: SET_WESTERN_BID_INTEGRATION_ID,
  payload: { id },
});

const setCheckBoxIntegrationId = (id: string) => ({
  type: SET_CHECK_BOX_INTEGRATION_ID,
  payload: { id },
});

const generateTelegramNewApiKey = () => ({
  type: GENERATE_TELEGRAM_API_KEY_PENDING,
});

const getTelegramApiKeys = () => ({
  type: GET_TELEGRAM_API_KEYS_PENDING,
});

const removeIntegration = (id: string, removeAll: boolean) => ({
  type: REMOVE_INTEGRATION_PENDING,
  payload: { id, removeAll },
});

const createIntegrationToNewAccount = (
  payload: CreateBankToAccountIntegrationPayload,
) => ({
  type: CREATE_BANK_TO_ACCOUNT_INTEGRATION,
  payload,
});

const createPrivate24Credentials = (payload: CreatePrivate24Payload) => ({
  type: CREATE_PRIVATE_24_CREDENTIALS_PENDING,
  payload,
});

const getInitialMonoCredentials = () => ({
  type: GET_MONO_INITIAL_CREDENTIALS_PENDING,
});

const getMonoIsGranted = () => ({
  type: GET_MONO_IS_GRANTED_PENDING,
});

const getMonoAccounts = () => ({
  type: GET_MONO_ACCOUNTS_PENDING,
});

const setMonoCardId = (cardId: string) => ({
  type: SET_MONO_CARD_ID_PENDING,
  payload: { cardId },
});

const resetIntegrationError = () => ({
  type: PRIVATE_24_CREATE_CREDENTIALS_ERROR,
  payload: { error: false },
});

const getServerIp = () => ({
  type: GET_SERVER_IP_PENDING,
});

const createCredentials = (payload: CreatePrivate24BusinessPayload) => ({
  type: CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_PENDING,
  payload,
});

const updateCredentials = (
  payload: UpdatePrivate24BusinessCredentialsPayload,
) => ({
  type: UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_PENDING,
  payload,
});

const createAccountAndIntegration = (
  payload: CreateBankToAccountIntegrationPayload,
) => ({
  type: CREATE_BANK_TO_ACCOUNT_INTEGRATION,
  payload,
});

export default {
  getServerIp,
  setMonoCardId,
  getMonoAccounts,
  getMonoIsGranted,
  updateCredentials,
  removeIntegration,
  createCredentials,
  createIntegration,
  getTelegramApiKeys,
  setUkrsibClientCode,
  resetIntegrationError,
  setFondyIntegrationId,
  setUkrsibIntegrationId,
  setiDokladIntegrationId,
  setPosterIntegrationId,
  setPayoneerCredentialsId,
  setCheckBoxIntegrationId,
  generateTelegramNewApiKey,
  getInitialMonoCredentials,
  setWesternBidIntegrationId,
  createPrivate24Credentials,
  createAccountAndIntegration,
  setFacturowniaIntegrationId,
  createIntegrationToNewAccount,
};
