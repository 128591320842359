import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import { isNotEmpty } from 'class-validator';
import moment from 'moment';

export class CreditAgricoleImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) => value.str.includes('Період:')),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата\nоперації',
              0,
            ])?.replace(/\s/, ' '),
            counterparty: this.getFirstValidCellByColumn([
              'Контрагент\nРахунок',
              3,
            ])?.split('\n')[1],
            debit: this.getFirstValidCellByColumn(['Дебет', 6]),
            credit: this.getFirstValidCellByColumn(['Кредит', 7]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 8]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            separatorsDistanceModifier: 0,
            maxInterlineSpacing: 20,
            interlineSpacingAccuracy: 7,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word === 'Дата',
                (word) => word?.includes('Кредит'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('Кредит'),
                1,
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) =>
              value.str.includes(
                'Цей документ не підлягає будь-якому додатковому підписанню',
              ),
            ) && rawDocument.find((value) => value.str.includes('Код:')),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата\nоперації',
              0,
            ])?.replace(/\s/, ' '),
            counterparty: this.getFirstValidCellByColumn(['Рахунок', 3])?.split(
              '\n',
            )[0],
            debit: this.getFirstValidCellByColumn(['Дебет', 5]),
            credit: this.getFirstValidCellByColumn(['Кредит', 6]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 7]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            pageSeparatorsLengthPx: 13,
            separatorsDistanceModifier: 0.67,
            interlineSpacingAccuracy: 7,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word === 'Дата',
                (word) => word?.includes('Кредит'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('Кредит'),
                1,
              );
              self.deleteFromTo(
                (word) =>
                  word?.startsWith(
                    'Акціонерне товариство «КРЕДІ АГРІКОЛЬ БАНК» - вул. Пушкінська',
                  ),
                (word) => word?.includes('Кредит'),
              );
              self.deleteFromTo(
                (word) =>
                  word?.startsWith(
                    'Акціонерне товариство «КРЕДІ АГРІКОЛЬ БАНК» - вул. Пушкінська',
                  ),
                undefined,
              );
              self.defineOperation([
                (value) => /^([,\d]+\.\d{2})$/.test(value), // '1,000.00'
              ]);
            },
          },
        },
      },
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) =>
              value.str.includes('Директор з Проксіміті банкінгу та мережі'),
            ),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата\nоперації',
              0,
            ])?.replace(/\s/, ' '),
            counterparty: this.getFirstValidCellByColumn(['Рахунок', 3])?.split(
              '\n',
            )[0],
            debit: this.getFirstValidCellByColumn(['Дебет', 5]),
            credit: this.getFirstValidCellByColumn(['Кредит', 6]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 7]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            pageSeparatorsLengthPx: 3,
            separatorsDistanceModifier: 0.7,
            interlineSpacingAccuracy: 40,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word === 'Дата',
                (word) => word?.includes('Кредит'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('Кредит'),
                1,
              );
              self.deleteFromTo(
                (word) =>
                  word?.startsWith(
                    'Акціонерне товариство «КРЕДІ АГРІКОЛЬ БАНК» - вул. Пушкінська',
                  ),
                (word) => word?.includes('Кредит'),
              );
              self.defineOperation([
                (value) => /^([,\d]+\.\d{2})$/.test(value), // '1,000.00'
              ]);
            },
          },
        },
      },
      // case 9
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return rawDocument[0]?.str === 'АТ «КРЕДІ АГРІКОЛЬ БАНК»';
        },
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = [];
          importDocument.slice(1).forEach((el, i) => {
            if (i === 0) {
              body.push(el);
              return;
            }
            const prevComments = body[i - 1][2].split('\n');
            const currentComments = el[2].split('\n');
            if (prevComments.length && currentComments.length) {
              if (
                prevComments[prevComments.length - 1] === currentComments[0]
              ) {
                currentComments.shift();
                el[2] = currentComments.join('\n');
              }
            }
            body.push(el);
          });
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата операції', 0]),
            currency: this.getFirstValidCellByColumn(['Валюта\nоперації', 3]),
            sum: this.getFirstValidCellByColumn(['Сума в\nвалюті\nрахунка', 6]),
            comment: this.getFirstValidCellByColumn(['Опис операції', 2]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            pageSeparatorsLengthPx: 3,
            separatorsDistanceModifier: 0.2,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => word === 'Сума в' && etc?.nextWord === 'Сума в',
                (word, etc) =>
                  word?.includes('рахунка') && etc?.prevWord === 'операції',
              );
              self.deleteFromTo(
                (word, etc) => word === 'АТ «КРЕДІ АГРІКОЛЬ БАНК»',
                (word, etc) =>
                  word?.includes(' (міжнародні)') &&
                  word?.includes(' (по Україні), '),
              );
              self.deleteFromTo(
                (word, etc) => etc?.nextWord === 'Дата формування:',
                (word, etc) =>
                  etc?.prevWord === 'Незнижувальний залишок:' &&
                  word &&
                  !isNaN(Number(word.replace(',', '.'))),
                1,
              );
              self.defineOperation([
                (value) => /^-?\d+(,\d{2})$/.test(value), // '1,000.00'
              ]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата\nоперації',
              0,
            ])?.replace(/\s/, ' '),
            counterparty: this.getFirstValidCellByColumn(['Рахунок', 3])
              ?.replace(/[A-Z]{2}[\d\s]+\n/g, '')
              .split('\n')[0],
            debit:
              this.getFirstValidCellByColumn(['Дебет']) ||
              this.getFirstValidCellByColumn(['Сума']),
            credit: this.getFirstValidCellByColumn(['Кредит']),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 7]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            pageSeparatorsLengthPx: 3,
            separatorsDistanceModifier: 0.83,
            interlineSpacingAccuracy: 40,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word === 'Дата',
                (word) => word?.includes('Кредит'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('Кредит'),
                1,
              );
              self.deleteFromTo(
                (word) =>
                  word?.startsWith(
                    'Акціонерне товариство «КРЕДІ АГРІКОЛЬ БАНК» - вул. Пушкінська',
                  ),
                (word) => word?.includes('Кредит'),
              );
              self.defineOperation([
                (value) => /^([,\d]+\.\d{2})$/.test(value), // '1,000.00'
              ]);
            },
          },
        },
      },
    ],
  };
}
