import i18next from 'i18next';
import { toast } from 'react-toastify';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  ONBOARDING_STATUS,
  ONBOARDING_SUB_STEPS,
} from '@finmap/core-constants';

import MsgToast from '../../components/MsgToast';
import { INTEGRATION_TOAST_ID } from '../../constants';
import { TAction } from '../../sagas/types';
import { startIntegrationInterval } from '../../sagas/utils';
import accountApi from '../../services/accounts';
import api from '../../services/bankIntegrations/integrations';
import { showError } from '../../utils/showError';
import {
  GET_LOG_ACCOUNTS_PENDING,
  GET_LOG_ACCOUNTS_SUCCESS,
  GET_SETTINGS_ACCOUNTS_PENDING,
} from '../accounts/actions';
import { onboardingActionTypes } from '../onboardingV2/actions';
import { selectIntegrationStep } from '../onboardingV2/selectors';
import {
  CREATE_BANK_TO_ACCOUNT_INTEGRATION,
  CREATE_INTEGRATIONS_PENDING,
  CREATE_INTEGRATIONS_SUCCESS,
  CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_PENDING,
  CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_SUCCESS,
  CREATE_PRIVATE_24_CREDENTIALS_PENDING,
  CREATE_PRIVATE_24_CREDENTIALS_SUCCESS,
  GET_INTEGRATIONS_PENDING,
  GET_INTEGRATIONS_SUCCESS,
  GET_INTEGRATIONS_TYPES_PENDING,
  GET_INTEGRATIONS_TYPES_SUCCESS,
  GET_MONO_ACCOUNTS_PENDING,
  GET_MONO_ACCOUNTS_SUCCESS,
  GET_MONO_CREDENTIALS_BY_ID_PENDING,
  GET_MONO_CREDENTIALS_BY_ID_SUCCESS,
  GET_MONO_INITIAL_CREDENTIALS_PENDING,
  GET_MONO_INITIAL_CREDENTIALS_SUCCESS,
  GET_MONO_IS_GRANTED_PENDING,
  GET_MONO_IS_GRANTED_SUCCESS,
  GET_SERVER_IP_PENDING,
  GET_SERVER_IP_SUCCESS,
  PRIVATE_24_CREATE_CREDENTIALS_ERROR,
  REMOVE_INTEGRATION_PENDING,
  REMOVE_INTEGRATION_SUCCESS,
  RESET_INTEGRATION_IN_PROGRESS,
  SET_INTEGRATION_ACCOUNT,
  SET_INTEGRATION_IN_PROGRESS,
  SET_MONO_CARD_ID_PENDING,
  SET_MONO_CARD_ID_SUCCESS,
  UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_PENDING,
  UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_SUCCESS,
} from './actions';
import {
  CreateBankToAccountIntegrationPayload,
  CreateIntegrationPayload,
  CreatePrivate24BusinessPayload,
  CreatePrivate24Payload,
  RemoveIntegrationPayload,
  SetMonoCardIdPayload,
  UpdatePrivate24BusinessCredentialsPayload,
} from './sagas.types';
import {
  getCheckBoxIntegrationId,
  getFacturowniaIntegrationId,
  getFondyIntegrationId,
  getiDokladIntegrationId,
  getMonoAccounts as selectMonoAccounts,
  getNordigenIntegrationId,
  getBelvoIntegrationId,
  getPayoneerCredentialsId,
  getPosterIntegrationId,
  getPumbIntegrationId,
  getSaltedgeIntegrationId,
  getUkrsibIntegrationId,
  getWesternBidIntegrationId,
  selectLastIntegrationCredentials,
  selectMonoInitialCredentials,
  selectPrivat24BusinessIntegrationCredentials,
} from './selectors';
import {
  InitialMonoCredentials,
  IntegrationTypeId,
  MonoAccount,
  Privat24BusinessIntegrationCredential,
} from './types';

export function* getIntegrations() {
  try {
    const { data } = yield call(api.getIntegrations);

    yield put({ type: GET_INTEGRATIONS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getIntegrationsTypes() {
  try {
    const { data } = yield call(api.getIntegrationsTypes);

    yield put({ type: GET_INTEGRATIONS_TYPES_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* createPrivate24Credentials(
  action: TAction<CreatePrivate24Payload>,
) {
  try {
    const { data } = yield call(api.private24CreateCredentials, action.payload);

    if (data.error) {
      yield put({
        type: PRIVATE_24_CREATE_CREDENTIALS_ERROR,
        payload: { error: true },
      });
    } else {
      yield put({
        type: CREATE_PRIVATE_24_CREDENTIALS_SUCCESS,
        payload: { data },
      });
    }
  } catch (error) {
    showError(error);
  }
}

export function* createPrivate24BusinessCredentials(
  action: TAction<CreatePrivate24BusinessPayload>,
) {
  try {
    const { data } = yield call(
      api.private24BusinessCreateCredentials,
      action.payload,
    );

    yield put({
      type: CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: PRIVATE_24_CREATE_CREDENTIALS_ERROR,
      payload: { error: true },
    });

    showError(error);
  }
}

export function* createIntegration(
  action: TAction<CreateIntegrationPayload>,
): SagaIterator {
  try {
    const {
      typeId,
      isFondyQ2,
      startDate,
      accountId,
      bankName: saltedgeBankName,
      facebookContext,
    } = action.payload;

    let integrationCredentialsId;
    let amount = 0;
    let bankName;

    const integrationStep = yield select(selectIntegrationStep);

    yield put({
      type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
      payload: {
        ...integrationStep,
        stepSubCategory: ONBOARDING_SUB_STEPS.INTEGRATION_LOG,
        status: ONBOARDING_STATUS.ACTIVE,
      },
    });

    yield put({ type: SET_INTEGRATION_IN_PROGRESS });

    const fondyIntegrationId = isFondyQ2
      ? IntegrationTypeId.FondyQ2
      : IntegrationTypeId.Fondy;

    if (typeId === IntegrationTypeId.MonoPersonal) {
      const credentials = yield select(selectMonoInitialCredentials);
      const monoAccounts = yield select(selectMonoAccounts);
      const integrationCredential = yield select(
        selectLastIntegrationCredentials,
      );
      const account: MonoAccount = monoAccounts.find(
        (acc: MonoAccount) => acc.id === integrationCredential.cardId,
      );

      if (account) {
        amount = account.balance;
      }

      integrationCredentialsId = credentials.item._id;
      bankName = 'monobank';
    } else if (typeId === IntegrationTypeId.Private24Personal) {
      const credentials = yield select(selectLastIntegrationCredentials);

      integrationCredentialsId = credentials._id;
      amount = credentials.balance;
      bankName = 'Privat24';
    } else if (typeId === IntegrationTypeId.Private24Business) {
      const credentials = yield select(selectLastIntegrationCredentials);
      const privateCredentials: Privat24BusinessIntegrationCredential =
        yield select(selectPrivat24BusinessIntegrationCredentials);

      integrationCredentialsId = credentials._id;

      const integrationAccount = privateCredentials.accounts.find(
        (el) => el.acc === credentials.acc,
      );

      amount = Number(integrationAccount?.balance) || 0;
      bankName = 'Privat24';
    } else if (typeId === IntegrationTypeId.Nordigen) {
      integrationCredentialsId = yield select(getNordigenIntegrationId);
    } else if (typeId === IntegrationTypeId.Belvo) {
      integrationCredentialsId = yield select(getBelvoIntegrationId);
    } else if (typeId === IntegrationTypeId.Saltedge) {
      integrationCredentialsId = yield select(getSaltedgeIntegrationId);

      bankName = saltedgeBankName;
    } else if (typeId === IntegrationTypeId.Pumb) {
      integrationCredentialsId = yield select(getPumbIntegrationId);

      bankName = 'Pumb';
    } else if (typeId === fondyIntegrationId) {
      integrationCredentialsId = yield select(getFondyIntegrationId);

      bankName = 'Fondy';
    } else if (typeId === IntegrationTypeId.WesternBid) {
      integrationCredentialsId = yield select(getWesternBidIntegrationId);

      bankName = 'WesternBid';
    } else if (typeId === IntegrationTypeId.CheckBox) {
      integrationCredentialsId = yield select(getCheckBoxIntegrationId);

      bankName = 'CheckBox';
    } else if (typeId === IntegrationTypeId.Facturownia) {
      integrationCredentialsId = yield select(getFacturowniaIntegrationId);

      bankName = 'Facturownia';
    } else if (typeId === IntegrationTypeId.iDoklad) {
      integrationCredentialsId = yield select(getiDokladIntegrationId);

      bankName = 'iDoklad';
    } else if (typeId === IntegrationTypeId.Poster) {
      integrationCredentialsId = yield select(getPosterIntegrationId);
      bankName = 'Poster';
    } else if (typeId === IntegrationTypeId.Payoneer) {
      integrationCredentialsId = yield select(getPayoneerCredentialsId);

      bankName = 'Payoneer';
    } else if (typeId === IntegrationTypeId.UkrSib) {
      integrationCredentialsId = yield select(getUkrsibIntegrationId);

      bankName = 'Ukrsib';
    }

    const { data } = yield call(api.createIntegration, {
      typeId,
      startDate,
      accountId,
      integrationCredentialsId,
    });

    const msgText = i18next.t('toasts.integrationInProgress', {
      postProcess: 'sprintf',
      sprintf: [0, '%', bankName],
    });

    toast(() => <MsgToast title={msgText} />, {
      toastId: `${INTEGRATION_TOAST_ID}_${accountId}`,
      autoClose: false,
      hideProgressBar: true,
    });

    startIntegrationInterval(accountId, bankName || '', integrationStep);

    const payload = {
      id: accountId,
      amount,
    };

    yield put({
      type: SET_INTEGRATION_ACCOUNT,
      payload: { data: payload },
    });

    yield put({ type: CREATE_INTEGRATIONS_SUCCESS, payload: { data } });

    if (facebookContext) {
      facebookContext.sendData();
    }
    yield put({ type: GET_SETTINGS_ACCOUNTS_PENDING });

    if (typeId === IntegrationTypeId.Private24Business) {
      yield put({
        type: CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (error) {
    toast(i18next.t('integrations.error.createError'));

    showError(error);
  }
}

export function* getMonoCredentialsById(): SagaIterator {
  try {
    const monoId = yield select();

    const { data } = yield call(api.getMonoCredentialsById, monoId);

    yield put({ type: GET_MONO_CREDENTIALS_BY_ID_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getMonoInitialCredentials(): SagaIterator {
  try {
    const { data } = yield call(api.getMonoInitialCredentials);

    yield put({
      type: GET_MONO_INITIAL_CREDENTIALS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* getMonoIsGranted(): SagaIterator {
  try {
    const monoInitialCredentials = yield select(selectMonoInitialCredentials);

    if (monoInitialCredentials) {
      const { data } = yield call(
        api.getMonoCredentialsById,
        monoInitialCredentials.item._id,
      );

      yield put({ type: GET_MONO_IS_GRANTED_SUCCESS, payload: { data } });
    }
  } catch (error) {
    showError(error);
  }
}

export function* getMonoAccounts() {
  try {
    const monoInitialCredentials: InitialMonoCredentials = yield select(
      selectMonoInitialCredentials,
    );

    if (monoInitialCredentials) {
      const { data } = yield call(
        api.getMonoAccounts,
        monoInitialCredentials.item._id,
      );

      yield put({ type: GET_MONO_ACCOUNTS_SUCCESS, payload: { data } });
    }
  } catch (error) {
    showError(error);
  }
}

export function* updatePrivate24BusinessIntegration(
  action: TAction<UpdatePrivate24BusinessCredentialsPayload>,
) {
  try {
    yield put({ type: SET_INTEGRATION_IN_PROGRESS });

    const credentials: Privat24BusinessIntegrationCredential = yield select(
      selectPrivat24BusinessIntegrationCredentials,
    );

    const { acc, autoClientId, token } = action.payload;

    const { data } = yield call(
      api.updatePrivate24BusinessCredentials,
      autoClientId,
      token,
      credentials._id,
      acc,
    );

    yield put({
      type: UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_SUCCESS,
      payload: { data },
    });

    yield put({ type: RESET_INTEGRATION_IN_PROGRESS });
  } catch (error) {
    showError(error);
  }
}

export function* setMonoCardId(action: TAction<SetMonoCardIdPayload>) {
  try {
    const { cardId } = action.payload;
    const monoInitialCredentials: InitialMonoCredentials = yield select(
      selectMonoInitialCredentials,
    );

    if (cardId && monoInitialCredentials) {
      const { data } = yield call(
        api.updateMonoCredentialsById,
        monoInitialCredentials.item._id,
        cardId,
      );

      yield put({ type: SET_MONO_CARD_ID_SUCCESS, payload: { data } });
    }
  } catch (error) {
    showError(error);
  }
}

export function* removeIntegration(action: TAction<RemoveIntegrationPayload>) {
  try {
    const { id, removeAll } = action.payload;
    const { data } = yield call(api.removeIntegration, id, removeAll);

    yield put({ type: REMOVE_INTEGRATION_SUCCESS, payload: { data } });
    yield put({ type: GET_SETTINGS_ACCOUNTS_PENDING });
    yield put({ type: GET_LOG_ACCOUNTS_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* createBankToNewAccountIntegration(
  action: TAction<CreateBankToAccountIntegrationPayload>,
) {
  try {
    const { name, currencyId, typeId, startDate } = action.payload;

    const { data } = yield call(accountApi.createAccount, currencyId, name);

    yield put({
      type: GET_LOG_ACCOUNTS_SUCCESS,
      payload: { data: data.accounts },
    });

    yield put({ type: GET_SETTINGS_ACCOUNTS_PENDING });

    const { _id } = data.newAccount;

    const payload: Omit<CreateIntegrationPayload, 'facebookContext'> = {
      accountId: _id,
      startDate,
      typeId,
    };

    yield put({ type: CREATE_INTEGRATIONS_PENDING, payload });
  } catch (error) {
    showError(error);
  }
}

function* getServerIp() {
  try {
    const { data } = yield call(api.getServerIp);

    yield put({ type: GET_SERVER_IP_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export default function common() {
  return all([
    takeLatest(GET_INTEGRATIONS_PENDING, getIntegrations),
    takeLatest(GET_INTEGRATIONS_TYPES_PENDING, getIntegrationsTypes),
    takeLatest(CREATE_INTEGRATIONS_PENDING, createIntegration),
    takeLatest(
      CREATE_PRIVATE_24_CREDENTIALS_PENDING,
      createPrivate24Credentials,
    ),
    takeLatest(
      CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_PENDING,
      createPrivate24BusinessCredentials,
    ),
    takeLatest(GET_MONO_INITIAL_CREDENTIALS_PENDING, getMonoInitialCredentials),
    takeLatest(GET_MONO_IS_GRANTED_PENDING, getMonoIsGranted),
    takeLatest(GET_MONO_CREDENTIALS_BY_ID_PENDING, getMonoCredentialsById),
    takeLatest(GET_MONO_ACCOUNTS_PENDING, getMonoAccounts),
    takeLatest(SET_MONO_CARD_ID_PENDING, setMonoCardId),
    takeLatest(REMOVE_INTEGRATION_PENDING, removeIntegration),
    takeLatest(
      CREATE_BANK_TO_ACCOUNT_INTEGRATION,
      createBankToNewAccountIntegration,
    ),
    takeLatest(
      UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_PENDING,
      updatePrivate24BusinessIntegration,
    ),
    takeLatest(GET_SERVER_IP_PENDING, getServerIp),
  ]);
}
