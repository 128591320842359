import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(480)]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  text: {
    fontSize: 28,
    lineHeight: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  margin: {
    marginBottom: 48,
  },
  button: {
    width: 289,
    opacity: 0,
    visibility: 'hidden',
    transition: 'visibility 0.5s linear,opacity 0.5s linear',
  },
  showButton: {
    opacity: 1,
    visibility: 'visible',
  },
}));
