import { Typography } from '@material-ui/core';
import moment from 'moment';
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  SHOW_FACTUROWNIA_INTEGRATION,
  SHOW_ING_SALTEDGE,
  USE_ONLY_NORDIGEN_SERVICE,
} from '../../../constants/featureToggles/integrationsFeatureToggles';
import { Routes } from '../../../constants/routes';
import useUnleash from '../../../hooks/useUnleash';
import nordigenApi from '../../../services/bankIntegrations/nordigen';
import saltedgeApi from '../../../services/bankIntegrations/saltedge';
import Storages, { StorageKey } from '../../../services/Storages';
import { selectCountIntegrations } from '../../../store/accounts/selectors';
import { getUserGeoData } from '../../../store/common/selectors';
import { selectCurrentCompany } from '../../../store/company/selectors';
import {
  BelvoBankType,
  NordigenBankType,
  NordigenCredentials,
  NordigenCredentialsResponse,
  SaltedgeBankType,
  SaltedgeCredentials,
  SaltedgeCredentialsResponse,
} from '../../../store/integrations/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import { showError } from '../../../utils/showError';
import { isLite } from '../../../utils/subscriptions';
import AccountAndCalendarDialog from './AccountAndCalendarDialog';
import { nordigenBanks } from './constants';
import NordigenInfoDialog from './NordigenInfoDialog';
import SaltedgeInfoDialog from './SaltedgeInfoDialog';
import BelvoCredsDialog from '../BelvoCredsDialog';
import BelvoAccountsDialog from '../../AccountsDialog/BelvoAccountsDialog';
import { Props } from './ServiceProviderBanks.types';
import { useStyles } from './styles';

function ServiceProviderBanks(props: Props) {
  const {
    data,
    countryCode,
    internalAccountId,
    onOpenSubscriptionDialog,
    onSetShowOnboardingTooltip,
    onCloseIntegrationsDialog,
  } = props;
  const classes = useStyles();

  const showFacturowniaIntegration = useUnleash(SHOW_FACTUROWNIA_INTEGRATION);
  const userGeoData = useSelector(getUserGeoData);

  let bankList =
    countryCode === 'CZ'
      ? data.map((el) =>
          el.name === 'Ceska Sporitelna'
            ? { ...el, name: 'Česká Spořitelna' }
            : el,
        )
      : data;

  const fakturowniaBank = bankList.find((el) => el.name === 'Fakturownia');

  bankList =
    countryCode === 'PL'
      ? data.filter((el) => el.name !== 'Fakturownia')
      : bankList;

  if (fakturowniaBank && showFacturowniaIntegration) {
    bankList = [fakturowniaBank, ...bankList];
  }

  const company = useSelector(selectCurrentCompany);
  const countIntegrations = useSelector(selectCountIntegrations);

  const [currentBank, setCurrentBank] = useState<
    SaltedgeBankType | NordigenBankType | BelvoBankType | null
  >(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSaltadgeInfoDialog, setShowSaltedgeInfoDialog] = useState(false);
  const [showNordigenInfoDialog, setShowNordigenInfoDialog] = useState(false);
  const [saltedgeUrl, setSaltedgeUrl] = useState('');
  const [nordigenUrl, setNordigenUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sendFromDateProp, setSendFromDateProp] = useState(true);
  const [bankConnectionId, setBankConnectionId] = useState('');

  const [showBelvoInfoDialog, setShowBelvoInfoDialog] = useState(false);
  const [showBelvoAccounstDialog, setShowBelvoAccountsDialog] = useState(false);
  const [belvoIntegrationId, setBelvoIntegrationId] = useState<string | null>(
    null,
  );
  const [belvoAccountName, setBelvoAccountName] = useState<string | null>(null);
  const [belvoStartDate, setBelvoStartDate] = useState<number | null>(null);

  const showINGsaltedge = useUnleash(SHOW_ING_SALTEDGE);
  const useOnlyNordigenService = useUnleash(USE_ONLY_NORDIGEN_SERVICE);

  const useIMGBankForPolandBySaltagde = countryCode === 'PL' && showINGsaltedge;

  const banksList = useMemo(
    () =>
      useIMGBankForPolandBySaltagde
        ? nordigenBanks.filter((el) => el !== 'ING')
        : nordigenBanks,
    [useIMGBankForPolandBySaltagde],
  );

  const handleOnCloseBelvoAccountsListDialog = useCallback(() => {
    setBelvoIntegrationId(null);
    setBelvoStartDate(null);
    setBelvoAccountName(null);
    setShowBelvoAccountsDialog(false);
    setShowCalendar(false);
  }, []);

  const handleOnSuccessBelvoAccountsListDialog = useCallback(() => {
    setBelvoIntegrationId(null);
    setBelvoStartDate(null);
    setBelvoAccountName(null);
    setShowBelvoAccountsDialog(false);
    setShowCalendar(false);
    if (onCloseIntegrationsDialog) {
      onCloseIntegrationsDialog();
    }
  }, [onCloseIntegrationsDialog]);

  const handleOnSuccessCreatedBelvoCreds = useCallback(
    (integrationId: string) => {
      setBelvoIntegrationId(integrationId);
      setShowBelvoInfoDialog(false);
      setShowBelvoAccountsDialog(true);
    },
    [],
  );

  const handleCloseSaltedgeInfoDialog = useCallback(() => {
    setShowSaltedgeInfoDialog(false);
  }, []);

  const handleCloseNordigenInfoDialog = useCallback(() => {
    setShowNordigenInfoDialog(false);
  }, []);

  const handleCloseBelvoInfoDialog = useCallback(() => {
    setShowBelvoInfoDialog(false);
  }, []);

  const handleCloseCalendar = useCallback(() => {
    setShowCalendar(false);

    if (onSetShowOnboardingTooltip) {
      onSetShowOnboardingTooltip(true);
    }
  }, [onSetShowOnboardingTooltip]);

  const handleSetupSaltedgeBank = useCallback(
    async (event: SyntheticEvent<HTMLDivElement>) => {
      const { bankid } = event.currentTarget.dataset;
      if (isLite(company) && countIntegrations === 2) {
        onOpenSubscriptionDialog();

        return;
      }
      const bank = bankList.find((el) => el.id.toString() === bankid);
      if (bank) {
        if (bank.customHandler && bank.integrationTypeId) {
          bank.customHandler(bank.integrationTypeId);
        } else {
          setCurrentBank(bank);
          setShowCalendar(true);

          if (onSetShowOnboardingTooltip) {
            onSetShowOnboardingTooltip(false);
          }
        }
      }
    },
    [
      company,
      bankList,
      countIntegrations,
      onOpenSubscriptionDialog,
      onSetShowOnboardingTooltip,
    ],
  );

  const startSaltedgeIntegration = useCallback(
    async (accountName: string, date: number) => {
      if (!currentBank) {
        return;
      }

      const payload: SaltedgeCredentials = {
        takingDaysNumber: (currentBank as SaltedgeBankType)
          .max_consent_days as SaltedgeBankType['max_consent_days'],
        redirectURL: window.location.origin,
        providerCode: currentBank.code as SaltedgeBankType['code'],
      };

      if (sendFromDateProp) {
        payload.from_date = date;
      }

      try {
        setLoading(true);

        const { data: saltedgeCredentials } = await saltedgeApi.getCredentials(
          payload,
        );
        const { acceptingLink, integrationId, connectionId } =
          saltedgeCredentials as SaltedgeCredentialsResponse;

        Storages.put(StorageKey.saltedge, {
          integrationId,
          accountName,
          accountId: internalAccountId,
          startDate: date,
          bankName: currentBank.name,
          connectionId,
        });

        setLoading(false);

        if (connectionId) {
          setBankConnectionId(connectionId);
        }

        setSaltedgeUrl(acceptingLink);

        setShowSaltedgeInfoDialog(true);
      } catch (error) {
        // @ts-ignore
        if (error?.response?.saltageError === 'Date is out of consent range.') {
          setSendFromDateProp(false);
        }

        setLoading(false);
        // @ts-ignore
        setErrorMessage(error?.response?.message);
        showError(error);
      }
    },
    [currentBank, internalAccountId, sendFromDateProp],
  );

  const startBelvoIntegration = useCallback(
    (accountName: string, date: number) => {
      if (!currentBank) {
        return;
      }

      setShowBelvoInfoDialog(true);
      setBelvoAccountName(accountName);
      setBelvoStartDate(date);
    },
    [
      currentBank,
      setShowBelvoInfoDialog,
      setBelvoAccountName,
      setBelvoStartDate,
    ],
  );

  const startIntegration = useCallback(
    async (accountName: string, date: number) => {
      if (!currentBank) {
        return;
      }

      const takingDaysNumber =
        moment
          .utc()
          .endOf('day')
          .diff(moment.utc(date).startOf('day'), 'days') + 1;

      if (
        (banksList.includes(currentBank.name) &&
          !(currentBank as NordigenBankType).logo_url) ||
        useOnlyNordigenService
      ) {
        const payload: NordigenCredentials = {
          takingDaysNumber,
          bankId: currentBank.id as string,
          countryCode,
          redirectURL: window.location.origin,
        };

        try {
          setLoading(true);

          const { data: nordigenCredentials } =
            await nordigenApi.getCredentials(payload);

          const { acceptingLink, integrationId, fromHistory } =
            nordigenCredentials as NordigenCredentialsResponse;

          const { data: accountsData } =
            await nordigenApi.getIntegrationAccounts(integrationId);

          Storages.put(StorageKey.nordigen, {
            integrationId,
            accountName,
            accountId: internalAccountId,
            startDate: date,
            bankName: currentBank.name,
          });

          if (fromHistory) {
            window.location.href = Routes.LOG;
          } else if (accountsData.status) {
            setLoading(false);

            setNordigenUrl(acceptingLink);
            setShowNordigenInfoDialog(true);
          }
        } catch (error) {
          setLoading(false);
          showError(error);
        }
      } else {
        await startSaltedgeIntegration(accountName, date);
      }
    },
    [
      banksList,
      countryCode,
      currentBank,
      internalAccountId,
      useOnlyNordigenService,
      startSaltedgeIntegration,
    ],
  );

  const onRedirectToSaltedAccount = useCallback(() => {
    if (bankConnectionId) {
      window.location.href = Routes.LOG;

      return;
    }

    window.location.href = saltedgeUrl;
  }, [saltedgeUrl, bankConnectionId]);

  const onRedirectToNordigenAccount = useCallback(() => {
    window.location.href = nordigenUrl;
  }, [nordigenUrl]);

  const handleShowInfoDialog = useCallback(
    (accName: string, date: number) => {
      setErrorMessage('');

      if (
        currentBank &&
        !(
          (
            banksList.includes(currentBank?.name) &&
            !(currentBank as NordigenBankType).logo_url
          ) // saltage logo link key (not nordigen)
        )
      ) {
        if (!useOnlyNordigenService) {
          startSaltedgeIntegration(accName, date);
        } else {
          if (currentBank.isBelvo) {
            startBelvoIntegration(accName, date);
          } else {
            startIntegration(accName, date);
          }
        }
      } else {
        startIntegration(accName, date);
      }
    },
    [
      banksList,
      currentBank,
      startIntegration,
      useOnlyNordigenService,
      startSaltedgeIntegration,
      startBelvoIntegration,
    ],
  );

  const minSaltedgeCalendarDate = new Date(
    moment
      .utc()
      .subtract(
        (currentBank as SaltedgeBankType | NordigenBankType)?.max_consent_days,
        'days',
      )
      .valueOf() + getTimeOffset(),
  );

  const minNordigenCalendarDate = new Date(
    moment
      .utc()
      .subtract(
        (currentBank as SaltedgeBankType | NordigenBankType)
          ?.maxTakingDaysNumber,
        'days',
      )
      .valueOf() + getTimeOffset(),
  );

  let minDate =
    currentBank && banksList.includes(currentBank.name) && !currentBank.logo_url
      ? minNordigenCalendarDate
      : minSaltedgeCalendarDate;

  if (useOnlyNordigenService) {
    minDate = minNordigenCalendarDate;
  }

  return (
    <>
      {bankList.map((bank) => (
        <div
          key={bank.id}
          className={classes.button}
          id={`${bank.name}_my_accounts`}
          data-bankid={bank.id}
          onClick={handleSetupSaltedgeBank}
        >
          <div className={classes.flex}>
            <img
              alt={`${bank.id}`}
              src={
                userGeoData?.country === 'UA' && !bank?.isBelvo
                  ? `/nordigenIcons/${bank.id}.png`
                  : // @ts-ignore
                    bank.logo_url || bank.logoLink
              }
              className={classes.icon}
            />
            <Typography
              id={`${bank.name}_my_accounts_text`}
              className={classes.bankText}
            >
              {bank.name}
            </Typography>
          </div>
        </div>
      ))}
      {showCalendar && (
        <AccountAndCalendarDialog
          onClose={handleCloseCalendar}
          onChange={handleShowInfoDialog}
          minDate={minDate}
          showCreateAccountRow={!internalAccountId}
          loading={loading}
          externalDateError={errorMessage}
        />
      )}
      {showSaltadgeInfoDialog && currentBank && (
        <SaltedgeInfoDialog
          onClose={handleCloseSaltedgeInfoDialog}
          onChange={onRedirectToSaltedAccount}
          bank={currentBank as SaltedgeBankType}
          loading={loading}
        />
      )}
      {showNordigenInfoDialog && currentBank && (
        <NordigenInfoDialog
          onClose={handleCloseNordigenInfoDialog}
          onChange={onRedirectToNordigenAccount}
          bank={currentBank as NordigenBankType}
          loading={loading}
        />
      )}
      {showBelvoInfoDialog && currentBank && (
        <BelvoCredsDialog
          isOpened={showBelvoInfoDialog}
          onClose={handleCloseBelvoInfoDialog}
          bank={currentBank as BelvoBankType}
          onSuccess={handleOnSuccessCreatedBelvoCreds}
        />
      )}
      {showBelvoAccounstDialog &&
        belvoIntegrationId &&
        belvoStartDate &&
        belvoAccountName &&
        currentBank && (
          <BelvoAccountsDialog
            onCloseAccountsListDialog={handleOnCloseBelvoAccountsListDialog}
            onSuccess={handleOnSuccessBelvoAccountsListDialog}
            belvoIntegration={{
              integrationId: belvoIntegrationId,
              accountName: belvoAccountName,
              bankName: currentBank.name,
              startDate: belvoStartDate,
            }}
          />
        )}
    </>
  );
}

export default React.memo(ServiceProviderBanks);
