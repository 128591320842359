import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import { OPERATION_TYPES } from '@finmap/core-constants';
export class BSIBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            let sum, currency, type;
            const sumAndCurrencyArr = this.getFirstValidCellByColumn([
              'Importo',
              2,
            ])?.split(' ');
            if (sumAndCurrencyArr?.length) {
              sum = sumAndCurrencyArr.slice(0, -1).join('');
              currency = sumAndCurrencyArr[sumAndCurrencyArr.length - 1];
            }
            const externalType = this.getFirstValidCellByColumn([
              'Segno',
              3,
            ])?.replace(/\s/, '');
            if (externalType === '+') type = OPERATION_TYPES.INC;
            if (externalType === '-') type = OPERATION_TYPES.CON;
            return {
              date: this.getFirstValidCellByColumn(['Valuta', 1]),
              sum,
              comment: this.getFirstValidCellByColumn(['Descrizione', 4]),
              currency,
              type,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'EUR',
          preParserConfigs: {
            pageSeparatorsLengthPx: 14,
            interlineSpacingAccuracy: 5,
            separatorsDistanceModifier: 0.6,
            maxInterlineSpacingPx: 5,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Data Mov.') &&
                  etc?.nextWord?.includes('Valuta'),
                (word, etc) =>
                  word?.includes('Descrizione') &&
                  etc?.prevWord?.includes('Segno'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('Descrizione') &&
                  etc?.prevWord?.includes('Segno'),
                1,
              );
              self.deleteFromTo(
                (word, etc) => word?.startsWith('Totale Avere'),
                undefined,
              );
              self.defineOperation([
                (value) => moment(value, 'DD/MM/YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
  };
}
