import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class INGBankPLNImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return (
            rawDocument?.length &&
            rawDocument[0].str.includes('Lista transakcji')
          );
        },
        proceedCase: (importDocument) => {
          console.log(importDocument);
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            let sum, currency;
            const sumAndCurrencyArr = this.getFirstValidCellByColumn([
              'Kwota',
              4,
            ])?.split(/\s/);
            const dates = this.getFirstValidCellByColumn([
              'Data transakcji\n/ data księgow.',
              0,
            ])
              ?.trim()
              ?.split(/\s/);
            if (sumAndCurrencyArr?.length) {
              if (sumAndCurrencyArr[0].includes('blokada:')) {
                sum = sumAndCurrencyArr[1];
                currency = sumAndCurrencyArr[2];
              } else {
                sum = sumAndCurrencyArr[sumAndCurrencyArr.length - 2];
                currency = sumAndCurrencyArr[sumAndCurrencyArr.length - 1];
              }
            }
            return {
              date: dates[0],
              comment: this.getFirstValidCellByColumn(['Tytuł', 2]),
              sum,
              currency,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: {
            maxInterlineSpacingPx: 40,
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Data transakcji') &&
                  etc.nextWord?.includes('Dane kontrahenta'),
                (word, etc) =>
                  etc.prevWord?.includes('po') && word?.includes('transakcji'),
              );
              self.defineOperation([
                (value, { fullElement }) =>
                  moment(value, 'DD.MM.YYYY', true).isValid() &&
                  !fullElement?.hasEOL,
              ]);
            },
          },
        },
      },
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return (
            rawDocument?.length &&
            rawDocument[0].str.includes('Wyciąg z rachunku')
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          const currency = this.getFromStore('currency')?.toString()?.trim();
          return (): ImportResultItemMask => {
            const dateArr = this.getFirstValidCellByColumn(['Data', 0])?.split(
              '\n',
            );
            const counterpartyArr = this.getFirstValidCellByColumn([
              'Dane Kontrahenta',
              1,
            ])?.split('\n');
            return {
              date: dateArr?.length ? dateArr[0] : undefined,
              counterparty:
                counterpartyArr?.length > 1 ? counterpartyArr[1] : undefined,
              comment: this.getFirstValidCellByColumn(['Tytuł operacji', 2]),
              sum: this.getFirstValidCellByColumn(['Kwota', 4]),
              currency,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.saveToStore(
                'currency',
                (word, etc) =>
                  word?.includes('Waluta rachunku') &&
                  /^(\s*[A-Z]{3}\s*)$/.test(etc?.nextWord), // ' PLN '
                (word, etc) =>
                  /^(\s*[A-Z]{3}\s*)$/.test(word) &&
                  etc?.prevWord?.includes('Nazwa rachunku'),
              );
              self.findHeader(
                (word, etc) =>
                  word?.includes('Data') && etc?.nextWord?.includes('Dane'),
                (word, etc) =>
                  word?.includes('Kwota') &&
                  etc?.prevWord?.includes('operacji'),
              );
              self.deleteFromTo(
                (word, etc) =>
                  word?.startsWith('Wyciąg z rachunku') &&
                  etc?.nextWord?.startsWith('ING Bank Śląski S.A.'),
                (word, etc) =>
                  word?.includes('Nr transakcji') &&
                  etc?.prevWord?.includes('księgowania'),
              );
              self.deleteFromTo(
                (word) =>
                  word?.startsWith(
                    'Środki pieniężne zgromadzone na rachunku bankowym podlegają ochronie gwarancyjnej do ',
                  ),
                undefined,
              );
              self.defineOperation([
                (value) => /^(-?[\s\d]+,\d+)$/.test(value), // '-1 500,00'
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            let sum, currency;
            const sumAndCurrencyArr = this.getFirstValidCellByColumn([
              'Kwota\nWaluta',
              3,
            ])?.split(' ');
            if (sumAndCurrencyArr?.length) {
              sum = sumAndCurrencyArr.slice(0, -1).join('');
              currency = sumAndCurrencyArr[sumAndCurrencyArr.length - 1];
            }
            return {
              date: this.getFirstValidCellByColumn(['Data\nksięgowania', 0]),
              counterparty: this.getFirstValidCellByColumn(['Kontrahent', 1]),
              comment: this.getFirstValidCellByColumn([
                'Tytuł operacji\nTyp zlecenia',
                2,
              ]),
              sum,
              currency,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'one-line',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Data') && /^(\s*\d+\s*)$/.test(etc?.prevWord), // ' 67 '
                (word, etc) =>
                  word?.includes('Rachunek firmy') &&
                  moment(etc?.nextWord, 'DD-MM-YYYY', true).isValid(),
              );
              self.defineOperation([
                (value) => /^(-?[\s\d]+,\d+ [A-Z]{3})$/.test(value), // '-1 500,00 PLN'
                (value) => moment(value, 'DD-MM-YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
  };
}
