import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Dialog from '../../../components/Dialog/Dialog';
import {
  SHOW_IDOKLAD_INTEGRATION,
  SHOW_URKSIB_BANK_INTEGRATION,
} from '../../../constants/featureToggles/integrationsFeatureToggles';
import useUnleash from '../../../hooks/useUnleash';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import integrationActions from '../../../store/integrations/actions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import { IntegrationTypeId } from '../../../store/integrations/types';
import { dateToUTC } from '../../../utils/dateToUTC';
import RenderBanksList from '../../Integrations/BanksList';
import CheckBoxDialog from '../../Integrations/CheckBox';
import FacturowniaDialog from '../../Integrations/Facturownia';
import FondyAuth2Dialog from '../../Integrations/FondyAuth2/FondyAuth2FirstDialog';
import IdokladDialog from '../../Integrations/IdokladDialog';
import MonoIntegrationDialog from '../../Integrations/MonoDialog';
import PayoneerDialog from '../../Integrations/Payoneer/PayoneerFirstDialog';
import PosterDialog from '../../Integrations/PosterDialog';
import Private24CredentialsDialog from '../../Integrations/Private24Dialogs/Private24';
import Private24BusinessDialog from '../../Integrations/Private24Dialogs/Private24Business';
import PumbBusinessDialog from '../../Integrations/PumbBusinessDialog';
import UkrsibDialog from '../../Integrations/UkrsibBank/FirstStepDialog';
import WesternBidDialog from '../../Integrations/WesternBid';
import { Props } from './types';

function IntegrationDialog(props: Props) {
  const { onClose, account, onCloseAccountsDialog } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const facebookContext = useContext(FacebookPixelContext);
  const showiDokladIntegration = useUnleash(SHOW_IDOKLAD_INTEGRATION);
  const showUkrsibBankIntegration = useUnleash(SHOW_URKSIB_BANK_INTEGRATION);

  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const [currentBankId, setCurrentBankId] = useState<IntegrationTypeId | null>(
    null,
  );

  const handleChangeDate = useCallback((value: any) => {
    setSelectedDate(value);
  }, []);

  const handleCreate = useCallback(() => {
    if (currentBankId && selectedDate) {
      const payload: CreateIntegrationPayload = {
        typeId: currentBankId,
        accountId: account._id,
        startDate: dateToUTC(selectedDate),
        facebookContext,
      };

      dispatch(integrationActions.createIntegration(payload));

      if (onCloseAccountsDialog) {
        onCloseAccountsDialog();
      } else {
        onClose();
      }
    }
  }, [
    account,
    onClose,
    dispatch,
    selectedDate,
    currentBankId,
    facebookContext,
    onCloseAccountsDialog,
  ]);

  const handleSetCurrencyId = useCallback(() => {
    if (currentBankId === IntegrationTypeId.Private24Personal) {
      handleCreate();
    }

    if (currentBankId === IntegrationTypeId.Private24Business) {
      handleCreate();
    }
  }, [handleCreate, currentBankId]);

  const handleSetBank = useCallback((id: IntegrationTypeId) => {
    setCurrentBankId(id);
  }, []);

  if (!currentBankId) {
    return (
      <Dialog onClose={onClose} isOpened title={t('bank.whichBank')}>
        <RenderBanksList
          description={t('bank.description')}
          onSetupBank={handleSetBank}
          selectedAccountId={account._id}
          onClose={onClose}
        />
      </Dialog>
    );
  }

  return (
    <>
      {currentBankId === IntegrationTypeId.MonoPersonal && (
        <MonoIntegrationDialog
          selectedDate={selectedDate}
          onChangeDate={handleChangeDate}
          onClose={onClose}
          onCreateIntegration={handleCreate}
          onSetCurrencyId={handleSetCurrencyId}
          onCloseAccountsDialog={onCloseAccountsDialog}
        />
      )}
      {currentBankId === IntegrationTypeId.Private24Personal && (
        <Private24CredentialsDialog
          selectedDate={selectedDate}
          onChangeDate={handleChangeDate}
          onClose={onClose}
          onSetCurrencyId={handleSetCurrencyId}
        />
      )}
      {currentBankId === IntegrationTypeId.Private24Business && (
        <Private24BusinessDialog
          onClose={onClose}
          onCloseAccountsDialog={onCloseAccountsDialog}
          selectedAccountId={account._id}
        />
      )}
      {currentBankId === IntegrationTypeId.Pumb && (
        <PumbBusinessDialog
          onClose={onClose}
          onCloseAccountsDialog={onCloseAccountsDialog}
          selectedAccountId={account._id}
        />
      )}
      {currentBankId === IntegrationTypeId.UkrSib &&
        showUkrsibBankIntegration && (
          <UkrsibDialog onClose={onClose} selectedAccountId={account._id} />
        )}
      {currentBankId === IntegrationTypeId.FondyQ2 && (
        <FondyAuth2Dialog
          onClose={onClose}
          onCloseAccountsDialog={onCloseAccountsDialog}
          fondyAccountId={account._id}
        />
      )}
      {currentBankId === IntegrationTypeId.WesternBid && (
        <WesternBidDialog
          onClose={onClose}
          onCloseAccountsDialog={onCloseAccountsDialog}
          selectedAccountId={account._id}
        />
      )}
      {currentBankId === IntegrationTypeId.CheckBox && (
        <CheckBoxDialog
          onClose={onClose}
          onCloseAccountsDialog={onCloseAccountsDialog}
          selectedAccountId={account._id}
        />
      )}
      {currentBankId === IntegrationTypeId.Facturownia && (
        <FacturowniaDialog
          onClose={onClose}
          onCloseAccountsDialog={onCloseAccountsDialog}
          selectedAccountId={account._id}
        />
      )}
      {showiDokladIntegration &&
        currentBankId === IntegrationTypeId.iDoklad && (
          <IdokladDialog
            onClose={onClose}
            onCloseAccountsDialog={onCloseAccountsDialog}
            selectedAccountId={account._id}
          />
        )}
      {currentBankId === IntegrationTypeId.Poster && (
        <PosterDialog
          onClose={onClose}
          onCloseAccountsDialog={onCloseAccountsDialog}
          selectedAccountId={account._id}
        />
      )}
      {currentBankId === IntegrationTypeId.Payoneer && (
        <PayoneerDialog
          onClose={onClose}
          onCloseAccountsDialog={onCloseAccountsDialog}
          selectedAccountId={account._id}
        />
      )}
    </>
  );
}

export default React.memo(IntegrationDialog);
