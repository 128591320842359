import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class AltBankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: `${this.getFirstValidCellByColumn(['DATE', 2])} ${this.getFirstValidCellByColumn(['TIME', 3])}:00`,
            sum: this.getFirstValidCellByColumn(['SUM', 6]),
            comment: this.getFirstValidCellByColumn(['COMMENT', 14]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH', isDESCOpOrder: true },
      },
    ],
  };
}
