import i18next from 'i18next';
import { createSelector } from 'reselect';

import { EMPTY } from '../../constants';
import {
  DEFAULT_CONSUMPTION_EMPTY_ID,
  DEFAULT_INCOME_EMPTY_ID,
} from '../../constants/defaultCategories';
import { OperationSubType, OperationType } from '../operations/types';
import { AppState } from '../reducers';
import { Category, SubCategory } from './types';

const excludedPLConsumptionSubTypes = [
  OperationSubType.dividend,
  OperationSubType.loan,
  OperationSubType.loanrepayment,
  OperationSubType.loanRepayment,
];

export const getLanguage = (state: AppState) => state.company.lng;

export const getIncomeCategories = (state: AppState) =>
  state.categories.incomeCategories;

export const getConsumptionCategories = (state: AppState) =>
  state.categories.consumptionCategories;

export const selectLoadingIncomeCategories = createSelector(
  getIncomeCategories,
  (values) => values.loading,
);

export const selectLoadingConsumptionCategories = createSelector(
  getConsumptionCategories,
  (values) => values.loading,
);

export const selectLogIncomeCategories = createSelector(
  getIncomeCategories,
  (categories) => categories.log,
);

export const selectFullLogIncomeCategories = createSelector(
  getIncomeCategories,
  (categories) =>
    categories.fullLog.map((el) =>
      el._id === EMPTY ? { ...el, _id: DEFAULT_INCOME_EMPTY_ID } : el,
    ),
);

export const selectFullLogIncomeCategoriesIds = createSelector(
  selectFullLogIncomeCategories,
  (categories) =>
    categories.reduce((acc: string[], el) => {
      acc.push(el._id);

      el.subcategories?.forEach((subEl) => acc.push(subEl._id));

      return acc;
    }, []),
);

export const selectLogSystemIncomeCategories = createSelector(
  selectFullLogIncomeCategories,
  (categories) => categories.filter((el) => el.systemValue),
);

export const selectOperationIncomeCategories = createSelector(
  getIncomeCategories,
  (categories) => categories.operation,
);

export const selectSettingsIncomeCategories = createSelector(
  getIncomeCategories,
  (categories) => categories.settings,
);

export const selectSettingsIncomeCategoriesWithGenericAndNoCategories =
  createSelector(
    selectSettingsIncomeCategories,
    getLanguage,
    (categories, language) => {
      const i18 = i18next.getFixedT(language);

      // @ts-ignore
      categories.push({
        _id: DEFAULT_INCOME_EMPTY_ID,
        label: i18('categories.without'),
        subcategories: [],
      });

      return categories;
    },
  );

export const selectLogConsumptionCategories = createSelector(
  getConsumptionCategories,
  (categories) => categories.log,
);

export const selectFullLogConsumptionCategories = createSelector(
  getConsumptionCategories,
  (categories) =>
    categories.fullLog.map((el) =>
      el._id === EMPTY ? { ...el, _id: DEFAULT_CONSUMPTION_EMPTY_ID } : el,
    ),
);

export const selectFullLogConsumptionCategoriesIds = createSelector(
  selectFullLogConsumptionCategories,
  (categories) =>
    categories.reduce((acc: string[], el) => {
      acc.push(el._id);

      el.subcategories?.forEach((subEl) => acc.push(subEl._id));

      return acc;
    }, []),
);

export const selectLogSystemConsumptionCategories = createSelector(
  selectFullLogConsumptionCategories,
  (categories) => categories.filter((el) => el.systemValue),
);

export const selectOperationConsumptionCategories = createSelector(
  getConsumptionCategories,
  (categories) => categories.operation,
);

export const selectSettingsConsumptionCategories = createSelector(
  getConsumptionCategories,
  (categories) => categories.settings,
);

export const selectSettingsConsumptionCategoriesWithGenericAndNoCategories =
  createSelector(
    selectSettingsConsumptionCategories,
    getLanguage,
    (categories, language) => {
      const i18 = i18next.getFixedT(language);

      // @ts-ignore
      categories.push({
        _id: DEFAULT_CONSUMPTION_EMPTY_ID,
        label: i18('categories.without'),
        subcategories: [],
      });

      return categories;
    },
  );

export const selectAllCategoriesCount = createSelector(
  selectFullLogIncomeCategoriesIds,
  selectFullLogConsumptionCategoriesIds,
  (income, consumption) => income.length + consumption.length,
);

export const selectPLIncomeCategoriesIds = createSelector(
  selectFullLogIncomeCategories,
  (categories) =>
    categories
      .filter((el) => !el.systemValue)
      .reduce((acc: string[], el) => {
        acc.push(el._id);

        if (el.subcategories?.length) {
          el.subcategories.forEach((subEl) => acc.push(subEl._id));
        }

        return acc;
      }, []),
);

export const selectPLConsumptionCategoriesIds = createSelector(
  selectFullLogConsumptionCategories,
  (consumption) =>
    consumption
      .filter(
        (el) =>
          !excludedPLConsumptionSubTypes.includes(
            el.normalizedLabel as OperationSubType,
          ),
      )
      .reduce((acc: string[], el) => {
        acc.push(el._id);

        if (el.subcategories?.length) {
          el.subcategories.forEach((subEl) => acc.push(subEl._id));
        }

        return acc;
      }, []),
);

export const selectAllPLCategoriesCount = createSelector(
  selectPLIncomeCategoriesIds,
  selectPLConsumptionCategoriesIds,
  (income, consumption) => income.length + consumption.length,
);

export const selectConsumptionSettingsSortableTreeByType = createSelector(
  selectSettingsConsumptionCategories,
  (consumption) =>
    consumption.map((el) => {
      const subCatTree =
        el.subcategories?.map((subEl) => ({
          disableCheckbox: true,
          title: subEl.label,
          key: subEl._id,
          parentId: el._id,
          ...subEl,
        })) || [];

      return {
        disableCheckbox: true,
        title: el.label,
        key: el._id,
        children: subCatTree,
        parentId: null,
        ...el,
      };
    }),
);

export const selectSettingsSortableTreeByTypeV2 = (type: OperationType) =>
  createSelector(
    selectSettingsIncomeCategories,
    selectSettingsConsumptionCategories,
    (income, consumption) => {
      const categories = type === OperationType.income ? income : consumption;

      return categories.map((el) => {
        const subCatTree = el.subcategories?.map((subEl) => ({
          id: subEl._id,
          parentId: el._id,
          ...subEl,
          children: undefined,
          canHaveChildren: false,
        }));

        return {
          id: el._id,
          key: el._id,
          children: subCatTree,
          parentId: null,
          canHaveChildren: !el.systemValue,
          ...el,
        };
      });
    },
  );

export const selectIncomeSettingsSortableTreeByType = createSelector(
  selectSettingsIncomeCategories,
  (income) =>
    income.map((el) => {
      const subCatTree =
        el.subcategories?.map((subEl) => ({
          disableCheckbox: true,
          title: subEl.label,
          key: subEl._id,
          parentId: el._id,
          ...subEl,
        })) || [];

      return {
        disableCheckbox: true,
        title: el.label,
        key: el._id,
        children: subCatTree,
        parentId: null,
        ...el,
      };
    }),
);

export const selectConsumptionLoanRepaymentId = createSelector(
  selectSettingsConsumptionCategories,
  (categories) =>
    categories.find(
      (el) => el.normalizedLabel === OperationSubType.loanrepayment,
    )?._id || '',
);

export const selectIncomeLoanId = createSelector(
  selectSettingsIncomeCategories,
  (categories) =>
    categories.find((el) => el.normalizedLabel === OperationSubType.loan)
      ?._id || '',
);

export const selectIncomeSystemNameCategoriesToId = createSelector(
  selectOperationIncomeCategories,
  (income) =>
    income.reduce((acc, el) => {
      const { _id, systemValue, normalizedLabel } = el;

      if (!systemValue) {
        return acc;
      }

      acc.push({
        [normalizedLabel]: _id,
      });

      return acc;
    }, [] as { [x in string]: string }[]),
);

export const selectConsumptionSystemNameCategoriesToId = createSelector(
  selectOperationConsumptionCategories,
  (income) =>
    income.reduce((acc, el) => {
      const { _id, systemValue, normalizedLabel } = el;

      if (!systemValue) {
        return acc;
      }

      acc.push({
        [normalizedLabel]: _id,
      });

      return acc;
    }, [] as { [x in string]: string }[]),
);

export const selectIncomeCatsAndSubCatsToList = createSelector(
  selectOperationIncomeCategories,
  (categories) =>
    categories.reduce((acc: (Category | SubCategory)[], category) => {
      acc.push(category);

      if (category.subcategories.length) {
        category.subcategories.forEach((cat) => {
          if (cat.visible) {
            // @ts-ignore
            acc.push({ ...cat, parentId: category._id });
          }
        });
      }

      return acc;
    }, []),
);

export const selectConsumptionCatsAndSubCatsToList = createSelector(
  selectOperationConsumptionCategories,
  (categories) =>
    categories.reduce((acc: (Category | SubCategory)[], category) => {
      acc.push(category);

      if (category.subcategories.length) {
        category.subcategories.forEach((cat) => {
          if (cat.visible) {
            // @ts-ignore
            acc.push({ ...cat, parentId: category._id });
          }
        });
      }

      return acc;
    }, []),
);
