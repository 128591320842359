import {
  LoadMainChartPayload,
  LoadOtherChartsPayload,
  LoadPieChartPayload,
  LoadStackedChartPayload,
} from './sagas.types';
import { LoadingPieChartDataAmount, TypeFilter, UpdateProjectsBySortPayload } from "./types";

export const ANALYTIC_INIT = 'ANALYTIC_INIT';

export const GET_ACCOUNT_STATEMENT_PENDING = 'GET_ACCOUNT_STATEMENT_PENDING';
export const GET_ACCOUNT_STATEMENT_SUCCESS = 'GET_ACCOUNT_STATEMENT_SUCCESS';

export const GET_DEBIT_DATA_PENDING = 'GET_DEBIT_DATA_PENDING';
export const GET_DEBIT_DATA_SUCCESS = 'GET_DEBIT_DATA_SUCCESS';

export const GET_CREDIT_DATA_PENDING = 'GET_CREDIT_DATA_PENDING';
export const GET_CREDIT_DATA_SUCCESS = 'GET_CREDIT_DATA_SUCCESS';

export const GET_PROJECTS_DATA_PENDING = 'GET_PROJECTS_DATA_PENDING';
export const GET_PROJECTS_DATA_SUCCESS = 'GET_PROJECTS_DATA_SUCCESS';

export const GET_MAIN_CHART_PENDING = 'GET_MAIN_CHART_PENDING';
export const GET_MAIN_CHART_SUCCESS = 'GET_MAIN_CHART_SUCCESS';

export const GET_OTHER_CHARTS = 'GET_OTHER_CHARTS';

export const GET_CIRCLE_CHARTS_PENDING = 'GET_CIRCLE_CHARTS_PENDING';
export const GET_CIRCLE_CHARTS_SUCCESS = 'GET_CIRCLE_CHARTS_SUCCESS';

export const GET_CHARTS_PENDING = 'GET_CHARTS_PENDING';
export const GET_CHARTS_SUCCESS = 'GET_CHARTS_SUCCESS';

export const GET_TABLE_DATA_PENDING = 'GET_TABLE_DATA_PENDING';
export const GET_TABLE_DATA_SUCCESS = 'GET_TABLE_DATA_SUCCESS';

export const GET_PIE_CHAR_DATA_PENDING = 'GET_PIE_CHAR_DATA_PENDING';
export const GET_PIE_CHAR_DATA_SUCCESS = 'GET_PIE_CHAR_DATA_SUCCESS';

export const GET_PIE_CHAR_DATA_AMOUNT = 'GET_PIE_CHAR_INCOME_AMOUNT';

export const SET_LOADING_ERROR = 'SET_LOADING_ERROR';
export const SET_LOADING_CIRCLES = 'SET_LOADING_CIRCLES';
export const SET_LOADING_STACKED = 'SET_LOADING_STACKED';

export const GET_STACKED_CHART_DATA_PENDING = 'GET_STACKED_CHART_DATA_PENDING';
export const GET_STACKED_CHART_DATA_SUCCESS = 'GET_STACKED_CHART_DATA_SUCCESS';

export const SET_STACKED_CHART_DATA_LOADING = 'SET_STACKED_CHART_DATA_LOADING';
export const UPDATE_PROJECTS_BY_SORT = 'UPDATE_PROJECTS_BY_SORT';

export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';

const updateProjectsBySort = (data: UpdateProjectsBySortPayload) => ({
  type: UPDATE_PROJECTS_BY_SORT,
  payload: data,
});

const loadProjectsData = (useProjectsCharts: boolean) => ({
  type: GET_PROJECTS_DATA_PENDING,
  payload: { useProjectsCharts }
});

const loadDebitData = () => ({
  type: GET_DEBIT_DATA_PENDING,
});

const loadCreditData = () => ({
  type: GET_CREDIT_DATA_PENDING,
});

const loadMainChart = (payload: LoadMainChartPayload) => ({
  type: GET_MAIN_CHART_PENDING,
  payload,
});

const loadPieChart = (payload: LoadPieChartPayload) => ({
  type: GET_PIE_CHAR_DATA_PENDING,
  payload,
});

const loadPieCHartDataAmount = (payload: LoadingPieChartDataAmount) => ({
  type: GET_PIE_CHAR_DATA_AMOUNT,
  payload
})

const loadStackedChart = (payload: LoadStackedChartPayload) => ({
  type: GET_STACKED_CHART_DATA_PENDING,
  payload,
});

const loadOtherCharts = (payload: LoadOtherChartsPayload) => ({
  type: GET_OTHER_CHARTS,
  payload,
});

const loadTableData = (payload: LoadMainChartPayload) => ({
  type: GET_TABLE_DATA_PENDING,
  payload,
});

const getAccountStatement = () => ({
  type: GET_ACCOUNT_STATEMENT_PENDING,
});

const setTypeFilter = (data: TypeFilter) => ({
  type: SET_TYPE_FILTER,
  payload: { data }
});

export default {
  loadPieChart,
  setTypeFilter,
  loadTableData,
  loadDebitData,
  loadMainChart,
  loadCreditData,
  loadOtherCharts,
  loadStackedChart,
  loadProjectsData,
  getAccountStatement,
  updateProjectsBySort,
  loadPieCHartDataAmount,

};
