import { isNotEmpty } from 'class-validator';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class JusanbankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return rawDocument?.length && rawDocument[0].str.includes('Дата');
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn(['Дата', 0])?.slice(
              0,
              'DD.MM.YYYY HH:mm:ss'.length,
            ),
            sum: this.getFirstValidCellByColumn(['Сумма', 1]),
            comment: this.getFirstValidCellByColumn(['Детали операции', 4]),
            currency: this.getFirstValidCellByColumn(['Валюта', 2]),
          });
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') && etc.nextWord?.includes('Сумма'),
                (word) => word?.includes('Детали операции'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('Детали операции'),
                1,
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY HH:mm:ss', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) => value.str.includes('Дата операции')),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата операции',
              0,
            ])?.replace('\n', ' '),
            debit: this.getFirstValidCellByColumn(['Дебет', 3]),
            credit: this.getFirstValidCellByColumn(['Кредит', 4]),
            counterparty: this.getFirstValidCellByColumn(['Корреспондент', 9]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 8]),
          });
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            rotate: true,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата операции') &&
                  etc.nextWord?.includes('Дата'),
                (word) => word?.includes('Счет'),
              );
              self.deleteFromTo(undefined, (word) => word?.includes('Счет'), 1);
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn(['Дата', 0])?.slice(
              0,
              'DD.MM.YYYY HH:mm'.length,
            ),
            debit: this.getFirstValidCellByColumn(['Дебет', 2]),
            credit: this.getFirstValidCellByColumn(['Кредит', 3]),
            counterparty: this.getFirstValidCellByColumn(['Корреспондент', 6]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            rotate: true,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') && etc.nextWord?.includes('No док'),
                (word) => word?.includes('Счет'),
              );
              self.deleteFromTo(undefined, (word) => word?.includes('Счет'), 1);
              self.defineOperation([
                (value) =>
                  moment(
                    value.slice(0, 'DD.MM.YYYY HH:mm'.length),
                    'DD.MM.YYYY HH:mm',
                    true,
                  ).isValid(),
              ]);
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата выписки', 0]),
            time: moment(
              this.getFirstValidCellByColumn(['Время операции', 1]),
              'HH:mm:ss.SSSSSS',
            ).toISOString(),
            debit: this.getFirstValidCellByColumn(['Сумма по дебету', 10]),
            credit: this.getFirstValidCellByColumn(['Сумма по кредиту', 12]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 17]),
            currency: this.getFirstValidCellByColumn(['Валюта документа', 14]),
            counterparty: this.getFirstValidCellByColumn([
              'Наименование контрагента',
              16,
            ]),
          });
        },
        caseOptions: { defaultCurrency: 'KZT' },
      },
    ],
  };
}
