import { AnyAction } from 'redux';

import Storages, { StorageKey } from '../../services/Storages';
import { getTimeOffset } from '../../utils/dateToUTC';
import {
  GET_ACTIVITIES_SUCCESS,
  GET_SCHEDULE_PERIODS_SUCCESS,
  GET_VERSION_SUCCESS,
  GET_VERSION_UI_SUCCESS,
  RESET_CREATE_ELEMENT_ERROR,
  RESET_CREATE_ELEMENT_LOADING,
  RESET_SERVER_ERROR,
  SET_AMOUNT_EMOJI,
  SET_CONTAINER_OFFSET,
  SET_CREATE_ELEMENT_ERROR,
  SET_CREATE_ELEMENT_LOADING,
  SET_SEARCH_FOCUSED,
  SET_SERVER_ERROR,
  SET_SERVER_TIME,
  SET_SHOW_EMPTY_STATE_TOOLTIP_V3,
  SET_SHOW_LEFT_MENU,
  SET_SHOW_RESTRICTED_DIALOG,
  SET_USER_GEO_DATA,
} from './actions';
import { CommonState } from './types';

const initialState: CommonState = {
  version: '',
  versionUI: '',
  schedulePeriods: null,
  serverError: false,
  createElementError: false,
  createElementLoading: false,
  searchFocused: false,
  activities: [],
  serverTime: new Date().getTime() + getTimeOffset(),
  showRestrictedDialog: false,
  showLeftMenu:
    Storages.get(StorageKey.leftMenuStatus) === null
      ? true
      : Storages.get(StorageKey.leftMenuStatus) === true,
  containerOffset: 0,
  userGeoData: null,
  amountEmoji:
    Storages.get(StorageKey.amountEmoji) === null
      ? ''
      : Storages.get(StorageKey.amountEmoji),
  showEmptyStateTooltipV3: false,
};

const commonReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case SET_SHOW_EMPTY_STATE_TOOLTIP_V3: {
      return {
        ...state,
        showEmptyStateTooltipV3: payload.show,
      };
    }

    case SET_USER_GEO_DATA: {
      return {
        ...state,
        userGeoData: payload.userGeoData,
      };
    }

    case SET_AMOUNT_EMOJI: {
      const { emoji } = payload;

      if (emoji) {
        Storages.put(StorageKey.amountEmoji, emoji);
      } else {
        Storages.remove(StorageKey.amountEmoji);
      }

      return {
        ...state,
        amountEmoji: emoji,
      };
    }

    case SET_CONTAINER_OFFSET: {
      return {
        ...state,
        containerOffset: payload.containerOffset,
      };
    }

    case SET_SHOW_LEFT_MENU: {
      Storages.put(StorageKey.leftMenuStatus, payload.showLeftMenu);

      return {
        ...state,
        showLeftMenu: payload.showLeftMenu,
      };
    }

    case SET_SHOW_RESTRICTED_DIALOG: {
      return {
        ...state,
        showRestrictedDialog: payload.value,
      };
    }

    case SET_SERVER_TIME: {
      return {
        ...state,
        serverTime: payload.time,
      };
    }

    case SET_SEARCH_FOCUSED: {
      return {
        ...state,
        searchFocused: payload.focused,
      };
    }

    case GET_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activities: payload.data,
      };
    }

    case SET_CREATE_ELEMENT_LOADING: {
      return {
        ...state,
        createElementLoading: true,
      };
    }

    case RESET_CREATE_ELEMENT_LOADING: {
      return {
        ...state,
        createElementLoading: false,
      };
    }

    case RESET_CREATE_ELEMENT_ERROR: {
      return {
        ...state,
        createElementError: false,
      };
    }

    case SET_CREATE_ELEMENT_ERROR: {
      return {
        ...state,
        createElementError: true,
      };
    }

    case RESET_SERVER_ERROR: {
      return {
        ...state,
        serverError: false,
      };
    }

    case SET_SERVER_ERROR: {
      return {
        ...state,
        serverError: true,
      };
    }

    case GET_VERSION_SUCCESS: {
      return {
        ...state,
        version: payload.data.version,
      };
    }

    case GET_VERSION_UI_SUCCESS: {
      return {
        ...state,
        versionUI: payload.data.version,
      };
    }

    case GET_SCHEDULE_PERIODS_SUCCESS:
      return {
        ...state,
        schedulePeriods: payload.data,
      };

    default:
      return state;
  }
};

export default commonReducer;
