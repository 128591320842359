import './styles.scss';
import { TextField, Typography } from '@material-ui/core';
import { createFilterOptions, FilterOptionsState } from '@material-ui/lab';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import cn from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { normalize } from '@finmap/core-utils';

import {
  ENTER_KEY_CODE,
  ESCAPE_KEY_CODE,
  MAX_INPUT_LENGTH,
} from '../../constants';
import { USE_NEW_UX_LOGIC_CREATE_ITEM } from '../../constants/featureToggles/featureToggle';
import { findSubTypeInTranslation } from '../../foundation/Header/Settings/Components/CategoriesDialog/utils';
import { useCompareStyles } from '../../foundation/Operations/Components/compareStyles';
import usePermissions from '../../hooks/usePermissions';
import useSubscriptionActive from '../../hooks/useSubscriptionActive';
import useUnleash from '../../hooks/useUnleash';
import {
  CountryType,
  filteredCountries,
} from '../../scenes/Auth/Components/PhoneCodesList/countries';
import { Category, SubCategory } from '../../store/categories/types';
import { Client } from '../../store/clients/types';
import { OperationType } from '../../store/operations/types';
import { AutoCompleteProps } from '../../store/types';
import { persianGreen } from '../../theme/colors';
import PreviewRegisterDialog from '../PreviewRegisterDialog';
import TextFieldComponent from '../TextField/TextFieldComponent';
import TransitionComponent from '../TransitionComponent';
import { StyledAutocomplete } from './StyledAutocomplete';
import { useStyles } from './styles';
import { Props } from './types';

function isElementExist(
  type: string | undefined,
  operationType: OperationType | undefined,
  currentValue: any,
  data: Client[] | (Category | SubCategory)[] | null,
) {
  const isExistInDefaultCategory =
    type === 'category' &&
    operationType &&
    findSubTypeInTranslation(currentValue, operationType);

  const exist = data?.find(
    (el) => normalize(el.label) === normalize(currentValue),
  );

  return exist || isExistInDefaultCategory;
}

const StyledAutocompleteComponent = StyledAutocomplete as any;
const CheckIconComponent = CheckIcon as any;

function AutoCompleteComponent(props: Props) {
  const {
    data,
    type,
    value,
    label,
    loading,
    isError,
    onCreate,
    onChange,
    errorText,
    disabled = false,
    isCompare,
    isGeneric,
    rootStyles,
    difference,
    allowedCreate,
    operationType,
    disablePortal = true,
    className,
    clearOnEscape = true,
    disableClearable = false,
    getOptionDisabled,
    isCountryAutoComplete,
    classesMui,
    isProjectSplitDropdown = false,
  } = props;

  const classes = useStyles({ isError });
  const subscriptionActive = useSubscriptionActive();
  const { operationEnable } = usePermissions();

  const [existError, setExistError] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const [isNew, setIsNew] = useState(false);
  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);

  const useNewUXLogicCreateItem = useUnleash(USE_NEW_UX_LOGIC_CREATE_ITEM);

  const ref = useRef<{ data: (Client | Category)[] | null }>(null);
  const anchorEl = useRef<HTMLDivElement | null>(null);

  const compareClasses = useCompareStyles();
  const { t } = useTranslation();

  let placeHolderText = isCountryAutoComplete
    ? '+1'
    : t(`placeholderByType.${type}`);

  if (!type) {
    placeHolderText = '';
  }

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const handleTogglePopup = useCallback(() => {
    if (
      isCountryAutoComplete ||
      (subscriptionActive && operationEnable && !isCompare && !disabled)
    ) {
      setOpenPopup((val) => !val);
    }
  }, [
    subscriptionActive,
    operationEnable,
    isCompare,
    disabled,
    isCountryAutoComplete,
  ]);

  const handleClosePopup = useCallback(() => {
    setOpenPopup(false);
  }, []);

  const additionFilterRow = useMemo(
    () => ({
      id: 'new',
      name: `+ ${t('common.create')} ${t(`fieldTypes.${type}`)}`,
    }),
    [t, type],
  );

  const filter = createFilterOptions();

  const filterOptions = useCallback(
    (
      options: AutoCompleteProps[],
      params: FilterOptionsState<AutoCompleteProps>,
    ): AutoCompleteProps[] => {
      // @ts-ignore
      const filtered: AutoCompleteProps[] = filter(options, params);

      if (allowedCreate) {
        return [...filtered, additionFilterRow];
      }

      return filtered;
    },
    [additionFilterRow, filter, allowedCreate],
  );

  const handleChangeTextValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value: textValue } = event.target;

      if (!textValue) {
        onChange(null);
      }

      setCurrentValue(textValue);
    },
    [onChange],
  );

  const handleChangeNewTextValue = useCallback((val: string) => {
    setCurrentValue(val);
    setExistError(false);
  }, []);

  const handleCreate = useCallback(() => {
    handleClosePreviewRegisterDialog();

    if (currentValue && onCreate) {
      onCreate(currentValue);
      setIsNew(false);
      setOpenPopup(false);
    }
  }, [onCreate, currentValue, handleClosePreviewRegisterDialog]);

  const handleChange = useCallback(
    (_event: any, newValue: any) => {
      if (!newValue) {
        onChange(null);
        setOpenPopup(false);

        return;
      }

      if (newValue.id !== 'new') {
        onChange(newValue);
        setOpenPopup(false);
      } else {
        handleClosePreviewRegisterDialog();

        setIsNew(true);
      }
    },
    [onChange, handleClosePreviewRegisterDialog],
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        event.stopPropagation();

        setIsNew(false);
        setExistError(false);
        setCurrentValue('');

        onChange(null);

        return;
      }

      if (event.keyCode === ENTER_KEY_CODE && currentValue) {
        event.stopPropagation();

        if (useNewUXLogicCreateItem) {
          const newUXsuggested =
            // @ts-ignore
            data?.filter(
              (el) =>
                normalize(el.label).indexOf(normalize(currentValue)) !== -1,
            ) || [];

          if (newUXsuggested.length && !isNew) {
            onChange(newUXsuggested[0]);
            setCurrentValue('');

            handleClosePopup();

            return;
          }
        } else {
          const suggested =
            // @ts-ignore
            data?.filter((el) => el.label.indexOf(currentValue) !== -1) || [];

          if (suggested.length === 1 && !isNew) {
            onChange(suggested[0]);
            setCurrentValue('');

            handleClosePopup();

            return;
          }
        }

        const elementExist = isElementExist(
          type,
          operationType,
          currentValue,
          // @ts-ignore
          data,
        );

        if (elementExist) {
          setIsNew(true);
          setExistError(true);

          return;
        }

        handleOpenPreviewRegisterDialog();
      }
    },
    [
      type,
      data,
      isNew,
      onChange,
      currentValue,
      operationType,
      handleClosePopup,
      useNewUXLogicCreateItem,
      handleOpenPreviewRegisterDialog,
    ],
  );

  const handleActiveNew = useCallback(() => {
    if (currentValue) {
      const elementExist = isElementExist(
        type,
        operationType,
        currentValue,
        // @ts-ignore
        data,
      );

      if (elementExist) {
        setCurrentValue('');
      }
    }

    setIsNew(true);
  }, [currentValue, data, type, operationType]);

  const renderLoading = useCallback(
    () => (
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    ),
    [],
  );

  const renderInput = useCallback(
    (params: any) => (
      <TextField
        {...params}
        className={cn(isCompare && compareClasses.disabledInput)}
        onChange={handleChangeTextValue}
        multiline={false}
        label={label ?? (value && t(`placeholderByType.${type}`))}
        placeholder={label ?? placeHolderText}
        onKeyDown={handleKeyDown}
        onClick={handleTogglePopup}
        onBlur={handleClosePopup}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        inputProps={{
          ...params.inputProps,
          maxLength: MAX_INPUT_LENGTH,
        }}
      />
    ),
    [
      t,
      type,
      value,
      label,
      classes,
      isCompare,
      handleKeyDown,
      compareClasses,
      handleClosePopup,
      handleTogglePopup,
      handleChangeTextValue,
      placeHolderText,
    ],
  );

  const noOption = useCallback(() => {
    let title = type ? t(`errors.create.${type}.notExist`) : '';
    if (isCountryAutoComplete) {
      title = t('errors.codeNotFound');
    }
    if (!data?.length && type) {
      title = t(`errors.create.${type}.noExist`);
    }

    return (
      <div>
        <Typography className={cn(classes.noOptions, classes.shortText)}>
          {title}
        </Typography>
      </div>
    );
  }, [
    classes.noOptions,
    classes.shortText,
    data?.length,
    isCountryAutoComplete,
    t,
    type,
  ]);

  const replaceEmptyValue = useCallback((textValue: string) => {
    if (textValue) {
      return `"${textValue}"`;
    }

    return '';
  }, []);

  const getCountryCode = (optionPhone: string): string =>
    filteredCountries.find(
      (el: CountryType) => el?.phone === optionPhone?.substring(1),
    )?.code ?? '';

  const renderFlag = (code: string) => {
    if (!code) {
      return null;
    }

    return (
      <img
        loading="lazy"
        width="25"
        src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
        alt=""
        className={classes.icon}
      />
    );
  };

  const renderCountryCodeOption = (option: CountryType) => (
    <>
      {renderFlag(option.code)}
      <Typography>{`+${option.phone}`}</Typography>
    </>
  );

  const renderCountryCodeInput = (params: any) => (
    <div style={{ position: 'relative' }}>
      {params.inputProps.value && (
        <span className={classes.flagIcon}>
          {renderFlag(getCountryCode(params.inputProps.value))}
        </span>
      )}
      <TextField
        {...params}
        onChange={handleChangeTextValue}
        multiline={false}
        label={
          isCountryAutoComplete ? '' : value && t(`placeholderByType.${type}`)
        }
        placeholder={placeHolderText}
        onKeyDown={handleKeyDown}
        onClick={handleTogglePopup}
        onBlur={handleClosePopup}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        inputProps={{
          ...params.inputProps,
          maxLength: MAX_INPUT_LENGTH,
        }}
      />
    </div>
  );

  const renderOption = useCallback(
    (option: any, { selected }: AutocompleteRenderOptionState) => {
      if (selected) {
        return (
          <div className={classes.column}>
            <div className={classes.selectedOptionContainer}>
              <Typography
                className={cn(
                  classes.selectedOptionText,
                  option.parentId && classes.padding20,
                  classes.shortText,
                )}
                title={option.label}
              >
                {option.label}
              </Typography>
              <CheckIconComponent color={persianGreen} />
            </div>
            {Boolean(option.description) && (
              <Typography className={classes.description}>
                {option.description}
              </Typography>
            )}
          </div>
        );
      }

      if (
        allowedCreate &&
        additionFilterRow &&
        option.id === additionFilterRow.id
      ) {
        const elementExist = isElementExist(
          type,
          operationType,
          currentValue,
          // @ts-ignore
          data,
        );

        let title = `+ ${t('common.create')} ${t(
          `fieldTypes.${type}`,
        )} ${replaceEmptyValue(currentValue)}`;

        if (elementExist) {
          title = `+ ${t('common.create')} ${t(`fieldTypes.${type}`)}`;
        }

        return (
          <Typography
            className={cn(classes.additionalOptionText, classes.shortText)}
            onClick={handleActiveNew}
          >
            {title}
          </Typography>
        );
      }

      return (
        <div className={classes.column}>
          <Typography
            className={cn(
              option.parentId && classes.padding20,
              classes.shortText,
            )}
            title={option.label}
          >
            {option.label}
          </Typography>
          {Boolean(option.description) && (
            <Typography className={classes.description}>
              {option.description}
            </Typography>
          )}
        </div>
      );
    },
    [
      t,
      data,
      type,
      classes,
      currentValue,
      operationType,
      allowedCreate,
      handleActiveNew,
      additionFilterRow,
      replaceEmptyValue,
    ],
  );

  const getOptionLabel = useCallback(
    (option: any) =>
      isCountryAutoComplete
        ? `+${option.phone}`
        : option.label || option.name || '',
    [isCountryAutoComplete],
  );

  const handleBlurNew = useCallback(() => {
    const elementExist = isElementExist(
      type,
      operationType,
      currentValue,
      // @ts-ignore
      data,
    );

    if (elementExist) {
      setExistError(true);

      return;
    }

    if (currentValue) {
      handleOpenPreviewRegisterDialog();
    } else {
      setIsNew(false);
    }
  }, [
    data,
    type,
    currentValue,
    operationType,
    handleOpenPreviewRegisterDialog,
  ]);

  useEffect(() => {
    if (data?.length) {
      // @ts-ignore
      if (currentValue && data.length !== ref.current?.data.length) {
        // @ts-ignore
        const exist = data.find(
          (el: any) => normalize(el.label) === normalize(currentValue),
        );

        if (exist) {
          handleChange(null, exist);
          setCurrentValue('');
        }
      }

      // @ts-ignore
      ref.current = { data };
    }
  }, [data, handleChange, currentValue]);

  const getOptionSelected = useCallback(
    (option: AutoCompleteProps, optionValue: AutoCompleteProps) =>
      // @ts-ignore
      optionValue && option._id === optionValue._id,
    [],
  );

  if (isNew) {
    return (
      <div
        className={
          isProjectSplitDropdown ? classes.dropDownSplitProjectInputField : ''
        }
      >
        <TextFieldComponent
          active
          fullWidth
          label={t(`placeholderByTypeNew.${type}`)}
          onChange={handleChangeNewTextValue}
          value={currentValue}
          placeholder={placeHolderText}
          onKeyDown={handleKeyDown}
          onBlur={handleBlurNew}
          isError={existError}
          errorText={t(`errors.create.${type}.exist`)}
          rootClassName={isProjectSplitDropdown ? 'marginBtm0' : ''}
        />
        {showPreviewRegisterDialog && (
          <PreviewRegisterDialog
            onClose={handleClosePreviewRegisterDialog}
            callback={handleCreate}
          />
        )}
      </div>
    );
  }

  return (
    <div
      ref={anchorEl}
      className={cn(
        'root-autocomplete',
        classes.root,
        rootStyles,
        isError && classes.error,
        difference && compareClasses.root,
        isGeneric && compareClasses.genericRoot,
        (!subscriptionActive || isCompare || !operationEnable) &&
          !isCountryAutoComplete &&
          classes.disabledInput,
        className,
      )}
    >
      <div className={classes.container}>
        <StyledAutocompleteComponent
          autoHighlight
          blurOnSelect
          disableClearable={disableClearable}
          clearOnEscape={clearOnEscape}
          open={openPopup}
          value={value}
          disabled={
            isCountryAutoComplete
              ? false
              : disabled ||
                (!allowedCreate && !data?.length) ||
                !subscriptionActive ||
                !operationEnable ||
                isCompare
          }
          id={type}
          disablePortal={disablePortal}
          onChange={handleChange}
          options={data || []}
          getOptionLabel={getOptionLabel}
          // @ts-ignore
          renderOption={
            isCountryAutoComplete ? renderCountryCodeOption : renderOption
          }
          /* eslint-disable-next-line no-nested-ternary */
          renderInput={
            loading
              ? renderLoading
              : isCountryAutoComplete
              ? renderCountryCodeInput
              : renderInput
          }
          // @ts-ignore
          filterOptions={filterOptions}
          noOptionsText={noOption()}
          // @ts-ignore
          getOptionSelected={getOptionSelected}
          getOptionDisabled={getOptionDisabled}
          classes={classesMui}
        />
      </div>
      <TransitionComponent
        className="error"
        text={errorText || ''}
        enter={Boolean(isError)}
      />
      {isGeneric && (
        <Typography className={cn(compareClasses.genericText)}>
          {t('common.setField')}
        </Typography>
      )}
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={handleCreate}
        />
      )}
    </div>
  );
}

export default React.memo(AutoCompleteComponent);
