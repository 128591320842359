import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

import { CenterCreditBankPDFPreParser } from './centercredit-bank-pdf-preparser';

export class CenterCreditBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
            { rotate: true },
          );
          return (
            rawDocument?.length &&
            rawDocument[0].str.includes('Документ сформирован электронно')
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Күні / Дата',
              1,
            ])?.replace('\n', ' '),
            debit: this.getFirstValidCellByColumn(['Дебет / Дебет', 7]),
            credit: this.getFirstValidCellByColumn(['Кредит / Кредит', 8]),
            counterparty: this.getFirstValidCellByColumn([
              'Корреспондент / Корреспондент',
              5,
            ]),
            comment: this.getFirstValidCellByColumn([
              'Төлемнің мақсаты / Назначение платежа',
              11,
            ]),
          });
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            rotate: true,
            pageSeparatorsLengthPx: 3,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word?.includes('Корр-тің'),
                (word) => word?.includes('отправителя'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('отправителя'),
                1,
              );
              self.deleteFromTo((word) =>
                word?.startsWith('Жиынтығы / Итого:'),
              );
              self.defineOperation([
                (value) => /^\d+$/.test(value),
                (value) => /^\d+$/.test(value),
                (value) =>
                  moment(value, 'DD.MM.YYYY', true).isValid() ||
                  moment(value, 'DD.MM.YYYY HH:mm', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        preParser: new CenterCreditBankPDFPreParser(),
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            let lastStringArr = this.getFirstValidCellByColumn([
              'additionalData',
              9,
            ])?.split('\n') || [];
            let lastEmpty = 0;
            for (let i = 1; i < lastStringArr.length - 1; i++) {
              if (!/.+\/.+:/.test(lastStringArr[i])) {
                lastStringArr[lastEmpty] = `${lastStringArr[lastEmpty].replace(
                  '\n',
                  '',
                )} ${lastStringArr[i]}`;
                lastStringArr[i] = undefined;
              } else {
                lastEmpty = i;
              }
            }
            lastStringArr = lastStringArr.filter(Boolean);
            const counterparty = lastStringArr
              .find((str) =>
                str.startsWith('Корресп. атауы / Наименование корресп.:'),
              )
              ?.replace('Корресп. атауы / Наименование корресп.: ', '');
            const comment = lastStringArr
              .find((str) => str.startsWith('Төлем мақсаты / Назн. платежа:'))
              ?.replace('Төлем мақсаты / Назн. платежа: ', '');
            return {
              currency: this.getFirstValidCellByColumn(['Валютасы\nВалюта', 5]),
              date: this.getFirstValidCellByColumn(['Кұні\nДата', 6]),
              debit: this.getFirstValidCellByColumn([
                'Дебет сомасы\nСумма дебет',
                7,
              ]),
              credit: this.getFirstValidCellByColumn([
                'Кредит сомасы\nСумма кредит',
                8,
              ]),
              counterparty,
              comment,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          isDESCOpOrder: true,
          preParserConfigs: {
            verticalAlign: 'one-line',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word?.includes('Құжат No'),
                (word) => word?.includes('Сумма кредит'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('Сумма кредит'),
                1,
              );
              self.defineOperation([
                (value) => /^([\s\d]+\.\d+)$/.test(value), // '22 200.00'
                (value) => /^([\s\d]+\.\d+)$/.test(value), // '22 200.00'
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
  };
}
