import { isEmpty } from 'class-validator';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BaseXLSXPreParser,
  Config,
  ImportResultItemMask,
  TIME_FORMAT,
} from '@finmap/import-parsers/base-import-parser-v3';
import { OPERATION_TYPES } from '@finmap/core-constants';

export class BankPivdennyImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        async isCurCase(file: File, preParser: BaseXLSXPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return rawDocument?.length && rawDocument[0].includes('Надруковано:');
        },
        proceedCase: (importDocument) => {
          const firstHeaderLine = this.findString(/^Деталі платежу/)?.raw;
          const endBodyLine = this.findString(/^Обороти:/)?.raw;
          const currencyLine = this.findString(/^Валюта:/)?.raw;
          const currency = importDocument[currencyLine]
            ?.find(
              (cell) => /^(\s*[A-Z]{3}\s*)$/.test(cell), // "UAH "
            )
            ?.trim();
          const debitColumn = importDocument[firstHeaderLine].findIndex(
            (cell) => /^Дебет/.test(cell),
          );
          const credirColumn = importDocument[firstHeaderLine].findIndex(
            (cell) => /^Кредит/.test(cell),
          );

          importDocument = importDocument.map((line) => {
            return line.filter(
              (cell, index) =>
                !isEmpty(cell) ||
                index === debitColumn ||
                index === credirColumn,
            );
          });

          this.setDocumentHeader(importDocument[firstHeaderLine]);

          importDocument = importDocument.filter(
            (line, index) =>
              line.some((cell) => !isEmpty(cell)) &&
              index < endBodyLine &&
              index > firstHeaderLine + 1 &&
              !line.some((cell) => /^Деталі платежу/.test(cell)),
          );

          this.setDocumentBody(importDocument);
          return (): ImportResultItemMask => ({
            dateAndTime: moment(
              this.getFirstValidCellByColumn(['Дата проводки']),
              'DD.MM.YYYY HH:mm:ss',
            ).toISOString(),
            debit: this.getFirstValidCellByColumn(['Дебет']),
            credit: this.getFirstValidCellByColumn(['Кредит']),
            counterparty: this.getFirstValidCellByColumn(['Кореспондент']),
            comment: this.getFirstValidCellByColumn(['Деталі платежу']),
            currency,
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта', 12]),
            type:
              this.getFirstValidCellByColumn(['Операція', 2]) === 'Кредит'
                ? OPERATION_TYPES.INC
                : OPERATION_TYPES.CON,
            date: this.getFirstValidCellByColumn(['Дата проведення', 9]),
            time: moment(
              this.getFirstValidCellByColumn(['Час проведення', 13]),
              TIME_FORMAT,
            ).toISOString(),
            sum: this.getFirstValidCellByColumn(['Сума', 11]),
            comment: [
              this.getFirstValidCellByColumn(['Найменування контрагента', 6]),
              this.getFirstValidCellByColumn(['Призначення платежу', 8]),
            ].join('; '),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
  };
}
