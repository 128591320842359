import { MenuItem, MenuList, Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EditIcon } from '../../atoms';
import { useStyles } from './styles';
import { UserFiltersProps } from './types';

export function UserFilters(props: UserFiltersProps) {
  const {
    filters,
    onItemClick,
    onEditClick,
    focusedRowId,
    onItemMouseEnter,
    onItemMouseLeave,
    onOpenFiltersDialog,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={cn(classes.popupRoot, classes.paddingRoot, classes.rootMargin)}
    >
      <Typography
        className={cn(classes.popupTitle, classes.paddingRow, classes.noMargin)}
      >
        {t('filters.user.emptyTitle').toUpperCase()}
      </Typography>
      <MenuList
        className={cn(
          classes.listRoot,
          classes.listRootMargin,
          'scrollable-list',
        )}
      >
        {filters.map((el) => (
          <MenuItem
            className={classes.menuRow}
            key={el._id}
            id={el._id}
            onClick={onItemClick}
            onMouseEnter={onItemMouseEnter}
            onMouseLeave={onItemMouseLeave}
          >
            <Typography className={classes.textOverflow}>{el.name}</Typography>
            <EditIcon
              onClick={onEditClick}
              className={
                focusedRowId === el._id ? classes.visible : classes.hidden
              }
            />
          </MenuItem>
        ))}
      </MenuList>
      <Typography
        className={cn(classes.popupLink, classes.paddingRow)}
        onClick={onOpenFiltersDialog}
      >
        {t('filters.user.createNew')}
      </Typography>
    </div>
  );
}

export default UserFilters;
