import { SplitPaymentProjectDetailedInfo } from '../../foundation/Operations/Components/SplittingPayment/types';
import {
  CustomPeriodEnum,
  FullPeriod,
} from '../../foundation/Operations/HOC/types';
import { AccountValue } from '../accounts/types';
import { JournalData } from '../journal/types';
import { AutoCompleteProps } from '../types';
import { ActionTypes, UPDATE_ONE_OPERATION_PENDING } from './actions';
import { UpdateOneOperationPayload } from './sagas.types';

export type Projects = {
  id: string;
  stake: number;
};

export type ResponseData = {
  sum: number | undefined;
  companyCurrencySum: number | undefined;
  exchangeRate: number | undefined;
  schedulePeriodId: number | undefined;
  tags: string[] | undefined;
  comment: string | undefined;
  companyId: string | undefined;
  date: number | undefined;
  dateOfPayment: number;
  projects: Projects[] | undefined;
  accountToId: string | undefined;
  scheduleEndDate: number | undefined;
  scheduleMaxCount: number | undefined;
  scheduleInterval: number | undefined;
  scheduleIntervalType: number | undefined;
  scheduleDays: string | undefined;
  approved: boolean;
};

export type CreateIncomeSaleResponseData = ResponseData & {
  clientId: string | undefined | null;
  categoryId: string | undefined | null;
  scheduled: boolean;
  periodStartTimestamp: number;
  periodEndTimestamp: number;
};

export type CreateIncomeInvestmentResponseData = ResponseData & {
  investorId: string | undefined | null;
  scheduled: boolean;
};

export type CreateIncomeLoanRepaymentResponseData = ResponseData & {
  creditorId: string | undefined | null;
  scheduled: boolean;
};

export type CreateIncomeLoanResponseData = ResponseData & {
  borrowerId: string | undefined | null;
  scheduled: boolean;
};

export type CreateTransferResponseData = ResponseData & {
  accountFromId: string | undefined;
  scheduled: boolean;
  isCopy: boolean;
};

export type CreateCancellationTaxResponseData = ResponseData & {
  accountFromId: string | undefined;
  taxPeriodId: number | undefined;
  taxPeriodStartTimestamp: number | undefined;
  taxPeriodEndTimestamp: number | undefined;
  scheduled: boolean;
  taxOrganisationId: string | null;
};

export type CreateCancellationSupplierResponseData = ResponseData & {
  accountFromId: string | undefined;
  supplierId: string | undefined | null;
  categoryId: string | undefined | null;
  scheduled: boolean;
  periodStartTimestamp: number;
  periodEndTimestamp: number;
};

export type CreateCancellationLoanRepaymentResponseData = ResponseData & {
  accountFromId: string | undefined;
  creditorId: string | undefined | null;
  scheduled: boolean;
};

export type CreateCancellationLoanResponseData = ResponseData & {
  accountFromId: string | undefined;
  borrowerId: string | undefined | null;
  scheduled: boolean;
};

export type CreateCancellationSalaryResponseData = ResponseData & {
  accountFromId: string | undefined;
  staffMemberId: string | undefined | null;
  salaryPeriodId: number | undefined;
  salaryPeriodStartTimestamp?: number | undefined;
  salaryPeriodEndTimestamp?: number | undefined;
  scheduled: boolean;
};

export type CreateCancellationDividendResponseData = ResponseData & {
  accountFromId: string | undefined;
  ownerId: string | undefined | null;
  scheduled: boolean;
};

export enum OperationType {
  income = 'income',
  consumption = 'consumption',
  transfer = 'transfer',
}

export enum OperationSubType {
  sale = 'sale',
  investment = 'investment',
  loanRepayment = 'loanRepayment',
  loanrepayment = 'loanrepayment',
  loan = 'loan',
  supplier = 'supplier',
  tax = 'tax',
  dividend = 'dividend',
  salary = 'salary',
  generic = 'generic',
}

export enum IncomeOperationSubType {
  sale = 'income_sale',
  investment = 'income_investment',
  loanRepayment = 'income_loanRepayment',
  loan = 'income_loan',
  generic = 'income_generic',
}

export enum ConsumptionOperationSubType {
  loanRepayment = 'consumption_loanRepayment',
  loan = 'consumption_loan',
  supplier = 'consumption_supplier',
  tax = 'consumption_tax',
  dividend = 'consumption_dividend',
  salary = 'consumption_salary',
  generic = 'consumption_generic',
}

export type Attachment = {
  contentId: string;
  previewId?: string;
  label: string;
  _id: string;
};

export type OperationsState = {
  isCopyComponent: boolean;
  createAndCopyState: boolean;
  amount: string | null;
  currencyAmount: string | null;
  exchangeRate: string | null;
  revertedExchangeRate: string | null;
  editOperationId: string | null;
  account: AccountValue | null;
  toAccount: AccountValue | null;
  incomeDate: number;
  repeats: FullPeriod;
  splitPaymentProjects: SplitPaymentProjectDetailedInfo[];
  revertState: boolean;
  selectedTags: any[] | null;
  comments: string;
  salaryPeriodId: CustomPeriodEnum | null;
  taxPeriodId: CustomPeriodEnum | null;
  startTimestamp: number;
  endTimestamp: number;
  dateOfPayment: number;
  dateOfPaymentPeriodId: CustomPeriodEnum | null;
  dateOfPaymentWasChanged: boolean;
  currencyValue:
    | (AutoCompleteProps & { symbol: string; isCrypto?: boolean })
    | null;
  loadingRates: boolean;
  isCompare?: boolean;
  isGeneric?: boolean;
  difference?: JournalData;
  counterpartyId?: string;
  canDelete?: boolean;
  canEdit?: boolean;
  attachment: Attachment | null;
  attachments: Attachment[];
  transactionSum: number | null;
  transactionCurrency: string | null;
  transactionCurrencyRate: number | null;
  botId?: string;
  externalId?: string;
  integrationId?: string;
  type: OperationType | null;
};

export type PeriodDateOfPaymentPayload = Pick<
  OperationsState,
  'startTimestamp' | 'endTimestamp' | 'incomeDate'
>;

export type SetCurrencyValuePayload = Pick<OperationsState, 'currencyValue'>;
export type SetDateOfPaymentWasChangedPayload = Pick<
  OperationsState,
  'dateOfPaymentWasChanged'
>;

export type SetTaxPeriodIdPayload = Pick<OperationsState, 'taxPeriodId'>;
export type SetSalaryPeriodIdPayload = Pick<OperationsState, 'salaryPeriodId'>;
export type SetCommentsPayload = Pick<OperationsState, 'comments'>;
export type SetSelectedTagsPayload = Pick<OperationsState, 'selectedTags'>;
export type SetSplitPaymentProjectsPayload = Pick<
  OperationsState,
  'splitPaymentProjects'
>;
export type SetRepeatsPayload = Pick<OperationsState, 'repeats'>;

export type SetScheduledPayload = {
  scheduled: boolean;
};

export type SetDateOfPaymentPayload = Pick<
  OperationsState,
  'startTimestamp' | 'endTimestamp' | 'dateOfPayment'
>;
export type SetIncomeDatePayload = Pick<OperationsState, 'incomeDate'>;
export type SetToAccountPayload = Pick<OperationsState, 'toAccount'>;
export type SetAmountPayload = Pick<OperationsState, 'amount'>;
export type SetEditOperationIdPayload = Pick<
  OperationsState,
  'editOperationId'
>;
export type SetExchangeRatePayload = Pick<OperationsState, 'exchangeRate'>;
export type SetRevertExchangeRatePayload = Pick<
  OperationsState,
  'revertedExchangeRate'
>;
export type SetCurrencyAmountPayload = Pick<OperationsState, 'currencyAmount'>;
export type SetStartTimeStampPayload = Pick<OperationsState, 'startTimestamp'>;
export type SetEndTimeStampPayload = Pick<OperationsState, 'endTimestamp'>;
export type SetIsCopyPayload = Pick<OperationsState, 'isCopyComponent'>;
export type SetAccountPayload = Pick<OperationsState, 'account'>;
export type SetCreateAndCopyStatePayload = Pick<
  OperationsState,
  'createAndCopyState'
>;

export type SetIsCopyAction = {
  type: ActionTypes.SET_IS_COPY_COMPONENT;
  payload: SetIsCopyPayload;
};

export type SetEndTimeStampAction = {
  type: ActionTypes.SET_END_TIME_STAMP;
  payload: SetEndTimeStampPayload;
};

export type SetStartTimeStampAction = {
  type: ActionTypes.SET_START_TIME_STAMP;
  payload: SetStartTimeStampPayload;
};

export type SetExchangeRateAction = {
  type: ActionTypes.SET_EXCHANGE_RATE;
  payload: SetExchangeRatePayload;
};

export type SetCurrencyAmountAction = {
  type: ActionTypes.SET_CURRENCY_AMOUNT;
  payload: SetCurrencyAmountPayload;
};

export type SetEditOperationIdAction = {
  type: ActionTypes.SET_EDIT_OPERATION_ID;
  payload: SetEditOperationIdPayload;
};

export type SetToAccountAction = {
  type: ActionTypes.SET_TO_ACCOUNT;
  payload: SetToAccountPayload;
};

export type SetIncomeDateAction = {
  type: ActionTypes.SET_INCOME_DATE;
  payload: SetIncomeDatePayload;
};

export type SetDateOfPaymentAction = {
  type: ActionTypes.SET_DATE_OF_PAYMENT;
  payload: { dateOfPayment: SetDateOfPaymentPayload };
};

export type SetRepeatsAction = {
  type: ActionTypes.SET_REPEATS;
  payload: SetRepeatsPayload;
};

export type SetSplitPaymentProjectsAction = {
  type: ActionTypes.SET_SPLIT_PAYMENT_PROJECTS;
  payload: SetSplitPaymentProjectsPayload;
};

export type SetSelectedTagsAction = {
  type: ActionTypes.SET_SELECTED_TAGS;
  payload: SetSelectedTagsPayload;
};

export type SetCommentsAction = {
  type: ActionTypes.SET_COMMENTS;
  payload: SetCommentsPayload;
};

export type SetSalaryPeriodIdAction = {
  type: ActionTypes.SET_SALARY_PERIOD_ID;
  payload: SetSalaryPeriodIdPayload;
};

export type SetDateOfPaymentWasChangedAction = {
  type: ActionTypes.SET_DATE_OF_PAYMENT_WAS_CHANGED;
  payload: SetDateOfPaymentWasChangedPayload;
};

export type SetTaxPeriodIdAction = {
  type: ActionTypes.SET_TAX_PERIOD_ID;
  payload: SetTaxPeriodIdPayload;
};

export type SetCurrencyValueAction = {
  type: ActionTypes.SET_CURRENCY_VALUE;
  payload: SetCurrencyValuePayload;
};

export type SetAmountAction = {
  type: ActionTypes.SET_AMOUNT;
  payload: SetAmountPayload;
};

export type SetRevertStateAction = {
  type: ActionTypes.SET_REVERT_STATE;
  payload: SetRevertStatePayload;
};

export type SetRevertedExchangeRateAction = {
  type: ActionTypes.SET_REVERTED_EXCHANGE_RATE;
  payload: SetRevertExchangeRatePayload;
};

export type SetAccountAction = {
  type: ActionTypes.SET_ACCOUNT;
  payload: SetAccountPayload;
};

export type SetCreateAndCopyStateAction = {
  type: ActionTypes.SET_CREATE_AND_COPY_STATE;
  payload: SetCreateAndCopyStatePayload;
};

export type SetOperationPropsPayload = {
  data: JournalData;
};

export type SetRevertStatePayload = {
  revertState: boolean;
};

export type UpdateOneOperationAction = {
  type: typeof UPDATE_ONE_OPERATION_PENDING;
  payload: UpdateOneOperationPayload;
};
