import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import { Button, MenuItem, MenuList, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import cn from 'classnames';

import { BelvoInstitutionFormField } from '../../../store/integrations/types';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import PopperComponent from '../../../components/PopperComponent';
import { useStyles } from './styles';

type Props = {
  field: BelvoInstitutionFormField;
  index: number;
  setError(hasError: boolean, index: number): void;
  setValue(value: string, index: number): void;
};

const BelvoField = (props: Props) => {
  const { field, setError, setValue, index } = props;

  const [inputValue, setInputValue] = useState<null | string>(null);
  const [selectLabel, setSelectLabel] = useState<null | string>(null);
  const [isError, setIsError] = useState<undefined | boolean>(undefined);
  const [showValuesList, setShowValuesList] = useState(false);
  const classes = useStyles();
  const anchorRefPeriod = useRef<HTMLButtonElement>(null);

  let type = 'text';
  if (field.type === 'string') {
    type = 'text';
  } else if (field.type === 'password') {
    type = 'password';
  }

  const handleCloseValuesList = useCallback(() => {
    setShowValuesList(false);
  }, []);

  const handleToggleConditionsList = useCallback(() => {
    setShowValuesList((value) => !value);
  }, []);

  const handleSelectCondition = useCallback(
    (event: SyntheticEvent) => {
      const { id } = event.currentTarget;
      if (field.values) {
        const currentValue = field.values.find((el) => el.code === id);
        //setSelectValue(id);
        setSelectLabel(currentValue?.label || null);
        setValue(id, index);
        handleCloseValuesList();
      }
    },
    [setValue, handleCloseValuesList, index, field],
  );

  const handleOnChangeInputValue = useCallback(
    (value: string) => {
      let isValid = true;
      if (field.validation) {
        const regex = new RegExp(field.validation);
        isValid = regex.test(value);
      }
      if (field.optional && value.length === 0) {
        isValid = true;
      }

      setIsError(!isValid || undefined);
      setError(!isValid, index);
      setValue(value, index);
      setInputValue(value);
    },
    [field, index, setValue, setError],
  );

  if (field.type === 'hidden') {
    return null;
  }

  if (field.type === 'select') {
    if (!field.values) return null;
    if (!selectLabel) {
      if (typeof field.pre_selected === 'number') {
        setSelectLabel(field.values[field.pre_selected].label);
      } else {
        setSelectLabel(field.values[0].label);
      }
    }
    return (
      <div className={classes.paddings}>
        <Button
          ref={anchorRefPeriod}
          className={cn(classes.conditionButtonContainer, classes.blockMargin)}
          onClick={handleToggleConditionsList}
          fullWidth
        >
          <Typography>{selectLabel}</Typography>
          {showValuesList ? (
            <ArrowDropUpIcon className={classes.arrowIcon} />
          ) : (
            <ArrowDropDownIcon className={classes.arrowIcon} />
          )}
        </Button>
        {showValuesList && (
          <PopperComponent
            disablePortal
            anchorRef={anchorRefPeriod}
            onClose={handleCloseValuesList}
            placement="bottom-start"
            width={260}
            paperClasses={classes.paper}
          >
            <MenuList id="menu-list-grow">
              {field.values.map((value) => {
                return (
                  <MenuItem
                    key={value.code}
                    id={value.code}
                    onClick={handleSelectCondition}
                  >
                    <Typography>{value.label}</Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </PopperComponent>
        )}
      </div>
    );
  }

  return (
    <>
      <TextFieldComponent
        value={inputValue}
        onChange={handleOnChangeInputValue}
        fullWidth
        type={type}
        placeholder={field.placeholder || field.name}
        isError={isError}
        errorText={field.validation_message}
      />
    </>
  );
};

export default React.memo(BelvoField);
