import 'pdfjs-dist/legacy/build/pdf.worker.entry';
import _ from 'lodash';
import * as pdfJsLib from 'pdfjs-dist/legacy/build/pdf.js';

import { searchFunc } from '../base-import-parser-v3.dto';
import { BaseImportPreParser } from './base-import-preparser';
import moment from 'moment';

export type alignMentTypes = 'top' | 'middle' | 'bottom' | 'one-line';

export interface PDFElement {
  str: string;
  width: number;
  height: number;
  x: number;
  y: number;
  hasEOL: boolean;
}

export type searchFuncForDefiners = (
  word: string,
  etc?: { fullElement?: PDFElement },
) => boolean;

export class BasePDFPreParser extends BaseImportPreParser {
  protected SPACE_LENGTH_PX: number;
  protected PAGE_SEPARATORS_LENGTH_PX: number;
  protected MAX_INTERLINE_SPACING_PX: number;
  protected INTERLINE_SPACING_ACCURACY: number;
  protected VERTICAL_ALIGN: alignMentTypes;
  protected SEPARATORS_DISTANCE_MODIFIER: number;
  protected rawDocument: PDFElement[];
  protected documentHeader: PDFElement[];
  protected documentBody: string[][];
  protected separatorsBorders: number[] = [];
  protected DEBUG: boolean;

  async getRawData(
    buffer: ArrayBuffer,
    {
      rotate = false,
    }: {
      rotate?: boolean;
    } = {},
  ): Promise<PDFElement[]> {
    const document = await pdfJsLib.getDocument(buffer as Uint8Array).promise;

    this.rawDocument = [];

    for (let i = 1; i <= document.numPages; i++) {
      const page = await document.getPage(i);
      const pageHeight = page.view[3];
      const pageContent = await page.getTextContent();
      const items = pageContent.items;
      for (const item of items as {
        str: string;
        dir: string;
        fontName: string;
        hasEOL: boolean;
        width: number;
        height: number;
        transform: number[];
      }[]) {
        const str = item.str;
        const width = Math.round(item.width);
        const height = Math.round(item.height);
        let x = Math.round(item.transform[4]);
        let y = Math.round(
          pageHeight - item.transform[5] - item.height + pageHeight * (i - 1),
        );
        if (rotate) {
          x = Math.round(item.transform[5]);
          y = Math.round(item.transform[4] + pageHeight * (i - 1));
        }

        this.rawDocument.push({
          str,
          width,
          height,
          x,
          y,
          hasEOL: item.hasEOL,
        });
      }
    }

    this.rawDocument = this.rawDocument.filter((r) => Boolean(r.str?.trim()));

    this.rawDocument = _.sortBy(this.rawDocument, ['y', 'x']);

    return this.rawDocument;
  }

  async preParse(
    buffer: ArrayBuffer,
    {
      spaceLengthPx = 4,
      pageSeparatorsLengthPx = 4,
      maxInterlineSpacingPx = 20,
      interlineSpacingAccuracy = 0,
      verticalAlign = 'top',
      rotate = false,
      separatorsDistanceModifier = 1,
      prepareRawPDF = () => ({}),
      debug = false,
      prepareRowConfig,
    }: {
      spaceLengthPx?: number;
      pageSeparatorsLengthPx?: number;
      maxInterlineSpacingPx?: number;
      interlineSpacingAccuracy?: number;
      verticalAlign?: alignMentTypes;
      rotate?: boolean;
      separatorsDistanceModifier?: number;
      prepareRawPDF: (self: BasePDFPreParser) => void;
      debug?: boolean;
      prepareRowConfig?: any;
    },
  ): Promise<string[][]> {
    await this.getRawData(buffer, { rotate });

    this.SPACE_LENGTH_PX = spaceLengthPx;
    this.PAGE_SEPARATORS_LENGTH_PX = pageSeparatorsLengthPx;
    this.MAX_INTERLINE_SPACING_PX = maxInterlineSpacingPx;
    this.VERTICAL_ALIGN = verticalAlign;
    this.INTERLINE_SPACING_ACCURACY = interlineSpacingAccuracy;
    this.SEPARATORS_DISTANCE_MODIFIER = separatorsDistanceModifier;
    this.DEBUG = debug;

    this.documentHeader = [];
    this.documentBody = [];

    if (this.DEBUG)
      console.log('LOGS-TO-RECORD', 'rawDocument', this.rawDocument);

    if (!prepareRowConfig) {
      prepareRawPDF(this);
    } else {
      if (prepareRowConfig.findHeader) {
        const [fromFunc, toFunc] = this.configToCondition(
          prepareRowConfig.findHeader,
        );
        this.findHeader(fromFunc, toFunc);
      }
      if (prepareRowConfig.delete) {
        for (const conf of prepareRowConfig.delete) {
          const [fromFunc, toFunc, count] = this.configToCondition(conf);
          this.deleteFromTo(fromFunc, toFunc, count);
        }
      }
      if (prepareRowConfig.define) {
        this.defineOperation(this.configToDefine(prepareRowConfig.define));
      }
    }

    return [this.documentHeader.map((h) => h.str), ...this.documentBody];
  }

  findHeader(from: searchFunc, to: searchFunc) {
    const fromIndex = this.rawDocument.findIndex((r, i) => {
      return from(r?.str, {
        nextWord: this.rawDocument[i + 1]?.str,
        prevWord: this.rawDocument[i - 1]?.str,
      });
    });
    const toIndex = this.rawDocument.findIndex((r, i) => {
      return to(r.str, {
        nextWord: this.rawDocument[i + 1]?.str,
        prevWord: this.rawDocument[i - 1]?.str,
      });
    });
    if (fromIndex !== -1 && toIndex !== -1) {
      this.documentHeader = this.rawDocument
        .slice(fromIndex, toIndex + 1)
        .filter((h) => Boolean(h.width));
      const joinedDocumentHeader = [{ ...this.documentHeader[0] }];
      let joinedIndex = 0;
      for (let i = 1; i < this.documentHeader.length; i++) {
        const headerEl = this.documentHeader[i];
        const headerPrevEl = this.documentHeader[i - 1];
        if (
          headerPrevEl.width &&
          headerEl.x === headerPrevEl.x + headerPrevEl.width
        ) {
          joinedDocumentHeader[joinedIndex].str += headerEl.str;
          joinedDocumentHeader[joinedIndex].width += headerEl.width;
        } else if (
          headerPrevEl.width &&
          headerEl.x >= headerPrevEl.x + headerPrevEl.width &&
          headerEl.x <=
            headerPrevEl.x + headerPrevEl.width + this.SPACE_LENGTH_PX
        ) {
          joinedDocumentHeader[joinedIndex].str += ` ${headerEl.str}`;
          joinedDocumentHeader[joinedIndex].width +=
            this.SPACE_LENGTH_PX + headerEl.width;
        } else {
          joinedIndex++;
          joinedDocumentHeader.push({ ...headerEl });
        }
      }
      this.documentHeader = joinedDocumentHeader;
    }
    const comparedHeaders: PDFElement[] = [];
    for (let i = 0; i < this.documentHeader.length; i++) {
      const curHeader = this.documentHeader[i];
      const sameHeaderIndex = comparedHeaders.findIndex(
        (h) =>
          h.y !== curHeader.y &&
          ((curHeader.x >= h.x && curHeader.x <= h.x + h.width) ||
            (curHeader.x + curHeader.width >= h.x &&
              curHeader.x + curHeader.width <= h.x + h.width) ||
            (h.x >= curHeader.x && h.x <= curHeader.x + curHeader.width) ||
            (h.x + h.width >= curHeader.x &&
              h.x + h.width <= curHeader.x + curHeader.width)),
      );
      if (sameHeaderIndex !== -1) {
        const prevHeader = { ...comparedHeaders[sameHeaderIndex] };
        comparedHeaders[sameHeaderIndex].str += `\n${curHeader.str}`;
        comparedHeaders[sameHeaderIndex].y = Math.min(
          prevHeader.y,
          curHeader.y,
        );
        comparedHeaders[sameHeaderIndex].x = Math.min(
          prevHeader.x,
          curHeader.x,
        );
        comparedHeaders[sameHeaderIndex].width =
          Math.max(
            prevHeader.x + prevHeader.width,
            curHeader.x + curHeader.width,
          ) - comparedHeaders[sameHeaderIndex].x;
        comparedHeaders[sameHeaderIndex].height =
          curHeader.y + curHeader.height - comparedHeaders[sameHeaderIndex].y;
      } else comparedHeaders.push({ ...curHeader });
    }
    this.documentHeader = _.sortBy(comparedHeaders, ['x']);
    if (this.DEBUG)
      console.log('LOGS-TO-RECORD', 'documentHeader', this.documentHeader);
  }

  deleteFromTo(from?: searchFunc, to?: searchFunc, times = Infinity) {
    let fromIndex, toIndex;
    let count = 0;
    while (count < times) {
      count++;
      fromIndex = from
        ? this.rawDocument.findIndex((r, i) => {
            return from(r?.str, {
              nextWord: this.rawDocument[i + 1]?.str,
              prevWord: this.rawDocument[i - 1]?.str,
            });
          })
        : 0;
      if (fromIndex === -1) break;
      const slicedFromIndex = this.rawDocument.slice(fromIndex + 1);
      toIndex = to
        ? slicedFromIndex.findIndex((r, i) => {
            return to(r.str, {
              nextWord: slicedFromIndex[i + 1]?.str,
              prevWord: slicedFromIndex[i - 1]?.str,
            });
          })
        : this.rawDocument.length - 1;
      toIndex += fromIndex + 1;
      if (toIndex <= fromIndex) break;
      this.rawDocument = [
        ...this.rawDocument.slice(0, fromIndex),
        ...this.rawDocument.slice(toIndex + 1),
      ];
    }
  }

  defineOperation(definers: searchFuncForDefiners[]) {
    if (this.DEBUG)
      console.log(
        'LOGS-TO-RECORD',
        'rawDocumentAfterDeleting',
        this.rawDocument,
      );

    const minX = _.minBy(this.rawDocument, (r) => r.x)?.x as number;
    const maxXObj = _.maxBy(this.rawDocument, (r) => r.x);
    const maxX = ((maxXObj?.x as number) + maxXObj?.width) as number;

    const separatorsNumber = Math.ceil(
      (maxX - minX) / this.PAGE_SEPARATORS_LENGTH_PX,
    );
    const wordOccurrence: number[] = new Array(separatorsNumber + 1).fill(0);
    const separatorsBorders: number[] = new Array(separatorsNumber + 1)
      .fill(0)
      .reduce((acc, _, i) => {
        acc.push(i * this.PAGE_SEPARATORS_LENGTH_PX + minX);
        return acc;
      }, []);

    const docDividedByY: PDFElement[][] = [];
    let prevY = 0;
    for (let i = 0; i < this.rawDocument.length; i++) {
      const curElement = this.rawDocument[i];
      if (prevY === curElement.y)
        docDividedByY[docDividedByY.length - 1].push(curElement);
      else {
        docDividedByY.push([]);
        docDividedByY[docDividedByY.length - 1].push(curElement);
        prevY = curElement.y;
      }

      for (let j = 1; j < separatorsBorders.length; j++) {
        const curBorder = separatorsBorders[j];
        const prevBorder = separatorsBorders[j - 1];
        if (
          (curBorder > curElement.x &&
            curBorder < curElement.x + curElement.width) ||
          (prevBorder > curElement.x &&
            prevBorder < curElement.x + curElement.width)
        )
          wordOccurrence[j - 1]++;
      }
    }

    const definedLines: PDFElement[][] = [];
    for (let i = 0; i < docDividedByY.length; i++) {
      let copiedLine = [...docDividedByY[i]];
      let definedAsOp = true;
      for (let j = 0; j < definers.length; j++) {
        const definer = definers[j];
        const index = copiedLine.findIndex((r) =>
          definer(r.str, { fullElement: r }),
        );
        if (index !== -1) {
          delete copiedLine[index];
          copiedLine = copiedLine.filter((v) => Boolean(v));
        } else {
          definedAsOp = false;
          break;
        }
      }
      if (definedAsOp) definedLines.push(docDividedByY[i]);
    }

    this.separatorsBorders = [];
    for (let i = 1; i < this.documentHeader.length; i++) {
      const curHeader = this.documentHeader[i];
      const prevHeader = this.documentHeader[i - 1];
      const halfAverageDist =
        ((curHeader.x - prevHeader.x - prevHeader.width) / 2) *
        this.SEPARATORS_DISTANCE_MODIFIER;
      const middlePosX = prevHeader.x + prevHeader.width + halfAverageDist;
      const pretenders: {
        [key: number]: number;
      } = {};
      for (let i = 0; i < separatorsBorders.length; i++) {
        if (
          separatorsBorders[i] > prevHeader.x - halfAverageDist &&
          separatorsBorders[i] < curHeader.x + halfAverageDist
        )
          pretenders[i] = wordOccurrence[i];
      }

      const rightLineIndex = parseInt(
        (
          _.minBy(
            Object.entries(pretenders),
            ([key, value]) =>
              Math.abs(middlePosX - separatorsBorders[key]) * (value + 1),
          ) as [string, number]
        )[0],
        10,
      );

      this.separatorsBorders.push(separatorsBorders[rightLineIndex]);
    }

    if (this.DEBUG)
      console.log(
        'LOGS-TO-RECORD',
        'separatorsBorders',
        this.separatorsBorders,
      );

    for (let i = 0; i < definedLines.length; i++) {
      let curOperation: PDFElement[] = definedLines[i];
      const curParsedOperation: string[] = new Array(
        this.documentHeader.length,
      ).fill('');
      const curParsedOperationLevels: number[] = new Array(
        this.documentHeader.length,
      ).fill(null);

      let nearestSpacing: null | number = null;
      if (this.VERTICAL_ALIGN === 'top' || this.VERTICAL_ALIGN === 'middle') {
        const opIndex = docDividedByY.findIndex(
          (line) => line[0].y === curOperation[0].y,
        );
        const nextOpIndex = docDividedByY.findIndex(
          (line) =>
            line[0].y === (definedLines[i + 1] ? definedLines[i + 1][0].y : -1),
        );

        let additionalRows: PDFElement[][] = [];
        let lastY = curOperation[0].y;
        for (
          let j = opIndex;
          j < (nextOpIndex === -1 ? docDividedByY.length : nextOpIndex);
          j++
        ) {
          if (lastY + this.MAX_INTERLINE_SPACING_PX < docDividedByY[j][0].y)
            break;
          additionalRows.push(docDividedByY[j]);
          lastY = docDividedByY[j][0].y;
        }
        if (additionalRows.length > 1) {
          nearestSpacing = additionalRows[1][0].y - curOperation[0].y;
          let skipNext = false;
          additionalRows = additionalRows.filter((r, i) => {
            if (skipNext) return false;
            const take =
              i !== 0 &&
              r[0].y - additionalRows[i - 1][0].y >=
                nearestSpacing - this.INTERLINE_SPACING_ACCURACY &&
              r[0].y - additionalRows[i - 1][0].y <=
                nearestSpacing + this.INTERLINE_SPACING_ACCURACY;
            if (!take && i !== 0) skipNext = true;
            return take;
          });
          curOperation = [
            ...curOperation,
            ...additionalRows.reduce((acc, cur) => [...acc, ...cur], []),
          ];
        }
      }
      if (
        this.VERTICAL_ALIGN === 'bottom' ||
        this.VERTICAL_ALIGN === 'middle'
      ) {
        const opIndex = docDividedByY.findIndex(
          (line) => line[0].y === curOperation[0].y,
        );
        const prevOpIndex = docDividedByY.findIndex(
          (line) =>
            line[0].y === (definedLines[i - 1] ? definedLines[i - 1][0].y : -1),
        );

        let additionalRows: PDFElement[][] = [];
        let lastY = curOperation[0].y;
        for (
          let j = opIndex;
          j >= (prevOpIndex === -1 ? 0 : prevOpIndex + 1);
          j--
        ) {
          if (lastY - this.MAX_INTERLINE_SPACING_PX > docDividedByY[j][0].y)
            break;
          additionalRows.push(docDividedByY[j]);
          lastY = docDividedByY[j][0].y;
        }
        if (additionalRows.length > 1) {
          nearestSpacing = nearestSpacing
            ? nearestSpacing
            : curOperation[0].y - additionalRows[1][0].y;
          let skipNext = false;
          additionalRows = additionalRows.filter((r, i) => {
            if (skipNext) return false;
            const take =
              i !== 0 &&
              additionalRows[i - 1][0].y - r[0].y >=
                nearestSpacing - this.INTERLINE_SPACING_ACCURACY &&
              additionalRows[i - 1][0].y - r[0].y <=
                nearestSpacing + this.INTERLINE_SPACING_ACCURACY;
            if (!take && i !== 0) skipNext = true;
            return take;
          });
          curOperation = [
            ...additionalRows.reduce((acc, cur) => [...cur, ...acc], []),
            ...curOperation,
          ];
        }
      }

      for (let j = 0; j < curOperation.length; j++) {
        const curOperationEl = curOperation[j];
        const fitArr: number[] = [];

        for (let k = 0; k <= this.separatorsBorders.length; k++) {
          const curLineX = this.separatorsBorders[k]
            ? this.separatorsBorders[k]
            : maxX;
          const prevLineX = k === 0 ? minX : this.separatorsBorders[k - 1];

          let fit = 0;
          if (curOperationEl.x >= prevLineX && curOperationEl.x <= curLineX) {
            if (curOperationEl.x + curOperationEl.width > curLineX) {
              fit = curLineX - curOperationEl.x;
            } else {
              fit = curOperationEl.width;
            }
          } else if (
            curOperationEl.x + curOperationEl.width >= prevLineX &&
            curOperationEl.x + curOperationEl.width <= curLineX
          ) {
            fit = curOperationEl.x + curOperationEl.width - prevLineX;
          } else if (
            curOperationEl.x <= prevLineX &&
            curOperationEl.x + curOperationEl.width >= curLineX
          ) {
            fit = curLineX - prevLineX;
          }

          fitArr.push(fit);
        }

        const maxFitIndex = parseInt(
          (
            _.maxBy(Object.entries(fitArr), ([, value]) => value) as [
              string,
              number,
            ]
          )[0],
          10,
        );

        if (curParsedOperation[maxFitIndex]) {
          if (curParsedOperationLevels[maxFitIndex] === curOperationEl.y)
            curParsedOperation[maxFitIndex] += ` ${curOperationEl.str}`;
          else {
            curParsedOperation[maxFitIndex] += `\n${curOperationEl.str}`;
            curParsedOperationLevels[maxFitIndex] = curOperationEl.y;
          }
        } else {
          curParsedOperation[maxFitIndex] = curOperationEl.str;
          curParsedOperationLevels[maxFitIndex] = curOperationEl.y;
        }
      }

      this.documentBody.push(curParsedOperation);
    }
  }

  saveToStore(field: string, from: searchFunc, to: searchFunc) {
    const fromIndex = from
      ? this.rawDocument.findIndex((r, i) => {
          return from(r?.str, {
            nextWord: this.rawDocument[i + 1]?.str,
            prevWord: this.rawDocument[i - 1]?.str,
          });
        })
      : 0;
    const toIndex = to
      ? this.rawDocument.findIndex((r, i) => {
          return to(r.str, {
            nextWord: this.rawDocument[i + 1]?.str,
            prevWord: this.rawDocument[i - 1]?.str,
          });
        })
      : this.rawDocument.length - 1;
    if (fromIndex !== -1 && toIndex !== -1) {
      this.store[field] = [
        ...this.rawDocument.slice(0, fromIndex),
        ...this.rawDocument.slice(toIndex + 1),
      ].reduce((acc: string, cur: PDFElement, index, array) => {
        if (index === 0) return acc + cur.str;
        if (cur.y === array[index - 1].y) return `${acc} ${cur.str}`;
        return `${acc}\n${cur.str}`;
      }, '');
    }
  }

  private replaceWord(cond, word) {
    if (cond.replace) {
      for (const rep of cond.replace) {
        word = word.replaceAll(rep.from, rep.to);
      }
    }
    return word;
  }

  private compareValues(cond, word): boolean {
    const replacedWord = this.replaceWord(cond, word);
    if (cond.eq) {
      return cond.eq === replacedWord;
    }
    if (cond.neq) {
      return cond.neq !== replacedWord;
    }
    if (cond.is) {
      return cond.is;
    }
    if (cond.in) {
      return replacedWord.includes(cond.in);
    }
    if (cond.dateFormat) {
      return moment(replacedWord, cond.dateFormat, true).isValid();
    }
    if (cond.isNum === true) {
      return !isNaN(Number(replacedWord));
    }
    if (cond.isNum === false) {
      return isNaN(Number(replacedWord));
    }
  }

  private defineCondition(cond, word, etc): boolean {
    if (cond.or) {
      const res = cond.or.some((el) => this.defineCondition(el, word, etc));
      if (!res) return false;
    }

    if (cond.and) {
      const res = cond.and.every((el) => this.defineCondition(el, word, etc));
      if (!res) return false;
    }

    if (cond.word) {
      if (!this.compareValues(cond.word, word)) return false;
    }

    if (cond.nextWord) {
      if (!this.compareValues(cond.nextWord, etc.nextWord)) return false;
    }

    if (cond.prevWord) {
      if (!this.compareValues(cond.prevWord, etc.prevWord)) return false;
    }

    return true;
  }

  private configToCondition(config): any[] {
    const result = [];
    if (config.from) {
      result.push((word, etc) => {
        return config.from.every((el) => this.defineCondition(el, word, etc));
      });
    } else {
      result.push(undefined);
    }
    if (config.to) {
      result.push((word, etc) => {
        return config.to.every((el) => this.defineCondition(el, word, etc));
      });
    } else {
      result.push(undefined);
    }
    if (config.count) result.push(config.count);

    return result;
  }

  private configToDefine(config) {
    return config.map((conf) => (value) => {
      return this.compareValues(conf, value);
    });
  }
}
