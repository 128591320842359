import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize';

import CustomButton from '../../components/Button';
import { SHOW_GROW_SURF_REFERRAL } from '../../constants/featureToggles/featureToggle';
import { Routes } from '../../constants/routes';
import useUnleash from '../../hooks/useUnleash';
import { getUser } from '../../store/auth/selectors';
import { selectCurrentCompany } from '../../store/company/selectors';
import journalApi from '../../store/journal/api';
import { useStyles } from './styles';

function SuccessPayment() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  const showGrowsurfRefferal = useUnleash(SHOW_GROW_SURF_REFERRAL);

  const user = useSelector(getUser);
  const company = useSelector(selectCurrentCompany);

  const params = new URLSearchParams(search);

  const paymentId = params.get('id');
  const state = params.get('state');

  if (paymentId && state === 'succeeded' && showGrowsurfRefferal) {
    if (window.growsurf) {
      window.growsurf.triggerReferral({
        email: user?.email,
        company: company?.name,
      });
    }
  }

  const { width, height } = useWindowSize();
  const [success, setSuccess] = useState(false);

  const handleClick = useCallback(async () => {
    const { data: logCount } = await journalApi.getOperationsCount();

    if (logCount) {
      history.replace(Routes.LOG);
    } else {
      history.replace(Routes.ON_BOARDING);
    }
  }, [history]);

  useEffect(() => {
    setTimeout(() => {
      setSuccess(true);
    }, 3000);
  }, []);

  return (
    <div className={classes.root}>
      <Confetti width={width} height={height} />
      <Typography className={classes.text}>
        {t('subscription.payment.congratulation')}
      </Typography>
      <Typography className={cn(classes.text, classes.margin)}>
        {t('subscription.payment.paid')}
      </Typography>
      <CustomButton
        title={t('subscription.payment.buttonText')}
        action={handleClick}
        className={cn(classes.button, success && classes.showButton)}
      />
    </div>
  );
}

export default React.memo(SuccessPayment);
