import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { normalize } from '@finmap/core-utils';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import { Ga4Errors } from '../../../components/TextField/TextFieldComponent.types';
import { SHOW_URKSIB_BANK_INTEGRATION } from '../../../constants/featureToggles/integrationsFeatureToggles';
import useUnleash from '../../../hooks/useUnleash';
import { selectSettingsAccounts } from '../../../store/accounts/selectors';
import integrationActions from '../../../store/integrations/actions';
import { CreateBankToAccountIntegrationPayload } from '../../../store/integrations/sagas.types';
import { getLoadingMonoIntegration } from '../../../store/integrations/selectors';
import { IntegrationTypeId } from '../../../store/integrations/types';
import { dateToUTC } from '../../../utils/dateToUTC';
import CheckBoxDialog from '../../Integrations/CheckBox';
import MonoIntegrationDialog from '../../Integrations/MonoDialog';
import PayoneerFirstDialog from '../../Integrations/Payoneer/PayoneerFirstDialog';
import PosterDialog from '../../Integrations/PosterDialog';
import Private24CredentialsDialog from '../../Integrations/Private24Dialogs/Private24';
import Private24BusinessCredentialsDialog from '../../Integrations/Private24Dialogs/Private24Business';
import PumbBusinessDialog from '../../Integrations/PumbBusinessDialog';
import UkrsibDialog from '../../Integrations/UkrsibBank/FirstStepDialog';
import WesternBidDialog from '../../Integrations/WesternBid';
import { Props } from './types';

function IntegrationBankToNewAccountDialog(props: Props) {
  const { integrationTypeId: integrationId, onClose } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const accounts = useSelector(selectSettingsAccounts);
  const loadingMonoIntegration = useSelector(getLoadingMonoIntegration);

  const showUkrsibBankIntegration = useUnleash(SHOW_URKSIB_BANK_INTEGRATION);

  const [name, setName] = useState('');
  const [error, setError] = useState({
    name: '',
    date: '',
  });

  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const [showMonoIntegrationDialog, setShowMonoIntegrationDialog] =
    useState(false);
  const [showPumbIntegrationDialog, setShowPumbIntegrationDialog] =
    useState(false);
  const [showWesternBidIntegrationDialog, setShowWesternBidIntegrationDialog] =
    useState(false);
  const [showCheckBoxIntegrationDialog, setShowCheckBoxIntegrationDialog] =
    useState(false);
  const [showPosterIntegrationDialog, setShowPosterIntegrationDialog] =
    useState(false);

  const [showPayoneerIntegrationDialog, setShowPayoneerIntegrationDialog] =
    useState(false);

  const [showPrivat24IntegrationDialog, setShowPrivat24IntegrationDialog] =
    useState(false);
  const [
    showPrivat24BusinessIntegrationDialog,
    setShowPrivat24BusinessIntegrationDialog,
  ] = useState(false);
  const [showUkrsibIntegrationDialog, setShowUkrsibIntegrationDialog] =
    useState(false);

  const handleChangeDate = useCallback((value: any) => {
    setSelectedDate(value);
    setError((errors) => ({
      ...errors,
      date: '',
    }));
  }, []);

  const handleChangeName = useCallback((value: any) => {
    setName(value);
    setError((errors) => ({
      ...errors,
      name: '',
    }));
  }, []);

  const handleCloseUkrsibDialog = useCallback(() => {
    setShowUkrsibIntegrationDialog(false);
  }, []);

  const handleClosePumbDialog = useCallback(() => {
    setShowPrivat24IntegrationDialog(false);
  }, []);

  const handleCloseWesternBidDialog = useCallback(() => {
    setShowCheckBoxIntegrationDialog(false);
  }, []);

  const handleClosePosterDialog = useCallback(() => {
    setShowPosterIntegrationDialog(false);
  }, []);

  const handleClosePayoneerDialog = useCallback(() => {
    setShowPayoneerIntegrationDialog(false);
  }, []);

  const handleClosePrivatDialog = useCallback(() => {
    setShowPrivat24IntegrationDialog(false);
  }, []);

  const handleClosePrivat24BusinessDialog = useCallback(() => {
    setShowPrivat24BusinessIntegrationDialog(false);
  }, []);

  const handleSetupIntegration = useCallback(() => {
    const isAccountExist = accounts.some(
      (el) => el.normalizedLabel === normalize(name),
    );

    if (isAccountExist) {
      setError((errors) => ({
        ...errors,
        name: t('errors.create.account.exist'),
      }));

      return;
    }

    if (!name) {
      setError((errors) => ({
        ...errors,
        name: t('system.fieldMustFilled'),
      }));

      return;
    }

    if (integrationId === IntegrationTypeId.MonoPersonal) {
      setShowMonoIntegrationDialog(true);
    } else if (integrationId === IntegrationTypeId.Private24Personal) {
      setShowPrivat24IntegrationDialog(true);
    } else if (integrationId === IntegrationTypeId.Private24Business) {
      setShowPrivat24BusinessIntegrationDialog(true);
    } else if (integrationId === IntegrationTypeId.Pumb) {
      setShowPumbIntegrationDialog(true);
    } else if (integrationId === IntegrationTypeId.WesternBid) {
      setShowWesternBidIntegrationDialog(true);
    } else if (integrationId === IntegrationTypeId.CheckBox) {
      setShowCheckBoxIntegrationDialog(true);
    } else if (integrationId === IntegrationTypeId.Poster) {
      setShowPosterIntegrationDialog(true);
    } else if (integrationId === IntegrationTypeId.UkrSib) {
      setShowUkrsibIntegrationDialog(true);
    } else if (integrationId === IntegrationTypeId.Payoneer) {
      setShowPayoneerIntegrationDialog(true);
    }
  }, [t, name, accounts, integrationId]);

  const handleCreateIntegration = useCallback(
    (id: string) => {
      if (selectedDate && integrationId) {
        const payload: CreateBankToAccountIntegrationPayload = {
          name,
          typeId: integrationId,
          startDate: dateToUTC(selectedDate),
          currencyId: id,
        };

        dispatch(integrationActions.createIntegrationToNewAccount(payload));

        onClose();
      }
    },
    [name, onClose, dispatch, selectedDate, integrationId],
  );

  return (
    <>
      <Dialog isOpened title={t('integrations.title')} onClose={onClose}>
        <TextFieldComponent
          ga4Id={Ga4Errors.integrationCreateAccountNameError}
          onChange={handleChangeName}
          value={name}
          placeholder={t('common.title')}
          isError={Boolean(error.name)}
          errorText={error.name}
        />
        <CustomButton
          title={t('bank.set')}
          action={handleSetupIntegration}
          loading={loadingMonoIntegration}
          fullWidth
        />
      </Dialog>
      {showMonoIntegrationDialog && (
        <MonoIntegrationDialog
          selectedDate={selectedDate}
          onChangeDate={handleChangeDate}
          onClose={onClose}
          onCloseAccountsDialog={onClose}
          onSetCurrencyId={handleCreateIntegration}
          onCreateIntegration={handleCreateIntegration}
        />
      )}
      {showPrivat24IntegrationDialog && (
        <Private24CredentialsDialog
          selectedDate={selectedDate}
          onChangeDate={handleChangeDate}
          onSetCurrencyId={handleCreateIntegration}
          onClose={handleClosePrivatDialog}
        />
      )}
      {showPrivat24BusinessIntegrationDialog && (
        <Private24BusinessCredentialsDialog
          newAccountName={name}
          onCloseAccountsDialog={onClose}
          onClose={handleClosePrivat24BusinessDialog}
        />
      )}
      {showPumbIntegrationDialog && (
        <PumbBusinessDialog
          onClose={handleClosePumbDialog}
          onCloseAccountsDialog={onClose}
          newAccountName={name}
        />
      )}
      {showUkrsibBankIntegration && showUkrsibIntegrationDialog && (
        <UkrsibDialog onClose={handleCloseUkrsibDialog} newAccountName={name} />
      )}
      {showWesternBidIntegrationDialog && (
        <WesternBidDialog
          onClose={handleCloseWesternBidDialog}
          onCloseAccountsDialog={onClose}
          newAccountName={name}
        />
      )}
      {showCheckBoxIntegrationDialog && (
        <CheckBoxDialog
          onClose={handleCloseWesternBidDialog}
          onCloseAccountsDialog={onClose}
          newAccountName={name}
        />
      )}
      {showPayoneerIntegrationDialog && (
        <PayoneerFirstDialog
          onClose={handleClosePayoneerDialog}
          onCloseAccountsDialog={onClose}
          newAccountName={name}
        />
      )}
      {showPosterIntegrationDialog && (
        <PosterDialog
          onClose={handleClosePosterDialog}
          onCloseAccountsDialog={onClose}
          newAccountName={name}
        />
      )}
    </>
  );
}

export default React.memo(IntegrationBankToNewAccountDialog);
