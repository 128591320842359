import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { normalize } from '@finmap/core-utils';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../../components/PreviewRegisterDialog';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import usePreviewRegister from '../../../hooks/usePreviewRegister';
import { selectSettingsAccounts } from '../../../store/accounts/selectors';
import { selectIsDemoCompany } from '../../../store/company/selectors';
import { getTimeOffset } from '../../../utils/dateToUTC';
import CalendarComponent from '../../Operations/Components/CalendarComponent/CalendarComponent';

type Props = {
  minDate: Date;
  showCreateAccountRow: boolean;
  loading: boolean;
  externalDateError?: string;

  onClose(): void;
  onChange(accountName: string, date: number): void;
};

function AccountAndCalendarDialog(props: Props) {
  const {
    minDate,
    onClose,
    onChange,
    showCreateAccountRow,
    loading,
    externalDateError,
  } = props;
  const { t } = useTranslation();

  const {
    showPreviewRegisterDialog,
    onClosePreviewRegisterDialog,
    onOpenPreviewRegisterDialog,
  } = usePreviewRegister();

  const accounts = useSelector(selectSettingsAccounts);
  const isDemoCompany = useSelector(selectIsDemoCompany);

  const [currentDate, setCurrentDate] = useState<number | null>(
    moment.utc().startOf('month').subtract(1, 'month').valueOf(),
  );
  const [accountName, setAccountName] = useState('');
  const [error, setError] = useState({
    accountName: '',
    date: '',
  });

  const handleChangeDate = useCallback((date: number) => {
    setError((errors) => ({
      ...errors,
      date: '',
    }));

    setCurrentDate(date);
  }, []);

  const handleSave = useCallback(() => {
    if (isDemoCompany) {
      onOpenPreviewRegisterDialog();

      return;
    }

    if (showCreateAccountRow) {
      const isAccountExist = accounts.some(
        (el) => el.normalizedLabel === normalize(accountName),
      );

      if (isAccountExist) {
        setError((errors) => ({
          ...errors,
          accountName: t('errors.create.account.exist'),
        }));

        return;
      }

      if (!accountName) {
        setError((errors) => ({
          ...errors,
          accountName: t('system.fieldMustFilled'),
        }));

        return;
      }
    }

    if (!currentDate) {
      setError((errors) => ({
        ...errors,
        date: t('system.fieldMustFilled'),
      }));

      return;
    }

    onChange(accountName, currentDate);
  }, [
    onChange,
    accounts,
    currentDate,
    accountName,
    isDemoCompany,
    showCreateAccountRow,
    onOpenPreviewRegisterDialog,
    t,
  ]);

  const handleChangeName = useCallback((value: string) => {
    setAccountName(value);
    setError((errors) => ({
      ...errors,
      accountName: '',
    }));
  }, []);

  useEffect(() => {
    if (externalDateError) {
      setError((errors) => ({
        ...errors,
        date: externalDateError,
      }));
    }
  }, [externalDateError]);

  return (
    <>
      <Dialog isOpened onClose={onClose} title={t('bank.selectNameAndDate')}>
        <>
          {showCreateAccountRow && (
            <TextFieldComponent
              onChange={handleChangeName}
              value={accountName}
              placeholder={t('common.title')}
              isError={Boolean(error.accountName)}
              errorText={error.accountName}
            />
          )}
        </>
        <CalendarComponent
          onChange={handleChangeDate}
          placeholder={t('integrations.dateDescription')}
          // @ts-ignore
          value={currentDate}
          fullWidth
          maxDate={
            new Date(
              moment.utc().subtract(1, 'day').valueOf() + getTimeOffset(),
            )
          }
          minDate={minDate}
          error={Boolean(error.date)}
          errorText={error.date}
          fromIntegration
        />
        <CustomButton
          fullWidth
          title={t('common.continue')}
          action={handleSave}
          loading={loading}
        />
      </Dialog>
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog onClose={onClosePreviewRegisterDialog} />
      )}
    </>
  );
}

export default React.memo(AccountAndCalendarDialog);
