import { makeStyles } from '@material-ui/core/styles';
import { black } from '../../../theme/colors';

export const useStyles = makeStyles({
  paper: {
    paddingTop: 0,
    width: 312,
  },
  arrowIcon: {
    color: black,
    marginLeft: 'auto',
  },
  conditionButtonContainer: {
    background: 'rgba(237, 244, 247, 0.8)',
    borderRadius: 12,
    padding: '0 16px',
    height: 56,
    position: 'relative',
    minWidth: '18%',
    display: 'flex',
    alignItems: 'center',
  },
  blockMargin: {
    marginRight: 16,
  },
  paddings: {
    paddingBottom: 16,
  },
});
