import { makeStyles } from '@material-ui/core/styles';

import { white, persianGreen } from '../../constants/theme-colors';

export const useStyles = makeStyles({
  popupRoot: {
    background: white,
    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
  },
  paddingRoot: {
    padding: '16px 0',
  },
  rootMargin: {
    marginTop: 15,
  },
  popupTitle: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: 12,
    opacity: 0.3,
  },
  paddingRow: {
    padding: '0 22px',
  },
  noMargin: {
    marginBottom: '0 !important',
  },
  listRoot: {
    overflow: 'auto',
    marginRight: 4,
    maxHeight: 480,
    position: 'relative',
    paddingBottom: 0,
    paddingTop: 8,
  },
  listRootMargin: {
    marginBottom: 22,
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hidden: {
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
  popupLink: {
    color: persianGreen,
    cursor: 'pointer',
  },
});
